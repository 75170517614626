import { InferType, array, bool, mixed, number, object, string } from 'yup'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { verticalAlignmentStyleSchema } from '../styles/verticalAlignmentStyleSchema'
import { styleSchema } from '../shared/styleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { mediaComponentSchema } from './mediaComponentSchema'
import { containerComponentSchema } from './containerComponentSchema'
import { textComponentSchema } from './textComponentSchema'
import { buttonComponentSchema } from './buttonComponentSchema'
import { idSchema } from '../shared/idSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { transformStyleSchema } from '../styles/transformStyleSchema'
import { transitionStyleSchema } from '../styles/transitionStyleSchema'
import { sizeSchema } from './common/sizeSchema'

export const cardComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['card-component']).required(),
  })
    .noUnknown()
    .required(),
  direction: string().oneOf(['vertical', 'horizontal']),
  swapOrder: bool(),
  components: object({
    media: mediaComponentSchema,
    contentContainer: containerComponentSchema,
    tagline: textComponentSchema,
    heading: textComponentSchema,
    description: textComponentSchema,
    buttons: containerComponentSchema
      .shape({
        items: array().of(
          buttonComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
  }).noUnknown(),
  className: string(),
  size: sizeSchema
    .shape({
      grid: object({
        data: object({
          '--size-grid-column-end-default': number(),
          '--size-grid-column-end-xs': number(),
          '--size-grid-column-end-md': number(),
          '--size-grid-column-end-lg': number(),
          '--size-grid-row-end-default': number(),
          '--size-grid-row-end-xs': number(),
          '--size-grid-row-end-md': number(),
          '--size-grid-row-end-lg': number(),
        })
          .noUnknown()
          .required(),
      })
        .noUnknown()
        .optional(),
    })
    .noUnknown()
    .optional(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  background: backgroundSchema.optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  verticalAlignment: verticalAlignmentStyleSchema.optional(),
  transform: transformStyleSchema.optional(),
  transition: transitionStyleSchema.optional(),
  gap: gapStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
  cid: string(),
  id: string(),
  preview: bool(),
  isAnimated: bool(),
})
  .noUnknown()
  .meta({
    dataType: 'card-component',
  })

export type CardComponent = InferType<typeof cardComponentSchema>

export type CardComponentStyles = Pick<
  CardComponent,
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'horizontalAlignment'
  | 'verticalAlignment'
  | 'border'
  | 'gap'
>
