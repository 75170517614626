import React, { SVGProps } from 'react'

const RadiusMediumIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="1.5" y="4" width="13" height="9" rx="2.5" stroke="#252523" />
  </svg>
)

export default RadiusMediumIcon
