import type { SVGProps } from 'react'
const SvgSceneToolbarLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.02209 7.71841C5.45603 8.00606 4.81411 8.16845 4.13381 8.16845C1.85081 8.16845 1.35019e-06 6.33989 1.35019e-06 4.08423C1.35019e-06 1.82857 1.85081 0 4.13381 0C4.2285 0 4.3224 0.00312872 4.4155 0.00933651H8.891V0H19.2V7.22129H10.2407L12.2018 2.23763C12.2018 2.23763 11.0235 3.6615 8.36969 6.05165C7.63317 6.71499 6.84552 7.2647 6.02209 7.72497V7.71841Z"
      fill="#5E56F9"
    />
    <path
      d="M13.1779 8.28159C13.744 7.99394 14.3859 7.83154 15.0662 7.83154C17.3492 7.83154 19.2 9.66011 19.2 11.9158C19.2 14.1714 17.3492 16 15.0662 16C14.9715 16 14.8776 15.9969 14.7845 15.9907H10.309V16L6.33546e-07 16L0 8.77871L8.95928 8.7787L6.99823 13.7624C6.99823 13.7624 8.17651 12.3385 10.8303 9.94835C11.5668 9.28501 12.3545 8.7353 13.1779 8.27503V8.28159Z"
      fill="#5E56F9"
    />
  </svg>
)
export default SvgSceneToolbarLogo
