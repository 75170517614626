import { InferType, array, bool, mixed, object } from 'yup'
import { buttonComponentSchema } from './buttonComponentSchema'
import { containerComponentSchema } from './containerComponentSchema'
import { commonSliderSchema } from './sliderComponentSchema'
import { sliderPaginantionComponentSchema } from './sliderPaginationComponentSchema'

export const sliderVariant1ComponentsSchema = object({
  slides: containerComponentSchema
    .shape({
      items: array().of(mixed<any>()).required(),
    })
    .noUnknown()
    .required(),
  controlsContainer: containerComponentSchema
    .shape({
      components: object({
        navigation: containerComponentSchema
          .shape({
            components: object({
              buttonPrev: buttonComponentSchema,
              buttonNext: buttonComponentSchema,
            }),
          })
          .noUnknown()
          .required(),
        pagination: sliderPaginantionComponentSchema.required(),
      }),
    })
    .noUnknown()
    .meta({
      dataType: 'slider-variant-1-controls-container-component',
    }),
})
  .noUnknown()
  .required()

export const sliderVariant1ComponentSchema = commonSliderSchema
  .shape({
    hasFloatingNavigation: bool(),
    components: sliderVariant1ComponentsSchema,
  })
  .noUnknown()

export type SliderVariant1Component = InferType<
  typeof sliderVariant1ComponentSchema
>
