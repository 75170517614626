import {
  GetVarsByDocumentName,
  GqlResultByDocumentName,
  GqlResultDataByDocumentName,
} from '@sceneio/graphql-queries/dist/client/types'
import { asyncThunkFetcher } from '../../helpers/asyncThunkFetcher'
import { createGraphqlAsyncThunkByDocument } from '../../helpers/createGraphqlAsyncThunk'
import { GraphqlThunkData } from '../../types'
import { WorkspaceUserRoleId } from './workspaceSliceTypes'
import {
  errorNotification,
  successNotification,
} from '@sceneio/cms-notifications'
import {
  setTriggerLimitsCheck,
  wsUpdateSubscription,
} from '../subscription/subscriptionSlice'
import { AppDispatch, RootState } from '../../store'
import { wsUpdateWorkspace } from './workspaceSlice'

// ---------------
// Workspace(s)
// ---------------

export const workspacesFetch = createGraphqlAsyncThunkByDocument<
  'UserAssignedWorkspacesDocument',
  'assignedWorkspaces'
>()('worspaces/fetch', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'UserAssignedWorkspacesDocument',
    selector: 'assignedWorkspaces',
    variables: queryVariables,
    thunkAPI,
  })
})

export const workspaceFetchById = createGraphqlAsyncThunkByDocument<
  'UserAssignedWorkspacesDocument',
  'assignedWorkspaces'
>()('worspaces/fetch', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'UserAssignedWorkspacesDocument',
    selector: 'assignedWorkspaces',
    variables: queryVariables,
    thunkAPI,
  })
})

export const workspaceUpdate = createGraphqlAsyncThunkByDocument<
  'WorkspaceUpdateMutationDocument',
  'updateWorkspace'
>()('worspace/update', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'WorkspaceUpdateMutationDocument',
    selector: 'updateWorkspace',
    variables: queryVariables,
    thunkAPI,
  })
})

export type WorkspaceLeaveQueryVarsType = GraphqlThunkData<{
  workspaceId: string
}>
export const workspaceLeave = createGraphqlAsyncThunkByDocument<
  'WorkspaceLeaveMutationDocument',
  'leaveWorkspace',
  WorkspaceLeaveQueryVarsType
>()('worspace/leave', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'WorkspaceLeaveMutationDocument',
    selector: 'leaveWorkspace',
    variables: {},
    authContext: {
      workspaceId: queryVariables.workspaceId,
    },
    thunkAPI,
  })
})

// ---------------
// Avatar
// ---------------

export const workspaceAvatarUpload = createGraphqlAsyncThunkByDocument<
  'WorkspaceUploadAvatarMutationDocument',
  'uploadWorkspaceAvatar'
>()('worspace/avatar/upload', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'WorkspaceUploadAvatarMutationDocument',
    selector: 'uploadWorkspaceAvatar',
    variables: queryVariables,
    thunkAPI,
  })
})

export const workspaceAvatarRemove = createGraphqlAsyncThunkByDocument<
  'WorkspaceRemoveAvatarMutationDocument',
  'removeWorkspaceAvatar'
>()('worspace/avatar/remove', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'WorkspaceRemoveAvatarMutationDocument',
    selector: 'removeWorkspaceAvatar',
    variables: queryVariables,
    thunkAPI,
  })
})

// ---------------
// Users
// ---------------

// Type for assignUsers function parameter
type AssignUsersInput = Array<
  GetVarsByDocumentName<
    | 'WorkspaceManageViewersMutationDocument'
    | 'WorkspaceManageEditorsMutationDocument'
  >['usersInput'][0] & { roleId: WorkspaceUserRoleId }
>
type AssignUsersTypeEnum = 'ADD' | 'UPDATE'

// thunk that will take input of users and dispatch the correct mutation
export const workspaceAssignUsers = (
  usersInput: AssignUsersInput,
  type: AssignUsersTypeEnum,
) => {
  return async (dispatch: AppDispatch) => {
    const { editors, viewers } = usersInput.reduce(
      (acc, user) => {
        // Remove roleId since it's not needed in the assignment mutations
        const { roleId, ...userWithoutRole } = user
        if (roleId === 'id:content:editor') {
          acc.editors.push(userWithoutRole)
        } else if (roleId === 'id:viewer') {
          acc.viewers.push(userWithoutRole)
        }
        return acc
      },
      { editors: [], viewers: [] } as {
        editors: GetVarsByDocumentName<'WorkspaceManageEditorsMutationDocument'>['usersInput']
        viewers: GetVarsByDocumentName<'WorkspaceManageViewersMutationDocument'>['usersInput']
      },
    )

    let assignEditorsPromise: Promise<any> | undefined
    let assignViewersPromise: Promise<any> | undefined

    if (editors.length > 0) {
      assignEditorsPromise = dispatch(
        workspaceAssignEditors({ queryVariables: { usersInput: editors } }),
      )
    }
    if (viewers.length > 0) {
      assignViewersPromise = dispatch(
        workspaceAssignViewers({ queryVariables: { usersInput: viewers } }),
      )
    }

    Promise.all([assignEditorsPromise, assignViewersPromise]).then(
      ([p1, p2]) => {
        if (p1?.meta?.rejectedWithValue || p2?.meta?.rejectedWithValue) {
          if (type === 'ADD') {
            errorNotification({ content: 'Error sending invitation(s)' })
          } else {
            errorNotification({ content: 'Error updating user(s)' })
          }
          return
        }
        // TODO the subscription slice should be responsible for this
        dispatch(setTriggerLimitsCheck('remote'))
        if (type === 'ADD') {
          successNotification({ content: 'Invitation(s) sent' })
        } else {
          successNotification({ content: 'User(s) updated' })
        }
      },
    )

    return {
      assignEditorsPromise,
      assignViewersPromise,
    }
  }
}

export const workspaceAssignEditors = createGraphqlAsyncThunkByDocument<
  'WorkspaceManageEditorsMutationDocument',
  'manageEditorsInWorkspace'
>()('worspace/users/assignEditors', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'WorkspaceManageEditorsMutationDocument',
    selector: 'manageEditorsInWorkspace',
    variables: queryVariables,
    thunkAPI,
  })
})

export const workspaceAssignViewers = createGraphqlAsyncThunkByDocument<
  'WorkspaceManageViewersMutationDocument',
  'manageViewersInWorkspace'
>()('worspace/users/assignViewers', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'WorkspaceManageViewersMutationDocument',
    selector: 'manageViewersInWorkspace',
    variables: queryVariables,
    thunkAPI,
  })
})

export const workspaceUnassignUsers = createGraphqlAsyncThunkByDocument<
  'WorkspaceUnassignUsersMutationDocument',
  'unassignUsersFromWorkspace'
>()('worspace/users/unassign', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'WorkspaceUnassignUsersMutationDocument',
    selector: 'unassignUsersFromWorkspace',
    variables: queryVariables,
    thunkAPI,
  })
})

// ---------------
// WS Updates
// ---------------

export const handleWorkspaceWSUpdate = (
  workspace: NonNullable<
    GqlResultDataByDocumentName<'WorkspaceUpdatedSubscriptionDocument'>
  >['workspaceUpdated']['data'],
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const myUserId = state.user.id
    const myAclRoleId = workspace.assignedUsers.find(
      (user) => user.user.id === myUserId,
    )?.aclRoleId as WorkspaceUserRoleId | null

    dispatch(
      wsUpdateWorkspace({
        ...(myAclRoleId && { aclRoleId: myAclRoleId }),
        ...workspace,
        subscription: {
          ...workspace.subscription,
          workspaceId: workspace.id,
        },
      }),
    )

    dispatch(
      wsUpdateSubscription({
        subscription: { ...workspace.subscription, workspaceId: workspace.id },
        subscriptionAddons: workspace.subscriptionAddons,
      }),
    )
  }
}
