import {
  ISchema,
  InferType,
  ObjectShape,
  Reference,
  lazy,
  object,
  string,
} from 'yup'
import { idSchema } from '../shared/idSchema'
import { bannerBlockSchema } from './schemas/bannerBlockSchema'
import { textBlockSchema } from './schemas/textBlockSchema'
import { splitHeroBlockSchema } from './schemas/splitHeroBlockSchema'
import { mediaBlockSchema } from './schemas/mediaBlockSchema'
import { footerVariant1BlockSchema } from './schemas/footerVariant1BlockSchema'
import { headerVariant1BlockSchema } from './schemas/headerVariant1BlockSchema'
import { logoStripBlockSchema } from './schemas/logoStripBlockSchema'
import { testimonialVariant1BlockSchema } from './schemas/testimonialVariant1BlockSchema'
import { galleryGridBlockSchema } from './schemas/galleryGridBlockSchema'
import { embedBlockSchema } from './schemas/embedBlockSchema'
import { featureGridBlockSchema } from './schemas/featureGridBlockSchema'
import { featureGrid2BlockSchema } from './schemas/featureGrid2BlockSchema'
import { fAQ1BlockSchema } from './schemas/fAQ1BlockSchema'
import { logoStrip2BlockSchema } from './schemas/logoStrip2BlockSchema'
import { splitHero2BlockSchema } from './schemas/splitHero2BlockSchema'
import { timelineBlockSchema } from './schemas/timelineBlockSchema'
import { fAQ2BlockSchema } from './schemas/fAQ2BlockSchema'
import { fAQ3BlockSchema } from './schemas/fAQ3BlockSchema'
import { subscriptionFormBlockSchema } from './schemas/subscriptionFormBlockSchema'
import { contactFormBlockSchema } from './schemas/contactFormBlockSchema'
import { buttonsBlockSchema } from './schemas/buttonsBlockSchema'
import { headerVariant2BlockSchema } from './schemas/headerVariant2BlockSchema'
import { footerVariant2BlockSchema } from './schemas/footerVariant2BlockSchema'
import { footerVariant3BlockSchema } from './schemas/footerVariant3BlockSchema'
import { featureGrid3BlockSchema } from './schemas/featureGrid3BlockSchema'
import { featureGrid4BlockSchema } from './schemas/featureGrid4BlockSchema'
import { featureGrid5BlockSchema } from './schemas/featureGrid5BlockSchema'
import { videoHeroBlockSchema } from './schemas/videoHeroBlockSchema'
import { parallaxHeroBlockSchema } from './schemas/parallaxHeroBlockSchema'
import { hero4BlockSchema } from './schemas/hero4BlockSchema'
import { hero5BlockSchema } from './schemas/hero5BlockSchema'
import { heroWithForm1BlockSchema } from './schemas/heroWithForm1BlockSchema'
import { heroWithForm2BlockSchema } from './schemas/heroWithForm2BlockSchema'
import { heroWithForm3BlockSchema } from './schemas/heroWithForm3BlockSchema'
import { headerVariant3BlockSchema } from './schemas/headerVariant3BlockSchema'
import { ctaBanner1BlockSchema } from './schemas/ctaBanner1BlockSchema'
import { ctaBanner2BlockSchema } from './schemas/ctaBanner2BlockSchema'
import { ctaBanner3BlockSchema } from './schemas/ctaBanner3BlockSchema'
import { ctaBanner4BlockSchema } from './schemas/ctaBanner4BlockSchema'
import { featureWithFormBlockSchema } from './schemas/featureWithFormBlockSchema'
import { heroWithForm4BlockSchema } from './schemas/heroWithForm4BlockSchema'
import { masonryGalleryBlockSchema } from './schemas/masonryGalleryBlockSchema'
import { masonryGallery2BlockSchema } from './schemas/masonryGallery2BlockSchema'
import { pricingVariant1BlockSchema } from './schemas/pricingVariant1BlockSchema'
import { heroCarousel1BlockSchema } from './schemas/heroCarousel1BlockSchema'
import { heroCarousel2BlockSchema } from './schemas/heroCarousel2BlockSchema'
import { galleryCarouselBlockSchema } from './schemas/galleryCarouselBlockSchema'
import { heroCountdownBlockSchema } from './schemas/heroCountdownBlockSchema'
import { testimonialCarousel1BlockSchema } from './schemas/testimonialCarousel1BlockSchema'
import { testimonialCarousel2BlockSchema } from './schemas/testimonialCarousel2BlockSchema'
import { featureBento1BlockSchema } from './schemas/featureBento1BlockSchema'
import { heroCarousel3BlockSchema } from './schemas/heroCarousel3BlockSchema'
import { heroWithLogo1BlockSchema } from './schemas/heroWithLogo1BlockSchema'

function contentBlockSchemaByBlockType<
  T extends ISchema<any, any, any, any> | Reference<unknown>,
  BT extends string,
>({
  type,
  blockSchema,
  strict,
  extendSchema,
}: {
  type: BT
  strict?: Boolean
  extendSchema?: ObjectShape
  blockSchema: T
}) {
  let schema = object({
    id: idSchema.required(),
    type: string().oneOf([type]).required(),
    config: blockSchema,
  })

  if (extendSchema) {
    schema = schema.shape(extendSchema).noUnknown() as any
  }

  if (strict) {
    return schema.noUnknown()
  }

  return schema
}

export const resolvedContentBlockSchemaByBlockType = (
  blockType: string,
  strict?: Boolean,
  extendContentBlockSchema?: ObjectShape,
) => {
  switch (blockType) {
    case 'banner':
      return contentBlockSchemaByBlockType<typeof bannerBlockSchema, 'banner'>({
        type: 'banner',
        blockSchema: bannerBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'videoHero':
      return contentBlockSchemaByBlockType<
        typeof videoHeroBlockSchema,
        'videoHero'
      >({
        type: 'videoHero',
        blockSchema: videoHeroBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'parallaxHero':
      return contentBlockSchemaByBlockType<
        typeof parallaxHeroBlockSchema,
        'parallaxHero'
      >({
        type: 'parallaxHero',
        blockSchema: parallaxHeroBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'text':
      return contentBlockSchemaByBlockType<typeof textBlockSchema, 'text'>({
        type: 'text',
        blockSchema: textBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'splitHero':
      return contentBlockSchemaByBlockType<
        typeof splitHeroBlockSchema,
        'splitHero'
      >({
        type: 'splitHero',
        blockSchema: splitHeroBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })

    case 'splitHero2':
      return contentBlockSchemaByBlockType<
        typeof splitHero2BlockSchema,
        'splitHero2'
      >({
        type: 'splitHero2',
        blockSchema: splitHero2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })

    case 'media':
      return contentBlockSchemaByBlockType<typeof mediaBlockSchema, 'media'>({
        type: 'media',
        blockSchema: mediaBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'footerVariant1':
      return contentBlockSchemaByBlockType<
        typeof footerVariant1BlockSchema,
        'footerVariant1'
      >({
        type: 'footerVariant1',
        blockSchema: footerVariant1BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'footerVariant2':
      return contentBlockSchemaByBlockType<
        typeof footerVariant2BlockSchema,
        'footerVariant2'
      >({
        type: 'footerVariant2',
        blockSchema: footerVariant2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'footerVariant3':
      return contentBlockSchemaByBlockType<
        typeof footerVariant3BlockSchema,
        'footerVariant3'
      >({
        type: 'footerVariant3',
        blockSchema: footerVariant3BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'headerVariant1':
      return contentBlockSchemaByBlockType<
        typeof headerVariant1BlockSchema,
        'headerVariant1'
      >({
        type: 'headerVariant1',
        blockSchema: headerVariant1BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'headerVariant2':
      return contentBlockSchemaByBlockType<
        typeof headerVariant2BlockSchema,
        'headerVariant2'
      >({
        type: 'headerVariant2',
        blockSchema: headerVariant2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'headerVariant3':
      return contentBlockSchemaByBlockType<
        typeof headerVariant3BlockSchema,
        'headerVariant3'
      >({
        type: 'headerVariant3',
        blockSchema: headerVariant3BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'logoStrip':
      return contentBlockSchemaByBlockType<
        typeof logoStripBlockSchema,
        'logoStrip'
      >({
        type: 'logoStrip',
        blockSchema: logoStripBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'logoStrip2':
      return contentBlockSchemaByBlockType<
        typeof logoStrip2BlockSchema,
        'logoStrip2'
      >({
        type: 'logoStrip2',
        blockSchema: logoStrip2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'testimonialVariant1':
      return contentBlockSchemaByBlockType<
        typeof testimonialVariant1BlockSchema,
        'testimonialVariant1'
      >({
        type: 'testimonialVariant1',
        blockSchema: testimonialVariant1BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'galleryGrid':
      return contentBlockSchemaByBlockType<
        typeof galleryGridBlockSchema,
        'galleryGrid'
      >({
        type: 'galleryGrid',
        blockSchema: galleryGridBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'embed':
      return contentBlockSchemaByBlockType<typeof embedBlockSchema, 'embed'>({
        type: 'embed',
        blockSchema: embedBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'featureGrid':
      return contentBlockSchemaByBlockType<
        typeof featureGridBlockSchema,
        'featureGrid'
      >({
        type: 'featureGrid',
        blockSchema: featureGridBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'featureGrid2':
      return contentBlockSchemaByBlockType<
        typeof featureGrid2BlockSchema,
        'featureGrid2'
      >({
        type: 'featureGrid2',
        blockSchema: featureGrid2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'featureGrid3':
      return contentBlockSchemaByBlockType<
        typeof featureGrid3BlockSchema,
        'featureGrid3'
      >({
        type: 'featureGrid3',
        blockSchema: featureGrid3BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'featureGrid4':
      return contentBlockSchemaByBlockType<
        typeof featureGrid4BlockSchema,
        'featureGrid4'
      >({
        type: 'featureGrid4',
        blockSchema: featureGrid4BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'featureGrid5':
      return contentBlockSchemaByBlockType<
        typeof featureGrid5BlockSchema,
        'featureGrid5'
      >({
        type: 'featureGrid5',
        blockSchema: featureGrid5BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'featureWithForm':
      return contentBlockSchemaByBlockType<
        typeof featureWithFormBlockSchema,
        'featureWithForm'
      >({
        type: 'featureWithForm',
        blockSchema: featureWithFormBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'fAQ1':
      return contentBlockSchemaByBlockType<typeof fAQ1BlockSchema, 'fAQ1'>({
        type: 'fAQ1',
        blockSchema: fAQ1BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'fAQ2':
      return contentBlockSchemaByBlockType<typeof fAQ2BlockSchema, 'fAQ2'>({
        type: 'fAQ2',
        blockSchema: fAQ2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'fAQ3':
      return contentBlockSchemaByBlockType<typeof fAQ3BlockSchema, 'fAQ3'>({
        type: 'fAQ3',
        blockSchema: fAQ3BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'timeline':
      return contentBlockSchemaByBlockType<
        typeof timelineBlockSchema,
        'timeline'
      >({
        type: 'timeline',
        blockSchema: timelineBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'subscriptionForm':
      return contentBlockSchemaByBlockType<
        typeof subscriptionFormBlockSchema,
        'subscriptionForm'
      >({
        type: 'subscriptionForm',
        blockSchema: subscriptionFormBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'contactForm':
      return contentBlockSchemaByBlockType<
        typeof contactFormBlockSchema,
        'contactForm'
      >({
        type: 'contactForm',
        blockSchema: contactFormBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'buttons':
      return contentBlockSchemaByBlockType<
        typeof buttonsBlockSchema,
        'buttons'
      >({
        type: 'buttons',
        blockSchema: buttonsBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'hero4':
      return contentBlockSchemaByBlockType<typeof hero4BlockSchema, 'hero4'>({
        type: 'hero4',
        blockSchema: hero4BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'hero5':
      return contentBlockSchemaByBlockType<typeof hero5BlockSchema, 'hero5'>({
        type: 'hero5',
        blockSchema: hero5BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'heroWithForm1':
      return contentBlockSchemaByBlockType<
        typeof heroWithForm1BlockSchema,
        'heroWithForm1'
      >({
        type: 'heroWithForm1',
        blockSchema: heroWithForm1BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'heroWithForm2':
      return contentBlockSchemaByBlockType<
        typeof heroWithForm2BlockSchema,
        'heroWithForm2'
      >({
        type: 'heroWithForm2',
        blockSchema: heroWithForm2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'heroWithForm3':
      return contentBlockSchemaByBlockType<
        typeof heroWithForm3BlockSchema,
        'heroWithForm3'
      >({
        type: 'heroWithForm3',
        blockSchema: heroWithForm3BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'heroWithForm4':
      return contentBlockSchemaByBlockType<
        typeof heroWithForm4BlockSchema,
        'heroWithForm4'
      >({
        type: 'heroWithForm4',
        blockSchema: heroWithForm4BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'ctaBanner1':
      return contentBlockSchemaByBlockType<
        typeof ctaBanner1BlockSchema,
        'ctaBanner1'
      >({
        type: 'ctaBanner1',
        blockSchema: ctaBanner1BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'ctaBanner2':
      return contentBlockSchemaByBlockType<
        typeof ctaBanner2BlockSchema,
        'ctaBanner2'
      >({
        type: 'ctaBanner2',
        blockSchema: ctaBanner2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'ctaBanner3':
      return contentBlockSchemaByBlockType<
        typeof ctaBanner3BlockSchema,
        'ctaBanner3'
      >({
        type: 'ctaBanner3',
        blockSchema: ctaBanner3BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'ctaBanner4':
      return contentBlockSchemaByBlockType<
        typeof ctaBanner4BlockSchema,
        'ctaBanner4'
      >({
        type: 'ctaBanner4',
        blockSchema: ctaBanner4BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'masonryGallery':
      return contentBlockSchemaByBlockType<
        typeof masonryGalleryBlockSchema,
        'masonryGallery'
      >({
        type: 'masonryGallery',
        blockSchema: masonryGalleryBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'masonryGallery2':
      return contentBlockSchemaByBlockType<
        typeof masonryGallery2BlockSchema,
        'masonryGallery2'
      >({
        type: 'masonryGallery2',
        blockSchema: masonryGallery2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'pricingVariant1':
      return contentBlockSchemaByBlockType<
        typeof pricingVariant1BlockSchema,
        'pricingVariant1'
      >({
        type: 'pricingVariant1',
        blockSchema: pricingVariant1BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'heroCarousel1':
      return contentBlockSchemaByBlockType<
        typeof heroCarousel1BlockSchema,
        'heroCarousel1'
      >({
        type: 'heroCarousel1',
        blockSchema: heroCarousel1BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'heroCarousel2':
      return contentBlockSchemaByBlockType<
        typeof heroCarousel2BlockSchema,
        'heroCarousel2'
      >({
        type: 'heroCarousel2',
        blockSchema: heroCarousel2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'galleryCarousel':
      return contentBlockSchemaByBlockType<
        typeof galleryCarouselBlockSchema,
        'galleryCarousel'
      >({
        type: 'galleryCarousel',
        blockSchema: galleryCarouselBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'heroCountdown':
      return contentBlockSchemaByBlockType<
        typeof heroCountdownBlockSchema,
        'heroCountdown'
      >({
        type: 'heroCountdown',
        blockSchema: heroCountdownBlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'testimonialCarousel1':
      return contentBlockSchemaByBlockType<
        typeof testimonialCarousel1BlockSchema,
        'testimonialCarousel1'
      >({
        type: 'testimonialCarousel1',
        blockSchema: testimonialCarousel1BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'testimonialCarousel2':
      return contentBlockSchemaByBlockType<
        typeof testimonialCarousel2BlockSchema,
        'testimonialCarousel2'
      >({
        type: 'testimonialCarousel2',
        blockSchema: testimonialCarousel2BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    case 'featureBento1':
      return contentBlockSchemaByBlockType<
        typeof featureBento1BlockSchema,
        'featureBento1'
      >({
        type: 'featureBento1',
        blockSchema: featureBento1BlockSchema,
      })
    case 'heroCarousel3':
      return contentBlockSchemaByBlockType<
        typeof heroCarousel3BlockSchema,
        'heroCarousel3'
      >({
        type: 'heroCarousel3',
        blockSchema: heroCarousel3BlockSchema,
      })
    case 'heroWithLogo1':
      return contentBlockSchemaByBlockType<
        typeof heroWithLogo1BlockSchema,
        'heroWithLogo1'
      >({
        type: 'heroWithLogo1',
        blockSchema: heroWithLogo1BlockSchema,
        strict,
        extendSchema: extendContentBlockSchema,
      })
    default:
      throw new Error('Invalid contentBlock type')
  }
}

export const contentBlockSchema = ({
  strict,
  extendContentBlockSchema,
}: {
  strict?: Boolean
  extendContentBlockSchema?: ObjectShape
} = {}) =>
  lazy((value) => {
    const blockType = value?.type

    return resolvedContentBlockSchemaByBlockType(
      blockType,
      strict,
      extendContentBlockSchema,
    )
  })

export const defaultContentBlockSchema = contentBlockSchema()
export const strictContentBlockSchema = contentBlockSchema({
  strict: true,
  extendContentBlockSchema: {
    contentPropertyName: string().oneOf(['blocks']).required(),
  },
})
export type ContentBlock = InferType<typeof defaultContentBlockSchema>
export type StrictContentBlock = InferType<typeof strictContentBlockSchema> & {
  contentPropertyName: 'blocks'
}
