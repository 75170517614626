import type { FontsMap } from '@sceneio/config-fonts/lib/fonts'
import type { Asset } from '@sceneio/graphql-queries/dist/generated/graphqlTypes'

export type FontStyleAsset = Partial<Asset> &
  Required<Pick<Asset, 'group' | 'meta' | 'presentationUrl' | 'type' | 'cid'>>

export function convertFontStyleAssetsToFontConfig(
  fontStyleAssets?: FontStyleAsset[],
): FontsMap {
  return (fontStyleAssets || []).reduce(
    (acc, { type, group, cid, meta, presentationUrl }) => {
      // filter only font styles
      if (type === 'STYLE' && group === 'font') {
        return {
          ...acc,
          [cid]: {
            title: meta.fontTitle,
            fontFamily: `${cid}, ${meta.fontFallback}`,
            url: presentationUrl,
            config: {
              allowedFontWeights: meta.allowedFontWeights,
            },
          },
        }
      }
      return acc
    },
    {} as FontsMap,
  )
}
