import { InferType, object, string } from 'yup'

export const backgroundColorStyleSchema = object({
  data: object({
    default: object({
      '--background-color-default-default': string(),
      '--background-color-xs-default': string(),
      '--background-color-md-default': string(),
      '--background-color-lg-default': string(),
    })
      .noUnknown()
      .optional(),
    hover: object({
      '--background-color-default-hover': string(),
      '--background-color-xs-hover': string(),
      '--background-color-md-hover': string(),
      '--background-color-lg-hover': string(),
    })
      .noUnknown()
      .optional(),
    active: object({
      '--background-color-default-active': string(),
      '--background-color-xs-active': string(),
      '--background-color-md-active': string(),
      '--background-color-lg-active': string(),
    })
      .noUnknown()
      .optional(),
  }).noUnknown(),
})
  .noUnknown()
  .meta({
    dataType: 'background-color-style',
  })

export type BackgroundColorStyle = InferType<typeof backgroundColorStyleSchema>
