/**
 * An object that contains information about the current device and environment.
 */
const devEnv = {
  isSafari: false,
  isIos: false,
  isChromeForIos: false,
  isFirefox: false,
  isAndroid: false,
  isWebview: false,
  isDarwin: false,
}

if (typeof window !== 'undefined' && 'navigator' in window) {
  devEnv.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  devEnv.isIos =
    !!navigator.userAgent.match(/iPad/i) ||
    !!navigator.userAgent.match(/iPhone/i)
  devEnv.isChromeForIos = /crios.*safari/i.test(navigator.userAgent)
  devEnv.isFirefox = /firefox/i.test(navigator.userAgent)
  devEnv.isAndroid = /android/i.test(navigator.userAgent)
  devEnv.isDarwin = window.navigator.userAgent.toLowerCase().indexOf('mac') > -1
}

const deviceEnviroment = devEnv

export { deviceEnviroment }
