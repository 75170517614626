import type { SVGProps } from 'react'
const SvgAddBlockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M16.2805 12.5297C16.4211 12.3891 16.5001 12.1983 16.5001 11.9994C16.5001 11.8005 16.4211 11.6097 16.2805 11.4691C16.1398 11.3284 15.9491 11.2494 15.7501 11.2494H12.7501V8.24941C12.7501 8.0505 12.6711 7.85974 12.5305 7.71908C12.3898 7.57843 12.1991 7.49941 12.0001 7.49941C11.8012 7.49941 11.6105 7.57843 11.4698 7.71908C11.3292 7.85974 11.2501 8.0505 11.2501 8.24941V11.2494H8.25015C8.05123 11.2494 7.86047 11.3284 7.71982 11.4691C7.57916 11.6097 7.50015 11.8005 7.50015 11.9994C7.50015 12.1983 7.57916 12.3891 7.71982 12.5297C7.86047 12.6704 8.05123 12.7494 8.25015 12.7494H11.2501V15.7494C11.2501 15.9483 11.3292 16.1391 11.4698 16.2797C11.6105 16.4204 11.8012 16.4994 12.0001 16.4994C12.1991 16.4994 12.3898 16.4204 12.5305 16.2797C12.6711 16.1391 12.7501 15.9483 12.7501 15.7494V12.7494H15.7501C15.9491 12.7494 16.1398 12.6704 16.2805 12.5297Z"
      fill="#252523"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.40015 7.64941C2.40164 6.25748 2.95524 4.92299 3.93948 3.93875C4.92372 2.9545 6.25822 2.4009 7.65015 2.39941H16.3501C17.7421 2.4009 19.0766 2.9545 20.0608 3.93875C20.757 4.63494 21.2377 5.50637 21.46 6.44941C21.552 6.8396 21.5997 7.24205 21.6001 7.64941L21.6001 16.3494C21.5987 17.7413 21.0451 19.0758 20.0608 20.0601C19.0766 21.0443 17.7421 21.5979 16.3501 21.5994H7.65015C7.24278 21.599 6.84033 21.5513 6.45015 21.4593C5.5071 21.237 4.63567 20.7563 3.93948 20.0601C2.95524 19.0758 2.40164 17.7413 2.40015 16.3494V7.64941ZM19.0018 4.99776C19.7051 5.70103 20.1001 6.65485 20.1001 7.64941L20.1001 16.3494C20.1001 17.344 19.7051 18.2978 19.0018 19.0011C18.2985 19.7043 17.3447 20.0994 16.3501 20.0994H7.65015C6.65558 20.0994 5.70176 19.7043 4.9985 19.0011C4.29523 18.2978 3.90015 17.344 3.90015 16.3494V7.64941C3.90015 6.65485 4.29523 5.70103 4.9985 4.99776C5.70176 4.2945 6.65558 3.89941 7.65015 3.89941H16.3501C17.3447 3.89941 18.2985 4.2945 19.0018 4.99776Z"
      fill="#252523"
    />
  </svg>
)
export default SvgAddBlockIcon
