import React, { SVGProps } from 'react'

const SpacingTightIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.8281 14L13.8281 11.5L2.82812 11.5L2.82813 14"
        stroke="black"
      />
      <path
        d="M13.8281 3.00195L13.8281 5.50195L2.82812 5.50195L2.82812 3.00195"
        stroke="black"
      />
      <path d="M13.3281 8.49805L4.32812 8.49805" stroke="black" />
    </svg>
  )
}

export default SpacingTightIcon
