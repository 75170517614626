import React, { SVGProps } from 'react'

const SpacingRegularIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.5 15L13.5 12.5L2.5 12.5L2.5 15" stroke="black" />
      <path
        d="M13.5 2.00195L13.5 4.50195L2.5 4.50195L2.5 2.00195"
        stroke="black"
      />
      <path d="M13 8.49805L4 8.49805" stroke="black" />
    </svg>
  )
}

export default SpacingRegularIcon
