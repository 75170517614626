import type { ContentBlock } from '@sceneio/graphql-queries/dist/generated/graphqlTypes'
import { restoreContentBlocks } from '../features/content/contentSlice'
import { deleteWhiteboardContentEntities } from '../features/whiteboard/whiteboardSlice'
import { WhiteboardContentEntitiesDeleteQueryVarsType } from '../features/whiteboard/whiteboardSliceTypes'
import { StoreType } from '../store'
import { LocalHistoryCommand } from './LocalHistoryCommand'
import { createContentModules } from '../features/contentModules/contentModulesSlice'
import { ContentModuleType } from '../features/contentModules/contentModulesSliceTypes'
import { Dispatch } from '@reduxjs/toolkit'

export class WhiteboardContentEntitiesDeleteCommand extends LocalHistoryCommand {
  thunkData: WhiteboardContentEntitiesDeleteQueryVarsType
  constructor(thunkData: WhiteboardContentEntitiesDeleteQueryVarsType) {
    super()
    this.thunkData = thunkData
    this.pastState = null
    this.type = 'WhiteboardContentEntitiesDeleteCommand'
  }

  preExecute(getState: StoreType['getState']) {
    const state = getState()

    if (state.content.status !== 'succeeded') {
      return
    }

    const deleteInput = this.thunkData.queryVariables.deleteInput

    const contentBlockIdsToDelete: string[] = []
    const contentModuleIdsToDelete: string[] = []

    deleteInput.forEach(({ entity, id }) => {
      if (entity === 'CONTENTBLOCK') contentBlockIdsToDelete.push(id)
      if (entity === 'CONTENTMODULE') contentModuleIdsToDelete.push(id)
    })

    const contentBlocksToAdd = state.content.entity.contentBlocks.filter(
      ({ id }) => contentBlockIdsToDelete.includes(id),
    )

    const renderableContentBlocksOrders = contentBlocksToAdd.reduce<number[]>(
      (acc, block) => {
        if (block.isRenderable && typeof block?.order === 'number') {
          acc.push(block.order)
        }
        return acc
      },
      [],
    )

    let position = undefined

    if (renderableContentBlocksOrders.length > 0) {
      position = Math.min(...renderableContentBlocksOrders)
    }

    this.pastState = {
      position,
      contentBlocksToAdd: state.content.entity.contentBlocks.filter(({ id }) =>
        contentBlockIdsToDelete.includes(id),
      ),
      contentModulesToAdd: state.contentModules.entities.filter(({ id }) =>
        contentModuleIdsToDelete.includes(id),
      ),
    }
  }

  execute() {
    return deleteWhiteboardContentEntities(this.thunkData)
  }

  undo(getState: StoreType['getState']) {
    const state = getState()

    if (state.content.status !== 'succeeded') {
      return
    }

    const { contentBlocksToAdd, commentsToAdd, contentModulesToAdd, position } =
      this.pastState

    // add blocks

    return (
      dispatch: Dispatch<any>,
      getState: StoreType['getState'],
      payload: any,
    ) => {
      if (contentBlocksToAdd.length > 0) {
        dispatch(
          restoreContentBlocks({
            queryVariables: {
              restoreInput: (contentBlocksToAdd as ContentBlock[]).map(
                ({ cid }) => ({ cid }),
              ),
            },
          }),
        )
      }

      if (contentModulesToAdd.length > 0) {
        dispatch(
          createContentModules({
            queryVariables: {
              contentModulesData: (
                contentModulesToAdd as ContentModuleType[]
              ).map(({ cid, type, meta, name, data }) => ({
                cid,
                type,
                meta,
                name,
                data,
              })),
            },
          }),
        )
      }
    }
  }
}
