import { object, InferType, string } from 'yup'

export const sizeSchema = object({
  width: object({
    data: object({
      '--size-width-default': string(),
      '--size-width-xs': string(),
      '--size-width-md': string(),
      '--size-width-lg': string(),
    })
      .noUnknown()
      .required(),
  })
    .noUnknown()
    .optional(),
  height: object({
    data: object({
      '--size-height-default': string(),
      '--size-height-xs': string(),
      '--size-height-md': string(),
      '--size-height-lg': string(),
    })
      .noUnknown()
      .required(),
  })
    .noUnknown()
    .optional(),
  maxWidth: object({
    data: object({
      '--size-max-width-default': string(),
      '--size-max-width-xs': string(),
      '--size-max-width-md': string(),
      '--size-max-width-lg': string(),
    })
      .noUnknown()
      .required(),
  })
    .noUnknown()
    .optional(),
  maxHeight: object({
    data: object({
      '--size-max-height-default': string(),
      '--size-max-height-xs': string(),
      '--size-max-height-md': string(),
      '--size-max-height-lg': string(),
    })
      .noUnknown()
      .required(),
  })
    .noUnknown()
    .optional(),
  minWidth: object({
    data: object({
      '--size-min-width-default': string(),
      '--size-min-width-xs': string(),
      '--size-min-width-md': string(),
      '--size-min-width-lg': string(),
    })
      .noUnknown()
      .required(),
  })
    .noUnknown()
    .optional(),
  minHeight: object({
    data: object({
      '--size-min-height-default': string(),
      '--size-min-height-xs': string(),
      '--size-min-height-md': string(),
      '--size-min-height-lg': string(),
    })
      .noUnknown()
      .required(),
  })
    .noUnknown()
    .optional(),
}).noUnknown()

export type Size = InferType<typeof sizeSchema>
