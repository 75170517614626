import { StoreType } from '../store'
import { LocalHistoryCommand } from './LocalHistoryCommand'
import { createCid } from '../helpers/createCid'
import { AddContentBlocksQueryVarsType } from '../features/content/contentSliceTypes'
import { addBlocks } from '../features/content/contentSlice'
import { deleteWhiteboardContentEntities } from '../features/whiteboard/whiteboardSlice'
import { ContentEntitiesDeleteInput } from '@sceneio/graphql-queries/dist/generated/graphqlTypes'
import posthog from 'posthog-js'

export class AddBlocksCommand extends LocalHistoryCommand {
  thunkData: AddContentBlocksQueryVarsType
  constructor(thunkData: AddContentBlocksQueryVarsType) {
    super()
    this.thunkData = {
      ...thunkData,
      queryVariables: {
        ...thunkData.queryVariables,
        contentBlocksData: thunkData.queryVariables.contentBlocksData.map(
          (contentBlock) => ({ ...contentBlock, cid: createCid() }),
        ),
      },
    }
    this.pastState = null
    this.type = 'AddBlocksCommand'
  }

  preExecute(getState: StoreType['getState']) {
    this.pastState = this.thunkData.queryVariables.contentBlocksData.map(
      ({ cid }) => cid,
    )
  }

  execute() {
    posthog.capture('block_add', {
      blocks: this.thunkData.queryVariables.contentBlocksData.map(
        ({ cid, type }) => {
          return {
            cid,
            type,
          }
        },
      ),
    })
    return addBlocks(this.thunkData)
  }

  undo(getState: StoreType['getState']) {
    const state = getState()

    if (state.content.status !== 'succeeded') {
      return
    }

    const contentBlocksToDelete = state.content.entity.contentBlocks.filter(
      ({ cid }) => this.pastState.includes(cid),
    )

    const contentBlocksToDeleteInput: Array<ContentEntitiesDeleteInput> =
      contentBlocksToDelete.map(({ id }) => ({
        id,
        entity: 'CONTENTBLOCK',
      }))

    if (contentBlocksToDeleteInput.length > 0) {
      return deleteWhiteboardContentEntities({
        queryVariables: { deleteInput: contentBlocksToDeleteInput },
      })
    }

    return
  }
}
