import { InferType, array, bool, boolean, number, object, string } from 'yup'
import { idSchema } from '../shared/idSchema'
import { buttonComponentSchema } from './buttonComponentSchema'
import { containerComponentSchema } from './containerComponentSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { verticalAlignmentStyleSchema } from '../styles/verticalAlignmentStyleSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { styleSchema } from '../shared/styleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'

export const tabPaneComponentSchema = containerComponentSchema
  .shape({
    id: idSchema.required(),
    defaultActive: number(),
    index: number(),
    preview: boolean(),
    meta: object({
      dataType: string().oneOf(['tab-pane-component']).required(),
    })
      .noUnknown()
      .required(),
  })
  .noUnknown()
  .meta({
    dataType: 'tab-pane-component',
  })

export const tabsMenuComponentSchema = containerComponentSchema
  .shape({
    cid: string(),
    defaultActive: number(),
    preview: boolean(),
    items: array().of(
      buttonComponentSchema
        .shape({
          id: idSchema.required(),
        })
        .noUnknown(),
    ),
  })
  .noUnknown()
  .meta({
    dataType: 'tabs-menu-component',
  })

export const tabsContentComponentSchema = containerComponentSchema
  .shape({
    items: array().of(tabPaneComponentSchema),
    defaultActive: number(),
    editor: componentEditorSchema,
    preview: boolean(),
    meta: object({
      dataType: string().oneOf(['tabs-content-component']).required(),
    })
      .noUnknown()
      .required(),
  })
  .noUnknown()
  .meta({
    dataType: 'tabs-content-component',
  })

export const tabsComponentsSchema = object({
  tabsMenu: tabsMenuComponentSchema,
  tabsContent: tabsContentComponentSchema,
}).noUnknown()

export const tabsComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['tabs-component']).required(),
  })
    .noUnknown()
    .required(),
  components: tabsComponentsSchema,
  defaultActive: number(),
  cid: string(),
  className: string(),
  as: string().oneOf(['div']),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  verticalAlignment: verticalAlignmentStyleSchema.optional(),
  background: backgroundSchema.optional(),
  gap: gapStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
  preview: boolean(),
})
  .noUnknown()
  .meta({
    dataType: 'tabs-component',
  })

export type TabsComponent = InferType<typeof tabsComponentSchema>

export type TabPaneComponent = InferType<typeof tabPaneComponentSchema>
export type TabsContentComponent = InferType<typeof tabsContentComponentSchema>
export type TabsMenuComponent = InferType<typeof tabsMenuComponentSchema>

export type TabsComponentStyles = Pick<
  TabsComponent,
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'horizontalAlignment'
  | 'verticalAlignment'
  | 'border'
  | 'gap'
>
