import { Styleguide, StyleguideFontTypeSet } from '@sceneio/schemas/lib/types'

export const DEFAULT_STYLEGUIDE_VALUES: Styleguide = {
  cornerRadius: 'sharp',
  spacing: 'regular',
  colorPalette: 'whiteNeutral',
  colorSet: {
    id: '1',
    data: {
      neutral: 'rgba(37, 37, 35, 0.06)',
      accent: '#5E56F9',
    },
  },
  fontTypeSet: {
    id: '1',
    data: {
      baseFontSize: '16px',
      scale: 1.26,
      headingFont: {
        family: 'Inter, sans-serif',
        weight: 700,
        style: 'default',
        letterSpacing: 'default',
      },
      bodyFont: {
        family: 'Inter, sans-serif',
        weight: 400,
      },
    },
  },
}

export const TYPESET_OPTIONS: StyleguideFontTypeSet[] = [
  {
    id: '1',
    data: {
      baseFontSize: '20px',
      headingFont: {
        family: 'aspekta, sans-serif',
        weight: 700,
      },
      bodyFont: {
        family: 'Inter, sans-serif',
        weight: 400,
      },
    },
  },
  {
    id: '2',
    data: {
      baseFontSize: '16px',
      headingFont: {
        family: '"Playfair Display", serif',
        weight: 700,
      },
      bodyFont: {
        family: '"Work Sans", sans-serif',
        weight: 400,
      },
    },
  },
]
