import { InferType, bool, number, object, string } from 'yup'
import { reactNodeSchema } from '../shared/reactNodeSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { verticalAlignmentStyleSchema } from '../styles/verticalAlignmentStyleSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { styleSchema } from '../shared/styleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { flowStyleSchema } from '../styles/flowStyleSchema'
import type { JSX } from 'react'
import { transitionStyleSchema } from '../styles/transitionStyleSchema'
import { transformStyleSchema } from '../styles/transformStyleSchema'
import { sizeSchema } from './common/sizeSchema'

export const containerComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['container-component']).required(),
  })
    .noUnknown()
    .required(),
  name: string().oneOf(['group', 'componentArray', 'component']),
  children: reactNodeSchema,
  className: string(),
  as: string().oneOf(['div']),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  size: sizeSchema
    .shape({
      grid: object({
        data: object({
          '--size-grid-column-end-default': number(),
          '--size-grid-column-end-xs': number(),
          '--size-grid-column-end-md': number(),
          '--size-grid-column-end-lg': number(),
          '--size-grid-row-end-default': number(),
          '--size-grid-row-end-xs': number(),
          '--size-grid-row-end-md': number(),
          '--size-grid-row-end-lg': number(),
        })
          .noUnknown()
          .required(),
      })
        .noUnknown()
        .optional(),
    })
    .noUnknown()
    .optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  verticalAlignment: verticalAlignmentStyleSchema.optional(),
  transition: transitionStyleSchema.optional(),
  transform: transformStyleSchema.optional(),
  background: backgroundSchema.optional(),
  flow: flowStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
  gap: gapStyleSchema.optional(),
})
  .noUnknown()
  .meta({
    dataType: 'container-component',
  })

export type ContainerComponent = InferType<
  typeof containerComponentSchema & {
    as?: keyof JSX.IntrinsicElements
  }
>

export type ContainerComponentStyles = Pick<
  ContainerComponent,
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'border'
  | 'horizontalAlignment'
  | 'verticalAlignment'
  | 'gap'
  | 'flow'
>
