import React from 'react'
import type { SVGProps } from 'react'

const AIBoltIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path
      d="M8.65557 4.81924C9.65978 3.8518 10.5796 2.87901 11.3952 1.93364C11.4682 1.84903 11.5763 1.7998 11.6905 1.7998C11.9585 1.7998 12.1433 2.05861 12.0474 2.29971L9.86158 7.79693L15.1904 7.79693C15.4293 7.79693 15.623 7.98355 15.623 8.21376C15.623 8.35366 15.5502 8.48394 15.4298 8.56218C13.7012 9.6857 11.7521 11.2437 9.82341 13.1017C8.78927 14.098 7.84466 15.1 7.01112 16.0718C6.94174 16.1527 6.83869 16.1998 6.72977 16.1998C6.47502 16.1998 6.29938 15.9538 6.39051 15.7246L8.61741 10.1241H3.06489C2.86334 10.1241 2.69995 9.96666 2.69995 9.77249C2.69995 9.65353 2.76246 9.54282 2.86541 9.47715C4.63902 8.34585 6.65826 6.74344 8.65557 4.81924Z"
      fill="#5E56F9"
    />
  </svg>
)
export default AIBoltIcon
