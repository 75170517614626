import { Middleware } from '@reduxjs/toolkit'
import { addUsedFonts } from '@sceneio/content-store/lib/features/assets/assetsSlice'
import { RootState } from '@sceneio/content-store/lib/store'
import { getUsedFonts } from '@sceneio/ui-core/src/fonts/helpers/getUsedFonts'
import { Action } from '@reduxjs/toolkit'

const ACTIONS_WHITELIST = [
  'content/reusableContentBlock/assign/fulfilled',
  'content/reusableContentBlock/mergeDraft/fulfilled',
  'content/reusableContentBlock/deleteDraft/fulfilled',
  'content/wsAddContentBlocks',
  'content/wsUpdateContentBlocks',
  'content/wsUpdateReusableContentBlocks',
  'snippets/wsCreateSnippet',
  'snippets/wsUpdateSnippets',
  'snippets/wsDiscardSnippet',
  'snippets/wsIssuedSnippet',
]

export const usedFontsMiddleware: Middleware<{}, RootState> =
  ({ dispatch, getState }) =>
  (next) =>
  (action: unknown) => {
    const result = next(action)
    const typedAction = action as Action
    const typedResult = result as { payload: any }

    if (ACTIONS_WHITELIST.includes(typedAction.type)) {
      const usedFonts = getUsedFonts(typedResult.payload)

      if (usedFonts.length > 0) {
        dispatch(addUsedFonts(usedFonts))
      }
    }

    return result
  }
