import React from 'react'
import clsx from 'clsx'
import { AVAILABLE_ICONS } from './iconsMap'

export type PHOSPHOR_ICON = (typeof AVAILABLE_ICONS)[number]

export type PhosphorIconProps = {
  className?: string
  style?: React.CSSProperties
  icon: PHOSPHOR_ICON
  weight?: 'thin' | 'light' | 'regular' | 'bold' | 'fill' | 'duotone'
  alt?: string
  color?: string
  size?: string | number
  mirrored?: boolean
}

export function PhosphorIcon({
  icon,
  className,
  style,
  weight,
  alt,
  color,
  size = 16,
  mirrored,
}: PhosphorIconProps) {
  if (!AVAILABLE_ICONS.includes(icon)) {
    console.error(`Phosphor icon not found: ${icon}`)
    return <span>⨂</span>
  }

  const dashedCaseIcon = icon.replace(
    /[A-Z]/g,
    (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase(),
  )

  const webIconStyles = { ...style }
  if (size) {
    webIconStyles.fontSize = size
  }
  if (color) {
    webIconStyles.color = color
  }
  if (mirrored) {
    webIconStyles.transform = 'scale(-1, 1)'
  }

  return (
    <i
      style={webIconStyles}
      className={clsx(className, 'ph', {
        [`ph-${dashedCaseIcon}`]: dashedCaseIcon,
        [`ph-${weight}`]: weight,
      })}
    />
  )
}
