import { InferType, array, bool, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { testimonialVariant1CardSchema } from './testimonialVariant1BlockSchema'
import {
  sliderVariant2ComponentSchema,
  sliderVariant2ComponentsSchema,
} from '../../components/sliderVariant2ComponentSchema'

export const testimonialCarousel1BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema,
    tagline: textComponentSchema,
    heading: textComponentSchema,
    text: textComponentSchema,
    slider: sliderVariant2ComponentSchema
      .shape({
        components: sliderVariant2ComponentsSchema.shape({
          slides: containerComponentSchema.shape({
            items: array()
              .of(
                testimonialVariant1CardSchema
                  .shape({
                    id: idSchema.required(),
                  })
                  .noUnknown(),
              )
              .required(),
          }),
        }),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type TestimonialCarousel1Block = InferType<
  typeof testimonialCarousel1BlockSchema
>
