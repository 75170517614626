import { InferType, object, string } from 'yup'

export const colorStyleSchema = object({
  data: object({
    default: object({
      '--color-default-default': string(),
      '--color-xs-default': string(),
      '--color-md-default': string(),
      '--color-lg-default': string(),
    })
      .noUnknown()
      .optional(),
    hover: object({
      '--color-default-hover': string(),
      '--color-xs-hover': string(),
      '--color-md-hover': string(),
      '--color-lg-hover': string(),
    })
      .noUnknown()
      .optional(),
    active: object({
      '--color-default-active': string(),
      '--color-xs-active': string(),
      '--color-md-active': string(),
      '--color-lg-active': string(),
    })
      .noUnknown()
      .optional(),
  }).noUnknown(),
})
  .noUnknown()
  .meta({
    dataType: 'color-style',
  })

export type ColorStyle = InferType<typeof colorStyleSchema>
