import { Icon } from '@sceneio/ui-icons'

export const StatusIcon = ({
  status,
}: {
  status: 'loading' | 'success' | 'error' | 'info' | 'warning' | 'neutral'
}) => {
  if (status === 'neutral') {
    return null
  }

  if (status === 'loading') {
    return (
      <div className="tw-shrink-0 tw-w-5 tw-h-5 tw-border-2 tw-border-border-color tw-border-b-transparent tw-rounded-full tw-animate-spin"></div>
    )
  }

  if (status === 'success') {
    return (
      <div className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center">
        <div className="tw-w-5 tw-h-5 tw-bg-green-50 tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center">
          <Icon provider="phosphor" icon="Check" size={12} weight="bold" />
        </div>
      </div>
    )
  }
  if (status === 'info') {
    return (
      <div className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center">
        <div className="tw-w-5 tw-h-5 tw-bg-blue-400 tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center">
          <Icon provider="phosphor" icon="Megaphone" size={12} weight="bold" />
        </div>
      </div>
    )
  }
  if (status === 'warning') {
    return (
      <div className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center">
        <div className="tw-w-5 tw-h-5 tw-bg-[#ff8904] tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center">
          <Icon
            provider="phosphor"
            icon="ExclamationMark"
            size={12}
            weight="bold"
          />
        </div>
      </div>
    )
  }
  return (
    <div className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center">
      <div className="tw-w-5 tw-h-5 tw-bg-red-60 tw-text-white tw-rounded-full tw-flex tw-items-center tw-justify-center">
        <Icon provider="phosphor" icon="X" size={12} weight="bold" />
      </div>
    </div>
  )
}
