import { Action, AnyAction, Dispatch, Middleware } from 'redux'
import { LocalHistoryCommand } from '@sceneio/content-store/lib/localHistoryCommands/LocalHistoryCommand'
import { RootState, StoreType } from '@sceneio/content-store/lib/store'
import {
  updateRedoEnabled,
  updateUndoEnabled,
} from '@sceneio/content-store/lib/features/editor/editorSlice'

const DEBUG_MODE = false

export type EntryType = {
  actionType: string
  pastValue: any
  actionValue: any
}

let past: LocalHistoryCommand[] = []
let future: LocalHistoryCommand[] = []

function DEBUG_LOG(action: string) {
  if (DEBUG_MODE) {
    console.log('[LocalHistory] Action: ', action)
    console.log('[LocalHistory] Past: ', past)
    console.log('[LocalHistory] future: ', future)
  }
}

// This function handles the history buttons (undo and redo) by enabling or disabling them based on the presence of past and future states in the editor's history.
function handleHistoryButtons(
  dispatch: StoreType['dispatch'],
  getState: StoreType['getState'],
) {
  const editorState = getState().editor
  const isUndoEnabled = editorState.undoEnabled
  const isRedoEnabled = editorState.redoEnabled
  if (past.length) {
    if (!isUndoEnabled) {
      dispatch(updateUndoEnabled(true))
    }
  } else {
    if (isUndoEnabled) {
      dispatch(updateUndoEnabled(false))
    }
  }
  if (future.length) {
    if (!isRedoEnabled) {
      dispatch(updateRedoEnabled(true))
    }
  } else {
    if (isRedoEnabled) {
      dispatch(updateRedoEnabled(false))
    }
  }
}

export const localHistoryMiddleware: Middleware<{}, RootState> =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action instanceof LocalHistoryCommand) {
      future = []
      action.preExecute(getState)

      // Execute the command
      const promise = action.execute()

      past.push(action)

      handleHistoryButtons(dispatch, getState)

      DEBUG_LOG('action')
      return promise(dispatch, getState, {})
    } else {
      if (action.type === 'LOCAL_HISTORY_UNDO') {
        // Call the previous commands undo method
        const command = past.pop()
        if (!command) {
          return
        }

        const promise = command.undo(getState)

        if (!promise) {
          return
        }
        future.push(command)

        handleHistoryButtons(dispatch, getState)

        DEBUG_LOG('undo')

        return promise(dispatch, getState, {})
      }
      if (action.type === 'LOCAL_HISTORY_REDO') {
        // Call the previous commands undo method
        const command = future.pop()
        if (!command) {
          return
        }

        const promise = command?.redo
          ? command.redo(getState)
          : command.execute()
        past.push(command)

        handleHistoryButtons(dispatch, getState)

        DEBUG_LOG('redo')

        return promise(dispatch, getState, {})
      }
      if (action.type === 'LOCAL_HISTORY_RESET') {
        future = []
        past = []

        DEBUG_LOG('reset')

        return next(action)
      } else {
        return next(action)
      }
    }
  }
