import { object, InferType, mixed, string } from 'yup'

export const blockEditorSchema = object({
  component: object({
    className: string(),
    selectedBlockId: string(),
    selectedComponentId: string(),
    onClick: mixed<(data: { componentId?: string }) => void>(),
  }).noUnknown(),
})
  .noUnknown()
  .optional()

export type BlockEditor = InferType<typeof blockEditorSchema>
