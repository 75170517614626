import { InferType, object, string } from 'yup'

const allowedTransitionPropertyValues = [
  'all',
  'background-color',
  'font-size',
  'color',
  'box-shadow',
  'border',
  'transform',
  'padding',
  'margin',
  'width',
  'height',
  'none',
]

const transitionPropertyStyleSchema = string().matches(
  new RegExp(
    `^(${allowedTransitionPropertyValues.join(
      '|',
    )})(,\\s*(${allowedTransitionPropertyValues.join('|')}))*$`,
  ),
  'Invalid transition property values. Allowed values: ' +
    allowedTransitionPropertyValues.join(', '),
)

const transitionTimingFunctionStyleSchema = string().oneOf([
  'ease',
  'linear',
  'ease-in',
  'ease-out',
  'ease-in-out',
])

export const transitionDurationStyleSchema =
  string().matches(/^-?\d*\.?\d+(s)$/i)

export const transitionStyleSchema = object({
  data: object({
    default: object({
      '--transition-default-property-default': transitionPropertyStyleSchema,
      '--transition-default-timing-function-default':
        transitionTimingFunctionStyleSchema,
      '--transition-default-duration-default': transitionDurationStyleSchema,
      '--transition-xs-property-default': transitionPropertyStyleSchema,
      '--transition-xs-timing-function-default':
        transitionTimingFunctionStyleSchema,
      '--transition-xs-duration-default': transitionDurationStyleSchema,
      '--transition-md-property-default': transitionPropertyStyleSchema,
      '--transition-md-timing-function-default':
        transitionTimingFunctionStyleSchema,
      '--transition-md-duration-default': transitionDurationStyleSchema,
      '--transition-lg-property-default': transitionPropertyStyleSchema,
      '--transition-lg-timing-function-default':
        transitionTimingFunctionStyleSchema,
      '--transition-lg-duration-default': transitionDurationStyleSchema,
    })
      .noUnknown()
      .optional(),
    hover: object({
      '--transition-default-property-hover': transitionPropertyStyleSchema,
      '--transition-default-timing-function-hover':
        transitionTimingFunctionStyleSchema,
      '--transition-default-duration-hover': transitionDurationStyleSchema,
      '--transition-xs-property-hover': transitionPropertyStyleSchema,
      '--transition-xs-timing-function-hover':
        transitionTimingFunctionStyleSchema,
      '--transition-xs-duration-hover': transitionDurationStyleSchema,
      '--transition-md-property-hover': transitionPropertyStyleSchema,
      '--transition-md-timing-function-hover':
        transitionTimingFunctionStyleSchema,
      '--transition-md-duration-hover': transitionDurationStyleSchema,
      '--transition-lg-property-hover': transitionPropertyStyleSchema,
      '--transition-lg-timing-function-hover':
        transitionTimingFunctionStyleSchema,
      '--transition-lg-duration-hover': transitionDurationStyleSchema,
    })
      .noUnknown()
      .optional(),
    active: object({
      '--transition-default-property-active': transitionPropertyStyleSchema,
      '--transition-default-timing-function-active':
        transitionTimingFunctionStyleSchema,
      '--transition-default-duration-active': transitionDurationStyleSchema,
      '--transition-xs-property-active': transitionPropertyStyleSchema,
      '--transition-xs-timing-function-active':
        transitionTimingFunctionStyleSchema,
      '--transition-xs-duration-active': transitionDurationStyleSchema,
      '--transition-md-property-active': transitionPropertyStyleSchema,
      '--transition-md-timing-function-active':
        transitionTimingFunctionStyleSchema,
      '--transition-md-duration-active': transitionDurationStyleSchema,
      '--transition-lg-property-active': transitionPropertyStyleSchema,
      '--transition-lg-timing-function-active':
        transitionTimingFunctionStyleSchema,
      '--transition-lg-duration-active': transitionDurationStyleSchema,
    })
      .noUnknown()
      .optional(),
  }).noUnknown(),
}).noUnknown()

export type TransitionStyle = InferType<typeof transitionStyleSchema>
