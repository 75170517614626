import { PayloadAction, createSlice, current } from '@reduxjs/toolkit'
import { errorNotification } from '@sceneio/cms-notifications'
import { getComponentConfigPath } from '@sceneio/content-shared-helpers'
import {
  ContentBlockUpdateDataInput,
  RestoreContentBlockOutput,
  ReusableContentBlockUpdateDataInput,
} from '@sceneio/graphql-queries/dist/generated/graphqlTypes'
import {
  appendExternalMediaResourceReference,
  appendInternalLinkResourceReference,
  appendMediaResourceReference,
  deleteReference,
  getReferencePath,
  referencesCleanup,
} from '@sceneio/referencing-tools'
import { appendDocumentResourceReference } from '@sceneio/referencing-tools/lib/appendReference'
import { ReferencesType } from '@sceneio/referencing-tools/lib/referencesTypes'
import {
  validateContent,
  validateContentBlock,
  validateContentBlocks,
} from '@sceneio/schemas'
import { materializeSnippets } from '@sceneio/snippets-tools'
import {
  assocJMESPath,
  interleave,
  mergeDeepRight,
  removeEmptyValues,
} from '@sceneio/tools'
import { search } from 'jmespath'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { asyncThunkFetcher } from '../../helpers/asyncThunkFetcher'
import { createAppendCidThunk } from '../../helpers/createAppendCidThunk'
import {
  createGraphqlAsyncThunk,
  createGraphqlAsyncThunkByDocument,
} from '../../helpers/createGraphqlAsyncThunk'
import { flattenUpdate } from '../../helpers/flattenUpdate'
import { thunkReject } from '../../helpers/thunkReject'
import { UpdateBlockCommand } from '../../localHistoryCommands/UpdateBlockCommand'
import { UpdateReusableBlockCommand } from '../../localHistoryCommands/UpdateReusableBlockCommand'
import { GraphqlThunkData } from '../../types'
import {
  initialState as editorSliceInitialState,
  fetchReusableBlocks,
  setCanvasState,
  setSelectedEntity,
  toggleUi,
} from '../editor/editorSlice'
import {
  deleteWhiteboardContentEntities,
  updateWhiteboardContentEntitiesMeta,
} from '../whiteboard/whiteboardSlice'
import { WhiteboardContentEntitiesMetaUpdateResType } from '../whiteboard/whiteboardSliceTypes'
import type { AppDispatch, RootState } from './../../store'
import type {
  AddContentBlocksQueryVarsType,
  AssignReusableContentBlockQueryVarsType,
  AssignReusableContentBlocksQueryVarsType,
  BlockType,
  ContentBlockMetaType,
  ContentDataType,
  ContentSliceType,
  ContentType,
  DetachReusableContentBlockQueryVarsType,
  ReferencesToProcess,
  RegenerateBlocksQueryVarsType,
  RequestBlocksByAiv2QueryVarsType,
  RestoreContentBlocksQueryVarsType,
  ReusableContentBlockOutput,
  SelectContentBlockQueryVarsType,
  UpdateContentBlockOrderQueryVarsType,
  UpdateContentBlockQueryVarsType,
  UpdateContentBlocksQueryVarsType,
  UpdateContentDataQueryVarsType,
  UpdateContentQueryVarsType,
  UpdateReusableContentBlockQueryVarsType,
  UpdateReusableContentBlocksQueryVarsType,
  SetInteractionOnContentBlockQueryVarsType,
} from './contentSliceTypes'
import { materializeContentBlock } from './helpers'
import { WhiteboardContentEntitiesDeleteCommand } from '../../localHistoryCommands/WhiteboardContentEntitiesDeleteCommand'
import { AddBlocksCommand } from '../../localHistoryCommands/AddBlocksCommand'
import { createCid } from '../../helpers/createCid'
import {
  preprocessContentBlocksUpdateVariables,
  preprocessContentUpdateVariables,
} from './contentSlicePreprocessors'

// WARNING - DON NOT CONNECT BLOCKS MAP !!!!
// if we connect blocksMap here, HMR will reload every time we change anything in UICORE,
// because the redux is connected to src/index.tsx and blocks/meta files are not optimised for fast refres

export * from './contentSliceMemoizedSelectorHooks'
export * from './contentSliceSelectors'

const SKIP_CUSTOM_BLOCKS_UPDATE = ['dynamicMasonryGrid']

export const APPEND_REFERENCE_METHOD_BY_TYPE_MAP = {
  media: appendMediaResourceReference,
  'external-media': appendExternalMediaResourceReference,
  'internal-link': appendInternalLinkResourceReference,
  'internal-file': appendDocumentResourceReference,
} as const

// ---------------
// Initial State
// ---------------
export const initialState: ContentSliceType = {
  entity: null,
  status: 'idle',
  error: null,
  requests: {
    updateBlockRequestId: '',
    updateContentDataRequestId: '',
    updateContentRequestId: '',
    updateBlocksRequestId: '',
    updateReusableBlocksRequestId: '',
  },
} as ContentSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Thunks
// ---------------

export const fetchContent = createGraphqlAsyncThunkByDocument<
  'ContentDataQueryDocument',
  'content'
>()('content/fetchData', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'ContentDataQueryDocument',
    selector: 'content',
    variables: queryVariables,
    thunkAPI,
    validateDataCallback: (data) => {
      if (!data) {
        return { isValid: false, error: 'Content Data not provided' }
      }

      return { isValid: true }
    },
  })
})

export const updateContent = createGraphqlAsyncThunkByDocument<
  'UpdateContentDataDocument',
  'updateContent',
  UpdateContentQueryVarsType
>()(
  'content/updateContent',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const variables = preprocessContentUpdateVariables(queryVariables, thunkAPI)

    return await asyncThunkFetcher({
      query: 'UpdateContentDataDocument',
      selector: 'updateContent',
      variables,
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then(async (res) => {
      if (thunkOptions?.onSuccess) {
        await thunkOptions.onSuccess()
      }
      return res
    })
  },
)

export const pageRegenerateUndoRedo = createGraphqlAsyncThunkByDocument<
  any,
  never,
  {
    queryVariables: {
      contentBlocksToDelete: Array<{
        cid: string
        id: string
      }>
      contentBlocksToRestore: Array<{
        cid: string
      }>
      content?: UpdateContentQueryVarsType['queryVariables']
    }
    thunkOptions?: {
      onSuccess?: () => void
    }
  }
>()('content/pageRegenerateUndoRedo', async ({ queryVariables }, thunkAPI) => {
  const contentId = thunkAPI.getState().content.entity?.id

  const { contentBlocksToRestore, content, contentBlocksToDelete } =
    queryVariables

  const deletedCurrentContentBlocks = asyncThunkFetcher({
    query: 'WhiteboardContentEntitiesDeleteMutationDocument',
    selector: 'deleteWhiteboardContentEntities',
    variables: { deleteInput: contentBlocksToDelete, contentId: contentId! },
    thunkAPI,
    rejectQueries: ['CONTENT', 'CONTENT_MODULES'],
  })

  const restoredContentBlocks = asyncThunkFetcher({
    query: 'ContentBlocksRestoreMutationDocument',
    selector: 'restoreContentBlocks',
    variables: { restoreInput: contentBlocksToRestore, contentId: contentId! },
    thunkAPI,
    rejectQueries: ['CONTENT'],
  })

  let updatedContent: any = Promise.resolve()

  if (content) {
    updatedContent = asyncThunkFetcher({
      query: 'UpdateContentDataDocument',
      selector: 'updateContent',
      variables: preprocessContentUpdateVariables(content, thunkAPI),
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  }

  await Promise.all([
    deletedCurrentContentBlocks,
    restoredContentBlocks,
    updatedContent,
  ])

  const [contentBlocksResult, contentResult] = await Promise.all([
    restoredContentBlocks,
    content ? updatedContent : Promise.resolve(null),
  ])

  return {
    contentBlocks: contentBlocksResult,
    ...(contentResult && { content: contentResult }),
  }
})

export const restoreContentBlocks = createGraphqlAsyncThunkByDocument<
  'ContentBlocksRestoreMutationDocument',
  'restoreContentBlocks',
  RestoreContentBlocksQueryVarsType
>()('content/restoreContentBlocks', async ({ queryVariables }, thunkAPI) => {
  const contentId = thunkAPI.getState().content.entity?.id

  return await asyncThunkFetcher({
    query: 'ContentBlocksRestoreMutationDocument',
    selector: 'restoreContentBlocks',
    variables: { ...queryVariables, contentId },
    thunkAPI,
    rejectQueries: ['CONTENT'],
  })
})

export const pageMultiUpdate = createGraphqlAsyncThunkByDocument<
  any,
  never,
  {
    queryVariables: {
      contentBlocks: Array<ContentBlockUpdateDataInput>
      content?: UpdateContentQueryVarsType['queryVariables']
      project?: {
        preferences: Record<string, any>
      }
    }
    thunkOptions?: {
      onSuccess?: () => void
    }
  }
>()(
  'content/pageMultiUpdate',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const { contentBlocks, content, project } = queryVariables

    const updateContentBlocksVariables = preprocessContentBlocksUpdateVariables(
      contentBlocks,
      thunkAPI,
    )

    const updatedContentBlocks = asyncThunkFetcher({
      query: 'ContentBlocksUpdateMutationDocument',
      selector: 'updateContentBlocks',
      variables: {
        updateInput: updateContentBlocksVariables.updateInput,
      },
      thunkAPI,
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess()
      }
      if (!updateContentBlocksVariables?.preventSetContentUserHasInteracted) {
        thunkAPI.dispatch(setContentPreferencesUserHasInteracted())
      }
      return res
    })

    let updatedContent: any = Promise.resolve()

    if (content) {
      updatedContent = asyncThunkFetcher({
        query: 'UpdateContentDataDocument',
        selector: 'updateContent',
        variables: preprocessContentUpdateVariables(content, thunkAPI),
        thunkAPI,
      })
    }

    let updatedProject: any = Promise.resolve()

    if (project) {
      updatedProject = asyncThunkFetcher({
        query: 'ProjectUpdateSettingsMutationDocument',
        selector: 'updateProjectSettings',
        variables: {
          preferences: project.preferences,
        },
        thunkAPI,
      })
    }

    await Promise.all([updatedContentBlocks, updatedContent, updatedProject])

    const [contentBlocksResult, contentResult, projectResult] =
      await Promise.all([
        updatedContentBlocks,
        content ? updatedContent : Promise.resolve(null),
        project ? updatedProject : Promise.resolve(null),
      ])

    return {
      contentBlocks: contentBlocksResult,
      ...(contentResult && { content: contentResult }),
      ...(projectResult && { project: projectResult }),
    }
  },
)

export const updateContentData = createGraphqlAsyncThunkByDocument<
  'UpdateContentDataDocument',
  'updateContent',
  UpdateContentDataQueryVarsType
>()(
  'content/updateContentData',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const {
      configPath,
      configValue,
      referencesToProcess = [],
      referencesToReplace,
      preferences,
    } = queryVariables
    const content = thunkAPI.getState().content.entity
    const contentId = content?.id
    const references = content?.references as ReferencesType
    const layoutConfig = content?.data || {}

    let layoutData = layoutConfig

    if (configPath !== undefined && configValue !== undefined) {
      if (thunkOptions?.shouldReplaceValue) {
        layoutData = assocJMESPath(
          configPath,
          configValue,
          layoutConfig,
        ) as ContentDataType
      } else {
        layoutData = mergeDeepRight({ config: configValue }, layoutConfig)
      }
    }

    const { isValid, error } = validateContent({
      data: layoutData,
    })

    if (!isValid) {
      errorNotification({
        content: 'Invalid content data',
        log: {
          message: '[updateContent]: Content data validation failed',
          data: {
            dataToValidate: layoutData,
            validationError: error,
            thunkQueryVariables: queryVariables,
          },
        },
      })

      return thunkReject({
        code: 'invalid_data',
        message: 'Invalid content data',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    // process references
    let updatedReferences = references
    referencesToProcess.forEach(({ type, operation, data }) => {
      const referencePath = getReferencePath({
        configPath: data.path!,
        data: { data: layoutData },
      })

      if (operation === 'add') {
        const appendReferencesMethod =
          APPEND_REFERENCE_METHOD_BY_TYPE_MAP[
            type as keyof typeof APPEND_REFERENCE_METHOD_BY_TYPE_MAP
          ]

        if (appendReferencesMethod) {
          updatedReferences = appendReferencesMethod({
            references,
            data: {
              id: data.id!,
              path: referencePath,
            },
          })
        }
      }
    })

    // final references cleanup
    updatedReferences = referencesCleanup({
      references: updatedReferences,
      data: { data: layoutData },
    })

    return await asyncThunkFetcher({
      query: 'UpdateContentDataDocument',
      selector: 'updateContent',
      variables: {
        id: contentId,
        data: layoutData,
        preferences: { ...content?.preferences, ...preferences },
        references:
          (referencesToReplace ? referencesToReplace : updatedReferences) || [],
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  },
)

export const detachReusableContentBlock = createGraphqlAsyncThunk<
  ContentType | null,
  DetachReusableContentBlockQueryVarsType
>(
  'content/detachReusableContentBlock',
  async ({ queryVariables }, thunkAPI) => {
    const { blockId } = queryVariables
    const reusableContentBlock = thunkAPI
      .getState()
      .content.entity?.contentBlocks.find(
        (block) => block.id === blockId && block.isReusable,
      )

    const reusableContentBlockConfig =
      reusableContentBlock?.reusableContentBlockDraft?.config ||
      reusableContentBlock?.config

    if (reusableContentBlock) {
      await thunkAPI.dispatch(
        new WhiteboardContentEntitiesDeleteCommand({
          queryVariables: {
            deleteInput: [{ id: blockId, entity: 'CONTENTBLOCK' }],
          },
        }),
      )

      return thunkAPI.dispatch(
        addBlocks({
          queryVariables: {
            position: reusableContentBlock.order,

            contentPropertyName: reusableContentBlock.contentPropertyName,
            contentBlocksData: [
              {
                cid: createCid(),
                config: reusableContentBlockConfig,
                type: reusableContentBlock.type,
                isRenderable: true,
                customType: reusableContentBlock.customType,
                references: [],
              },
            ],
          },
        }),
      )
    }

    return Promise.resolve(null)
  },
)

export const setContentPreferencesUserHasInteracted = createGraphqlAsyncThunk<
  ContentType | null,
  undefined
>('content/setContentPreferencesUserHasInteracted', async (_, thunkAPI) => {
  const content = thunkAPI.getState().content.entity
  const contentBlocks = thunkAPI.getState().content.entity?.contentBlocks
  const contentId = content?.id
  const contentReferences = content?.references

  const currentUserHasInteracted = content?.preferences?.userHasInteracted
  const isEmptyContent = contentBlocks?.length === 0

  if (currentUserHasInteracted && !isEmptyContent) {
    return null
  }

  return await asyncThunkFetcher({
    query: 'UpdateContentDataDocument',
    selector: 'updateContent',
    variables: {
      id: contentId,
      preferences: {
        ...content?.preferences,
        userHasInteracted: isEmptyContent ? false : true,
      },
      references: contentReferences,
    },
    rejectQueries: ['CONTENT'],
    thunkAPI,
  })
})

export const updateContentPath = createGraphqlAsyncThunkByDocument<
  'PathUpdateMutationDocument',
  'updatePath'
>()('content/updatePath', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'PathUpdateMutationDocument',
    selector: 'updatePath',
    variables: queryVariables,
    rejectQueries: ['CONTENT'],
    thunkAPI,
  })
})

export const requestContentBlocksByAiV2 = createGraphqlAsyncThunkByDocument<
  'RequestContentBlocksByAiv2Document',
  'requestContentBlocksByAIv2',
  RequestBlocksByAiv2QueryVarsType
>()(
  'content/blocks/requestByAIV2',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const state = thunkAPI.getState()
    const contentId = state.content.entity?.id
    const aiChatContext = state.editor.aiChatContext

    return await asyncThunkFetcher({
      query: 'RequestContentBlocksByAiv2Document',
      selector: 'requestContentBlocksByAIv2',
      variables: {
        ...queryVariables,
        contentId,
        inputData: queryVariables.inputData || aiChatContext || {},
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess()
      }
      if (!state.editor.showRightSidebar && !state.editor.showLeftSidebar) {
        thunkAPI.dispatch(toggleUi())
      }

      return res
    })
  },
)

export const addBlocks = createGraphqlAsyncThunkByDocument<
  'ContentBlocksAddMutationDocument',
  'addContentBlocks',
  AddContentBlocksQueryVarsType
>()(
  'content/blocks/add',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const contentId = thunkAPI.getState().content.entity?.id
    const state = thunkAPI.getState()

    if (!state.editor.showRightSidebar && !state.editor.showLeftSidebar) {
      thunkAPI.dispatch(toggleUi())
    }
    return await asyncThunkFetcher({
      query: 'ContentBlocksAddMutationDocument',
      selector: 'addContentBlocks',
      variables: { ...queryVariables, contentId },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess()
      }
      return res
    })
  },
)

export const regenerateBlocks = createGraphqlAsyncThunk<
  void,
  RegenerateBlocksQueryVarsType
>('content/blocks/regenerate', async ({ queryVariables }, thunkAPI) => {
  const allRenderableContentBlocksIds =
    thunkAPI
      .getState()
      .content.entity?.contentBlocks.filter(({ isRenderable }) => isRenderable)
      .map(({ id }) => id) || []

  thunkAPI.dispatch(setCanvasState('REGENERATING'))

  if (allRenderableContentBlocksIds.length === 0) {
    thunkAPI
      .dispatch(
        new AddBlocksCommand({
          queryVariables,
        }),
      )
      .then(() => {
        thunkAPI.dispatch(setCanvasState(null))
      })
  } else {
    thunkAPI
      .dispatch(
        new WhiteboardContentEntitiesDeleteCommand({
          queryVariables: {
            deleteInput: allRenderableContentBlocksIds.map((id) => ({
              id,
              entity: 'CONTENTBLOCK',
            })),
          },
        }),
      )
      .then(() => {
        thunkAPI
          .dispatch(
            new AddBlocksCommand({
              queryVariables,
            }),
          )
          .then(() => {
            thunkAPI.dispatch(setCanvasState(null))
          })
      })
  }
})

export const duplicateContentBlock = createGraphqlAsyncThunkByDocument<
  'ContentBlockDuplicateMutationDocument',
  'duplicateContentBlock'
>()('content/block/duplicate', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'ContentBlockDuplicateMutationDocument',
    selector: 'duplicateContentBlock',
    variables: queryVariables,
    thunkAPI,
    rejectQueries: ['CONTENT'],
  })
})

export const assignReusableContentBlock = createGraphqlAsyncThunkByDocument<
  'AssignReusableContentBlockDocument',
  'assignReusableContentBlock',
  AssignReusableContentBlockQueryVarsType
>()(
  'content/reusableContentBlock/assign',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const state = thunkAPI.getState()

    if (!state.editor.showRightSidebar && !state.editor.showLeftSidebar) {
      thunkAPI.dispatch(toggleUi())
    }
    const contentId = state.content.entity?.id
    return await asyncThunkFetcher({
      query: 'AssignReusableContentBlockDocument',
      selector: 'assignReusableContentBlock',
      variables: { ...queryVariables, contentId },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess(res)
      }
      return res
    })
  },
)

export const assignReusableContentBlocks = createGraphqlAsyncThunkByDocument<
  'AssignReusableContentBlocksDocument',
  'assignReusableContentBlocks',
  AssignReusableContentBlocksQueryVarsType
>()(
  'content/reusableContentBlocks/assign',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const contentId = thunkAPI.getState().content.entity?.id
    return await asyncThunkFetcher({
      query: 'AssignReusableContentBlocksDocument',
      selector: 'assignReusableContentBlocks',
      variables: { ...queryVariables, contentId },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess(res)
      }
      return res
    })
  },
)

export const duplicateBlockWithCid = createAppendCidThunk<
  typeof duplicateContentBlock
>(duplicateContentBlock)
export const assignReusableContentBlockWithCid = createAppendCidThunk<
  typeof assignReusableContentBlock
>(assignReusableContentBlock)

export const mergeReusableContentBlockDraft = createGraphqlAsyncThunkByDocument<
  'MergeReusableContentBlockDraftDocument',
  'mergeReusableContentBlockDraft'
>()(
  'content/reusableContentBlock/mergeDraft',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const contentId = thunkAPI.getState().content.entity?.id
    return await asyncThunkFetcher({
      query: 'MergeReusableContentBlockDraftDocument',
      selector: 'mergeReusableContentBlockDraft',
      variables: {
        ...queryVariables,
        contentId,
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      if (thunkOptions?.onSuccess) {
        thunkOptions.onSuccess()
      }

      return res
    })
  },
)

// delete parent reusable content block which is not assigned to any content
export const deleteReusableContentBlock = createGraphqlAsyncThunkByDocument<
  'DeleteReusableContentBlockDocument',
  'deleteReusableContentBlock'
>()(
  'content/reusableContentBlock/delete',
  async ({ queryVariables }, thunkAPI) => {
    return await asyncThunkFetcher({
      query: 'DeleteReusableContentBlockDocument',
      selector: 'deleteReusableContentBlock',
      variables: queryVariables,
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  },
)

export const deleteReusableContentBlockDraft =
  createGraphqlAsyncThunkByDocument<
    'DeleteReusableContentBlockDocument',
    'deleteReusableContentBlock'
  >()(
    'content/reusableContentBlock/deleteDraft',
    async ({ queryVariables }, thunkAPI) => {
      const contentId = thunkAPI.getState().content.entity?.id
      return await asyncThunkFetcher({
        query: 'DeleteReusableContentBlockDocument',
        selector: 'deleteReusableContentBlock',
        variables: {
          ...queryVariables,
          contentId,
        },
        rejectQueries: ['CONTENT'],
        thunkAPI,
      })
    },
  )

export const makeContentBlockReusable = createGraphqlAsyncThunkByDocument<
  'SetAsReusableContentBlockDocument',
  'setAsReusableContentBlock'
>()(
  'content/block/makeContentBlockReusable',
  async ({ queryVariables }, thunkAPI) => {
    const result = await asyncThunkFetcher({
      query: 'SetAsReusableContentBlockDocument',
      selector: 'setAsReusableContentBlock',
      variables: queryVariables,
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })

    thunkAPI.dispatch(fetchReusableBlocks({ queryVariables: {} }))

    return result
  },
)

export type SetContentBlocksAsRenderableThunkType = GraphqlThunkData<{
  setRenderableInput: {
    id: string
    meta?: ContentBlockMetaType
  }[]
  position: number
}>

export const setContentBlocksAsRenderable = createGraphqlAsyncThunkByDocument<
  'SetAsRenderableContentBlocksMutationDocument',
  'setContentBlocksAsRenderable'
>()(
  'content/blocks/setContentBlocksAsRenderable',
  async ({ queryVariables }, thunkAPI) => {
    return await asyncThunkFetcher({
      query: 'SetAsRenderableContentBlocksMutationDocument',
      selector: 'setContentBlocksAsRenderable',
      variables: queryVariables,
      thunkAPI,
    })
  },
)

export type SetContentBlocksAsNonRenderableThunkType = GraphqlThunkData<{
  setNonRenderableInput: {
    id: string
    meta: ContentBlockMetaType
  }[]
}>

export const setContentBlocksAsNonRenderable =
  createGraphqlAsyncThunkByDocument<
    'SetAsNonRenderableContentBlocksMutationDocument',
    'setContentBlocksAsNonRenderable'
  >()(
    'content/blocks/setContentBlocksAsNonRenderable',
    async ({ queryVariables }, thunkAPI) => {
      return await asyncThunkFetcher({
        query: 'SetAsNonRenderableContentBlocksMutationDocument',
        selector: 'setContentBlocksAsNonRenderable',
        variables: queryVariables,
        rejectQueries: ['CONTENT'],
        thunkAPI,
      })
    },
  )

export const updateReusableContentBlock = createGraphqlAsyncThunkByDocument<
  'ReusableContentBlocksUpdateMutationDocument',
  'updateReusableContentBlocks',
  UpdateReusableContentBlockQueryVarsType
>()(
  'content/reusableContentBlock/update',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const {
      blockId,
      name,
      configPath,
      configValue,
      customConfigValue,
      referencesToProcess = [],
      referencesToReplace,
    } = queryVariables

    const contentId = thunkAPI.getState().content.entity?.id
    let block = thunkAPI
      .getState()
      .content.entity?.contentBlocks.find((block) => block.id === blockId)

    if (!block) {
      return thunkReject({
        code: 'data_not_found',
        message: 'Block not found',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    if (block.isReusable) {
      block = block?.reusableContentBlockDraft || block
    }

    // TODO temp disallow update on dynamic masonry grid, in the future we want to refactor block meta to disallow updates on editor level
    if (
      block.customType &&
      SKIP_CUSTOM_BLOCKS_UPDATE.includes(block.customType)
    ) {
      return thunkReject({
        code: 'block_skip',
        message: 'Block Update Skipped',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    let blockConfig = block.config || {}
    if (configPath !== undefined && configValue !== undefined) {
      if (thunkOptions?.shouldReplaceValue) {
        blockConfig = assocJMESPath(configPath, configValue, block.config)
      } else {
        blockConfig = flattenUpdate(block.config, configValue, configPath)
      }

      if (thunkOptions?.shouldRemoveEmptyValues) {
        blockConfig = assocJMESPath(
          configPath,
          removeEmptyValues(search(blockConfig, configPath)),
          blockConfig,
        )
      }
    }

    const blockCustomConfig = customConfigValue || block.customConfig || {}

    const dataToValidate = {
      ...block,
      config: blockConfig,
    }
    const { isValid, error } = validateContentBlock(dataToValidate)

    if (!isValid) {
      errorNotification({
        content: 'Invalid block data',
        log: {
          message: `[updateReusableContentBlock]:  "${block.type}" block data validation failed`,
          data: {
            dataToValidate: dataToValidate,
            validationError: error,
            thunkQueryVariables: queryVariables,
          },
        },
      })

      return thunkReject({
        code: 'invalid_data',
        message: 'Invalid block data',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    const { references: blockReferences } = block

    // process references
    let updatedReferences = blockReferences
    referencesToProcess.forEach(({ type, operation, data }) => {
      const referencePath = getReferencePath({
        configPath: data.path!,
        data: { config: blockConfig },
      })

      if (operation === 'delete') {
        updatedReferences = deleteReference({
          references: updatedReferences,
          path: referencePath,
        })
      }

      if (operation === 'add') {
        const appendReferencesMethod =
          APPEND_REFERENCE_METHOD_BY_TYPE_MAP[
            type as keyof typeof APPEND_REFERENCE_METHOD_BY_TYPE_MAP
          ]

        if (appendReferencesMethod) {
          updatedReferences = appendReferencesMethod({
            blockReferences,
            data: {
              id: data.id!,
              path: referencePath,
            },
          })
        }
      }
    })

    // final references cleanup
    updatedReferences = referencesCleanup({
      references: updatedReferences,
      data: { config: blockConfig },
    })

    return await asyncThunkFetcher({
      query: 'ReusableContentBlocksUpdateMutationDocument',
      selector: 'updateReusableContentBlocks',
      variables: {
        updateInput: [
          {
            id: block.id,
            name,
            contentId,
            config: blockConfig,
            customConfig: blockCustomConfig,
            references:
              (referencesToReplace ? referencesToReplace : updatedReferences) ||
              [],
          },
        ],
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  },
)

export const updateContentBlocks = createGraphqlAsyncThunkByDocument<
  'ContentBlocksUpdateMutationDocument',
  'updateContentBlocks',
  UpdateContentBlocksQueryVarsType
>()(
  'content/blocks/update',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const { updateInput, preventSetContentUserHasInteracted } =
      preprocessContentBlocksUpdateVariables(queryVariables, thunkAPI)

    return await asyncThunkFetcher({
      query: 'ContentBlocksUpdateMutationDocument',
      selector: 'updateContentBlocks',
      variables: {
        updateInput,
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then(async (res) => {
      if (thunkOptions?.onSuccess) {
        await thunkOptions.onSuccess()
      }
      if (!preventSetContentUserHasInteracted) {
        thunkAPI.dispatch(setContentPreferencesUserHasInteracted())
      }
      return res
    })
  },
)

export const updateReusableContentBlocks = createGraphqlAsyncThunkByDocument<
  'ReusableContentBlocksUpdateMutationDocument',
  'updateReusableContentBlocks',
  UpdateReusableContentBlocksQueryVarsType
>()(
  'content/reusableContentBlocks/update',
  async ({ queryVariables }, thunkAPI) => {
    const { updateInput } = queryVariables
    const contentId = thunkAPI.getState().content.entity?.id
    const snippets = thunkAPI.getState().snippets.entities
    const currentReusableContentBlocks = thunkAPI
      .getState()
      .content.entity?.contentBlocks.filter(({ isReusable }) => isReusable)

    // reusableContentBlocks's config is optional during update, we need to used current reusableContentBlocks's config if not provided
    const reusableContentBlocks: ReusableContentBlockUpdateDataInput[] =
      updateInput.map((reusableContentBlock) => {
        const {
          config: currentReusableContentBlockConfig,
          reusableContentBlockDraft,
        } =
          currentReusableContentBlocks?.find(
            ({ id, reusableContentBlockDraft }) =>
              id === reusableContentBlock.id ||
              reusableContentBlockDraft?.id === reusableContentBlock.id,
          ) || {}

        return {
          ...reusableContentBlock,
          config:
            reusableContentBlock.config ||
            reusableContentBlockDraft?.config ||
            currentReusableContentBlockConfig ||
            {},
        }
      })

    // safety check if provided references are materialized correctly
    const materializedReusableContentBlocks = reusableContentBlocks.map(
      (reusableContentBlock) =>
        materializeContentBlock({
          block: reusableContentBlock,
          snippets,
        }),
    )

    const { isValid, error } = validateContentBlocks({
      contentBlocks: materializedReusableContentBlocks,
    })

    if (!isValid) {
      errorNotification({
        content: 'Invalid reusable content blocks',
        log: {
          message:
            '[updateReusableContentBlocks]: Reusable content blocks data validation failed',
          data: {
            dataToValidate: materializedReusableContentBlocks,
            validationError: error,
            thunkQueryVariables: queryVariables,
          },
        },
      })

      return thunkReject({
        code: 'invalid_data',
        message: 'Invalid content data',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    const contentBlocksWithContentIdAndCleanedReferences =
      reusableContentBlocks?.map((reusableContentBlock) => ({
        ...reusableContentBlock,
        contentId,
        references: referencesCleanup({
          references: reusableContentBlock?.references || [],
          data: { config: reusableContentBlock?.config },
        }),
      }))

    return await asyncThunkFetcher({
      query: 'ReusableContentBlocksUpdateMutationDocument',
      selector: 'updateReusableContentBlocks',
      variables: {
        updateInput: contentBlocksWithContentIdAndCleanedReferences,
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      thunkAPI.dispatch(setContentPreferencesUserHasInteracted())
      return res
    })
  },
)

export const updateBlock = createGraphqlAsyncThunkByDocument<
  'ContentBlockUpdateMutationDocument',
  'updateContentBlock',
  UpdateContentBlockQueryVarsType
>()(
  'content/block/update',
  async ({ queryVariables, thunkOptions }, thunkAPI) => {
    const {
      blockCid,
      blockId,
      configPath,
      name,
      configValue,
      customConfigValue,
      referencesToProcess = [],
      referencesToReplace,
      type,
    } = queryVariables

    const block = thunkAPI
      .getState()
      .content.entity?.contentBlocks.find(
        (block) => block.cid === blockCid || block.id === blockId,
      )

    if (!block) {
      return thunkReject({
        code: 'data_not_found',
        message: 'Block not found',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    let blockConfig = block.config || {}
    if (configPath !== undefined && configValue !== undefined) {
      if (thunkOptions?.shouldReplaceValue) {
        blockConfig = assocJMESPath(configPath, configValue, block.config)
      } else {
        blockConfig = flattenUpdate(block.config, configValue, configPath)
      }

      if (thunkOptions?.shouldRemoveEmptyValues) {
        blockConfig = assocJMESPath(
          configPath,
          removeEmptyValues(search(blockConfig, configPath)),
          blockConfig,
        )
      }
    }

    const blockCustomConfig = customConfigValue || block.customConfig || {}

    const dataToValidate = {
      ...block,
      type: type || block.type,
      config: blockConfig,
    }

    const { isValid, error } = validateContentBlock(dataToValidate)

    if (!isValid) {
      errorNotification({
        content: 'Invalid block data',
        log: {
          message: `[updateBlock]: "${block.type}" block data validation failed`,
          data: {
            validationError: error,
            thunkQueryVariables: queryVariables,
            dataToValidate,
          },
        },
      })

      return thunkReject({
        code: 'invalid_data',
        message: 'Invalid block data',
        thunkAPI,
        rejectQueries: ['CONTENT'],
      })
    }

    const { references: blockReferences } = block

    // process references
    let updatedReferences = blockReferences
    referencesToProcess.forEach(({ type, operation, data }) => {
      const referencePath = getReferencePath({
        configPath: data.path!,
        data: { config: blockConfig },
      })

      if (operation === 'delete') {
        updatedReferences = deleteReference({
          references: updatedReferences,
          path: referencePath,
        })
      }

      if (operation === 'add') {
        const appendReferencesMethod =
          APPEND_REFERENCE_METHOD_BY_TYPE_MAP[
            type as keyof typeof APPEND_REFERENCE_METHOD_BY_TYPE_MAP
          ]

        if (appendReferencesMethod) {
          updatedReferences = appendReferencesMethod({
            references: blockReferences,
            data: {
              id: data.id!,
              path: referencePath,
            },
          })
        }
      }
    })

    // final references cleanup
    updatedReferences = referencesCleanup({
      references: updatedReferences,
      data: { config: blockConfig },
    })

    return await asyncThunkFetcher({
      query: 'ContentBlockUpdateMutationDocument',
      selector: 'updateContentBlock',
      variables: {
        id: block.id,
        name: name,
        config: blockConfig,
        customConfig: blockCustomConfig,
        type: type || block.type,
        references:
          (referencesToReplace ? referencesToReplace : updatedReferences) || [],
      },
      thunkAPI,
      rejectQueries: ['CONTENT'],
    }).then((res) => {
      thunkAPI.dispatch(setContentPreferencesUserHasInteracted())
      return res
    })
  },
)

export const moveBlockToIndex = createGraphqlAsyncThunkByDocument<
  'ContentBlockOrderUpdateMutationDocument',
  'updateContentBlocksOrder',
  UpdateContentBlockOrderQueryVarsType
>()('content/blocks/reorder', async ({ queryVariables }, thunkAPI) => {
  const { toIndex, blockId, blockCid } = queryVariables
  const state = thunkAPI.getState()
  if (state.content.status !== 'succeeded') {
    return thunkReject({
      code: 'data_not_found',
      message: 'Blocks is undefined',
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  }
  const contentId = state.content.entity.id
  const renderableContentBlocks = state.content.entity.contentBlocks.filter(
    ({ isRenderable }) => isRenderable,
  )
  let id = blockId

  if (blockCid || blockId) {
    const block = state.content.entity.contentBlocks.find(
      ({ cid, id }) => cid === blockCid || id === blockId,
    )
    if (block) {
      id = block.id
    }
  }

  if (!renderableContentBlocks) {
    return thunkReject({
      code: 'data_not_found',
      message: 'Blocks is undefined',
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  }

  let sortedBlockIds = [...renderableContentBlocks.map(({ id }) => id)]

  // Find current index of block
  const fromIndex = sortedBlockIds.indexOf(id!)
  // Move block from current index to target index
  if (fromIndex !== -1) {
    const movedBlock = sortedBlockIds[fromIndex]
    sortedBlockIds = sortedBlockIds.toSpliced(fromIndex, 1)
    // When moving forward (fromIndex < toIndex), we need to adjust the target index
    // since removing the item shifts all subsequent indices down by 1
    const adjustedToIndex = fromIndex < toIndex ? toIndex - 1 : toIndex

    // pending state can already mutated the array
    if (sortedBlockIds[adjustedToIndex] !== id) {
      sortedBlockIds = sortedBlockIds.toSpliced(adjustedToIndex, 0, movedBlock)
    }
  }

  return await asyncThunkFetcher({
    query: 'ContentBlockOrderUpdateMutationDocument',
    selector: 'updateContentBlocksOrder',
    variables: {
      orderData: [
        {
          blockIds: sortedBlockIds,
          contentPropertyName: 'blocks',
        },
      ],
      contentId,
    },
    thunkAPI,
    rejectQueries: ['CONTENT'],
  }).then((res) => {
    thunkAPI.dispatch(setContentPreferencesUserHasInteracted())

    return res
  })
})

export const selectAdjacentComponent =
  (direction: 'NEXT' | 'PREV', blockComponents: Record<string, string[]>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const contentState = getState().content
    const editorState = getState().editor
    const { selectedComponentId, selectedBlockId } = editorState.selectedEntity

    if (contentState.status !== 'succeeded') {
      return
    }

    const blockData = contentState.entity.contentBlocks.find(
      (block) => block.id === selectedBlockId,
    )
    if (!blockData || !blockData.type) {
      return
    }

    const blockComponentKeys = blockComponents[blockData.type]

    const selectCompontentAtIndex = (index: number) =>
      dispatch(
        selectContentBlock({
          queryVariables: {
            blockId: selectedBlockId,
            componentId: blockComponentKeys[index],
          },
        }),
      )

    const selectedCompontentIdx = blockComponentKeys.findIndex(
      (key) => selectedComponentId === key,
    )

    // TODO @tom manually skipping block component for now, pending block/componetns refactor
    if (direction === 'NEXT') {
      // at the end of the list
      if (selectedCompontentIdx + 1 >= blockComponentKeys.length) {
        return selectCompontentAtIndex(1)
      } else {
        return selectCompontentAtIndex(selectedCompontentIdx + 1)
      }
    }

    if (direction === 'PREV') {
      // at the start of the list
      if (selectedCompontentIdx === 1) {
        return selectCompontentAtIndex(blockComponentKeys.length - 1)
      } else {
        return selectCompontentAtIndex(selectedCompontentIdx - 1)
      }
    }
  }

export const selectAdjacentBlock =
  (direction: 'NEXT' | 'PREV') =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const contentState = getState().content
    const { selectedBlockId } = getState().editor.selectedEntity

    if (contentState.status !== 'succeeded') {
      return
    }
    const contentBlocks = contentState.entity.contentBlocks

    const selectBlockAtIndex = (index: number) =>
      dispatch(
        selectContentBlock({
          queryVariables: {
            blockId: contentBlocks[index].id,
            componentId: '',
          },
        }),
      )

    if (selectedBlockId) {
      const selectedBlockIndex = contentBlocks.findIndex(
        (block) => block.id === selectedBlockId,
      )

      if (direction === 'NEXT') {
        // at the end of the list
        if (selectedBlockIndex + 1 >= contentBlocks.length) {
          return selectBlockAtIndex(0)
        } else {
          return selectBlockAtIndex(selectedBlockIndex + 1)
        }
      }

      if (direction === 'PREV') {
        // at the start of the list
        if (selectedBlockIndex === 0) {
          return selectBlockAtIndex(contentBlocks.length - 1)
        } else {
          return selectBlockAtIndex(selectedBlockIndex - 1)
        }
      }
    } else {
      // no block selected
      if (direction === 'NEXT') {
        return selectBlockAtIndex(0)
      }
      if (direction === 'PREV') {
        return selectBlockAtIndex(contentBlocks.length - 1)
      }
    }
  }

export const selectAdjacentEntity =
  (direction: 'NEXT' | 'PREV', blockComponents: Record<string, string[]>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const { selectedComponentId, selectedBlockId } =
      getState().editor.selectedEntity

    if (selectedComponentId) {
      // Traverse Components
      dispatch(selectAdjacentComponent(direction, blockComponents))
    } else {
      // Traverse Blocks
      dispatch(selectAdjacentBlock(direction))
    }
  }

export const deselectContentBlock =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().editor.selectedEntity.selectedBlockId) {
      dispatch(
        selectContentBlock({
          queryVariables: { blockId: '', componentId: '', frame: 'default' },
        }),
      )
    }
  }

export const deselectComponent =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const { selectedBlockId, selectedComponentId } =
      getState().editor.selectedEntity
    if (selectedComponentId) {
      dispatch(
        selectContentBlock({
          queryVariables: {
            blockId: selectedBlockId,
            componentId: '',
          },
        }),
      )
    }
  }

export const hideComponent =
  (blockComponents: Record<string, any>) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const { selectedBlockId, selectedComponentId } =
      getState().editor.selectedEntity

    const contentBlocks = getState().content.entity?.contentBlocks || []

    // HIDE COMPONENT
    if (selectedBlockId && selectedComponentId) {
      const contentState = getState().content
      if (contentState.status !== 'succeeded') {
        return
      }

      const blockData = contentState.entity.contentBlocks.find(
        (block) => block.id === selectedBlockId,
      )
      if (!blockData) {
        return
      }

      const componentMetaArrayPath = interleave(
        selectedComponentId.split('.'),
        'components',
      )

      const componentMetaJmesPath = componentMetaArrayPath
        .join('.')
        .replaceAll(/\[\d+\]/g, '')

      const componentMeta = search(
        blockComponents[blockData.type!],
        componentMetaJmesPath,
      )

      const componentConfigPath = getComponentConfigPath({
        meta: blockComponents[blockData.type!],
        selectedComponentId,
      })

      // Is hideable
      if (!componentMeta?.disableToggleShow) {
        dispatch(
          new UpdateBlockCommand({
            queryVariables: {
              blockCid: blockData.cid!,
              configPath: componentConfigPath,
              configValue: {
                ...search(blockData.config, componentConfigPath),
                show: false,
              },
            },
          }),
        )
      }
    }

    return
  }

export const selectBlockFirstChild =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const { selectedBlockId, selectedComponentId } = state.editor.selectedEntity

    // disregard invalid conditions
    if (
      !selectedBlockId ||
      selectedComponentId ||
      state.content.status !== 'succeeded'
    ) {
      return
    }

    const blockData = state.content.entity.contentBlocks.find(
      (block) => block.id === selectedBlockId,
    )
    if (!blockData || !blockData.type) {
      return
    }

    const firstNonBlockComponent = 'blockContainer'

    if (firstNonBlockComponent) {
      dispatch(
        selectContentBlock({
          queryVariables: {
            blockId: selectedBlockId,
            componentId: firstNonBlockComponent,
          },
        }),
      )
    }
  }

export const selectContentBlock = createGraphqlAsyncThunkByDocument<
  'SetInteractionOnContentBlockDocument',
  'setInteractionOnContentBlock',
  SelectContentBlockQueryVarsType
>()('content/block/select', async ({ queryVariables }, thunkAPI) => {
  const { blockId, componentId, frame, triggeredBy, focusedFormMolecule } =
    queryVariables
  const state = thunkAPI.getState()
  const selectedBlockId = state.editor.selectedEntity.selectedBlockId
  // check if pending state => id === cid === blockId
  const isPendingState = Boolean(
    state.content.entity?.contentBlocks?.find(
      ({ id, cid }) => id === blockId && id === cid,
    ),
  )

  thunkAPI.dispatch(
    setSelectedEntity({
      selectedBlockId: blockId,
      selectedComponentId: componentId,
      selectedFrame: frame,
      triggeredBy,
      focusedFormMolecule,
    }),
  )

  if (!isPendingState) {
    // Blur action, remove interaction from previously selected block
    if (!blockId && selectedBlockId) {
      await asyncThunkFetcher({
        query: 'SetInteractionOnContentBlockDocument',
        selector: 'setInteractionOnContentBlock',
        variables: { id: selectedBlockId, action: 'BLUR' },
        thunkAPI,
      })
    }

    // set focus on newly selected block, blur the old
    if (blockId && blockId !== selectedBlockId) {
      const promises = []
      if (selectedBlockId) {
        // BLUR previous block
        promises.push(
          asyncThunkFetcher({
            query: 'SetInteractionOnContentBlockDocument',
            selector: 'setInteractionOnContentBlock',
            variables: { id: selectedBlockId, action: 'BLUR' },
            thunkAPI,
          }),
        )
      }

      // FOCUS new block
      promises.push(
        asyncThunkFetcher({
          query: 'SetInteractionOnContentBlockDocument',
          selector: 'setInteractionOnContentBlock',
          variables: { id: blockId, action: 'FOCUS' },
          thunkAPI,
        }),
      )

      // TODO if above mutations fail, it will not reject the selectContentBlock thunk
      // it is due to the fact that in order for thunk to be rejected we must return thunkApi.rejectWithValue
      // but we can't do that because we are have multiple async calls
      await Promise.all(promises)
    }
  }
})

export const blurSelectedContentBlock = createGraphqlAsyncThunkByDocument<
  'SetInteractionOnContentBlockDocument',
  'setInteractionOnContentBlock',
  SetInteractionOnContentBlockQueryVarsType
>()('content/block/blur', async ({ queryVariables }, thunkAPI) => {
  const { projectId } = queryVariables
  const selectedBlockId =
    thunkAPI.getState().editor.selectedEntity.selectedBlockId

  if (!selectedBlockId) {
    return thunkReject({
      code: 'not_found',
      message: 'No block selected',
      thunkAPI,
      rejectQueries: [],
    })
  }

  return await asyncThunkFetcher({
    query: 'SetInteractionOnContentBlockDocument',
    selector: 'setInteractionOnContentBlock',
    variables: { id: selectedBlockId, action: 'BLUR' },
    ...(projectId && {
      authContext: {
        projectId,
      },
    }),
    thunkAPI,
    rejectQueries: [],
  })
})

// ---------------
// Action creators
// ---------------

export const resolveUpdateContentBlock = (
  queryVariables: {
    blockId: string
    name?: string
    configValue?: Record<string, any>
    configPath?: string
    customConfigPath?: any
    customConfigValue?: any
    referencesToReplace?: ReferencesType
    referencesToProcess?: ReferencesToProcess
  },
  thunkOptions?: {
    shouldReplaceValue?: boolean
    shouldRemoveEmptyValues?: boolean
  },
) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const block = getState().content.entity?.contentBlocks.find(
      (block) => block.id === queryVariables.blockId,
    )

    if (!block) {
      return
    }

    const isReusableContentBlock = block?.isReusable

    // Check the state and dispatch appropriate actions
    if (isReusableContentBlock) {
      dispatch(new UpdateReusableBlockCommand({ queryVariables, thunkOptions }))
    } else {
      dispatch(
        new UpdateBlockCommand({
          queryVariables: { ...queryVariables, blockCid: block.cid! },
          thunkOptions,
        }),
      )
    }
  }
}

// ---------------
// Reducer
// ---------------

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    resetContentState: () => {
      return initialState
    },
    wsUpdateWhiteboardContentContentBlocksEntity: (
      state,
      action: PayloadAction<WhiteboardContentEntitiesMetaUpdateResType>,
    ) => {
      if (!state.entity) {
        return
      }

      const updatedWhiteboardContentEntities = action.payload
      const updatedWhiteboardContentContentBlockEntities =
        updatedWhiteboardContentEntities.filter(
          ({ entity }) => entity === 'CONTENTBLOCK',
        )

      updatedWhiteboardContentContentBlockEntities.forEach((contentBlock) => {
        const existingContentBlockIndex = state.entity.contentBlocks.findIndex(
          (existingContentBlock) => existingContentBlock.id === contentBlock.id,
        )

        if (existingContentBlockIndex > -1) {
          const existingContentBlock =
            state.entity.contentBlocks[existingContentBlockIndex]

          state.entity.contentBlocks[existingContentBlockIndex] = {
            ...existingContentBlock,
            meta: {
              ...existingContentBlock.meta,
              whiteboard: {
                ...existingContentBlock.meta.whiteboard,
                ...contentBlock.payload,
              },
            },
          }
        }
      })
    },
    wsAddContentBlocks: (state, action: PayloadAction<BlockType[]>) => {
      if (!state.entity) {
        return
      }
      const addedContentBlocks = action.payload

      let updatedContentBlocks = [...state.entity.contentBlocks]

      const currentNonRenderableContentBlocksLength =
        updatedContentBlocks.filter(({ isRenderable }) => !isRenderable).length

      addedContentBlocks.forEach((block) => {
        const existingBlockIndex = updatedContentBlocks.findIndex(
          ({ cid }) => cid === block.cid,
        )
        if (existingBlockIndex > -1) {
          // the same block already exists
          const existingBlock = updatedContentBlocks[existingBlockIndex]
          const isPending = existingBlock.cid === existingBlock.id
          if (isPending) {
            updatedContentBlocks[existingBlockIndex] = block
          }
          return
        }

        if (block.isRenderable) {
          updatedContentBlocks = updatedContentBlocks.toSpliced(
            block.order! + currentNonRenderableContentBlocksLength,
            0,
            block,
          )
        } else {
          updatedContentBlocks = updatedContentBlocks.toSpliced(
            currentNonRenderableContentBlocksLength,
            0,
            block,
          )
        }
      })

      const nextNonRenderableContentBlocksLength = updatedContentBlocks.filter(
        ({ isRenderable }) => !isRenderable,
      ).length

      updatedContentBlocks = updatedContentBlocks.toSorted(
        (a, b) => a.order - b.order,
      )

      // update order
      updatedContentBlocks.forEach((block, idx) => {
        if (block.isRenderable) {
          block.order = idx - nextNonRenderableContentBlocksLength
        }
      })

      state.entity.contentBlocks = updatedContentBlocks
    },
    wsUpdateContentBlocks: (state, action: PayloadAction<BlockType[]>) => {
      if (!state.entity) {
        return
      }
      const updatedContentBlocks = action.payload

      updatedContentBlocks.forEach((block) => {
        const blockId = block.id

        const blockIndex = state.entity.contentBlocks.findIndex(
          (block) => block.id === blockId,
        )

        state.entity.contentBlocks[blockIndex] = block
      })
    },
    wsRestoreContentBlocks: (
      state,
      action: PayloadAction<RestoreContentBlockOutput>,
    ) => {
      if (!state.entity) {
        return
      }
      const { restoredContentBlocks, renderableContentBlocks } = action.payload

      const contentBlocksByRenderableType = restoredContentBlocks.reduce(
        (acc, val) => {
          if (val.isRenderable) {
            acc.renderableContentBlocks.push(val)
          } else {
            acc.nonRenderableContentBlocks.push(val)
          }
          return acc
        },
        {
          renderableContentBlocks: [],
          nonRenderableContentBlocks: [],
        } as {
          renderableContentBlocks: BlockType[]
          nonRenderableContentBlocks: BlockType[]
        },
      )

      let updatedContentBlocks = [
        ...contentBlocksByRenderableType.nonRenderableContentBlocks,
        ...state.entity.contentBlocks,
        ...contentBlocksByRenderableType.renderableContentBlocks,
      ]

      const renderableContentBlocksCidsOrder = renderableContentBlocks.map(
        (block) => block.cid,
      )

      updatedContentBlocks = updatedContentBlocks.map((block) => {
        if (block.isRenderable) {
          const order = renderableContentBlocksCidsOrder.findIndex(
            (cid) => cid === block.cid,
          )
          return {
            ...block,
            order,
          }
        }
        return block
      })

      updatedContentBlocks = updatedContentBlocks.toSorted(
        (a, b) => a.order - b.order,
      )

      state.entity.contentBlocks = updatedContentBlocks
    },
    wsDeleteContentBlocks: (state, action: PayloadAction<BlockType[]>) => {
      if (!state.entity) {
        return
      }
      const deletedContentBlocks = action.payload

      let updatedContentBlocks = [...state.entity.contentBlocks]

      deletedContentBlocks.forEach((block) => {
        const blockId = block.id

        updatedContentBlocks = updatedContentBlocks.filter(
          (block) => block.id !== blockId,
        )
      })

      const nextNonRenderableContentBlocksLength = updatedContentBlocks.filter(
        ({ isRenderable }) => !isRenderable,
      ).length

      updatedContentBlocks = updatedContentBlocks.toSorted(
        (a, b) => a.order - b.order,
      )
      // update order
      updatedContentBlocks.forEach((block, idx) => {
        if (block.isRenderable) {
          block.order = idx - nextNonRenderableContentBlocksLength
        }
      })

      state.entity.contentBlocks = updatedContentBlocks
    },
    wsSortContentBlock: (state, action: PayloadAction<BlockType[]>) => {
      if (!state.entity) {
        return
      }

      state.entity.contentBlocks = action.payload.reduce<BlockType[]>(
        (acc, { id, order }) => {
          const block = state.entity.contentBlocks.find(
            (findBlock) => findBlock.id === id,
          )

          if (block) {
            return [...acc, { ...block, order }]
          }

          return acc
        },
        [],
      )
    },
    wsUpdateBlockFocusedBy: (
      state,
      action: PayloadAction<Pick<BlockType, 'id' | 'cid' | 'focusedByUser'>>,
    ) => {
      if (!state.entity) {
        return
      }

      const blockIndex = state.entity.contentBlocks.findIndex(
        (block) => block.id === action.payload.id,
      )

      if (blockIndex > -1) {
        state.entity.contentBlocks[blockIndex].focusedByUser =
          action.payload.focusedByUser
      }
    },
    wsUpdateReusableContentBlocks: (
      state,
      action: PayloadAction<ReusableContentBlockOutput[]>,
    ) => {
      if (!state.entity) {
        return
      }

      const updatedReusableContentBlocks = action.payload

      updatedReusableContentBlocks.forEach((reusableContentBlock) => {
        const { contextContentBlocks, draftReusableContentBlock } =
          reusableContentBlock

        if (!contextContentBlocks) {
          return
        }

        let updatedContentBlocks = [...state.entity.contentBlocks]

        const currentNonRenderableContentBlocksLength =
          state.entity.contentBlocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

        contextContentBlocks.forEach((reusableContentBlock, index) => {
          const existingBlockIndex = state.entity.contentBlocks.findIndex(
            (block) => block.cid === reusableContentBlock.cid,
          )

          // make as reusable/update/merge/delete draft
          if (existingBlockIndex > -1) {
            updatedContentBlocks[existingBlockIndex] = {
              ...contextContentBlocks[index],
              reusableContentBlockDraft: draftReusableContentBlock,
            }
            return
          }

          if (reusableContentBlock.isRenderable) {
            updatedContentBlocks = updatedContentBlocks.toSpliced(
              reusableContentBlock.order! +
                currentNonRenderableContentBlocksLength,
              0,
              {
                ...reusableContentBlock,
                reusableContentBlockDraft: draftReusableContentBlock,
              },
            )
          } else {
            updatedContentBlocks = updatedContentBlocks.toSpliced(
              currentNonRenderableContentBlocksLength,
              0,
              {
                ...reusableContentBlock,
                reusableContentBlockDraft: draftReusableContentBlock,
              },
            )
          }
        })

        const nextNonRenderableContentBlocksLength =
          updatedContentBlocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

        updatedContentBlocks = updatedContentBlocks.toSorted(
          (a, b) => a.order - b.order,
        )

        updatedContentBlocks.forEach((block, idx) => {
          if (block.isRenderable) {
            block.order = idx - currentNonRenderableContentBlocksLength
          }
        })

        state.entity.contentBlocks = updatedContentBlocks
      })
    },
    wsUpdateContent: (state, action: PayloadAction<ContentType>) => {
      if (!state.entity) {
        return
      }
      const { data, references, preferences } = action.payload
      state.entity.data = data
      state.entity.references = references
      state.entity.preferences = preferences
    },
    setAIPrompt: (state, action: PayloadAction<string>) => {
      if (!state.entity) {
        return
      }
      state.entity.preferences = {
        ...state.entity.preferences,
        AIPrompt: action.payload,
      }
    },
    clearBlocks: (state) => {
      if (!state.entity) {
        return
      }
      state.entity.contentBlocks = []
    },
    wsReplaceBlocks: (state, action: PayloadAction<BlockType[]>) => {
      if (!state.entity) {
        return
      }

      state.entity.contentBlocks = action.payload
    },
    setSwapBlockData: (
      state,
      action: PayloadAction<{
        id: string
        data: Record<string, any>
        type: string
      }>,
    ) => {
      if (!state.entity) {
        return
      }

      const { id, data, type } = action.payload

      const blockIndex = state.entity.contentBlocks.findIndex(
        (block) => block.id === id,
      )

      if (blockIndex > -1) {
        state.entity.contentBlocks[blockIndex].swapBlockData = {
          data,
          type,
        }
      }
    },
    resetSwapBlockData: (state, action: PayloadAction<string>) => {
      if (!state.entity) {
        return
      }

      const id = action.payload

      const blockIndex = state.entity.contentBlocks.findIndex(
        (block) => block.id === id,
      )

      if (blockIndex > -1) {
        delete state.entity.contentBlocks[blockIndex].swapBlockData
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase('USER:LOGOUT', () => {
        return initialState
      })
      .addCase(pageRegenerateUndoRedo.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const { contentBlocks, content } = action.payload

          if (content) {
            const { data, references, preferences, updatedAt } = content

            state.entity.data = data
            state.entity.references = references
            state.entity.preferences = preferences
            state.entity.updatedAt = updatedAt
          }

          if (contentBlocks) {
            const { restoredContentBlocks } = contentBlocks
            state.entity.contentBlocks = restoredContentBlocks
          }
        }
      })
      .addCase(pageMultiUpdate.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const { contentBlocks, content } = action.payload

          if (content) {
            const { data, references, preferences, updatedAt } = content

            state.entity.data = data
            state.entity.references = references
            state.entity.preferences = preferences
            state.entity.updatedAt = updatedAt
          }

          if (contentBlocks) {
            contentBlocks.forEach((contentBlock) => {
              const blockIndex = state.entity.contentBlocks.findIndex(
                (block) => block.id === contentBlock.id,
              )

              if (blockIndex > -1) {
                state.entity.contentBlocks[blockIndex] = contentBlock
              }
            })
          }
        }
      })
      .addCase(fetchContent.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchContent.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // We are overriding payload data type because we are sure `payload.data` will be provided,
        // otherwise it would be rejected in fetchContent thunk
        const payload = action.payload as ContentType
        state.entity = {
          ...payload,
          contentBlocks: payload.contentBlocks,
        } as ContentType
      })
      .addCase(fetchContent.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload?.message || null
      })
      .addCase(
        setContentPreferencesUserHasInteracted.fulfilled,
        (state, action) => {
          if (state.status === 'succeeded' && action?.payload) {
            const { preferences } = action.payload
            state.entity.preferences = preferences
          }
        },
      )
      .addCase(deleteWhiteboardContentEntities.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const queryVariables = action.meta.arg.queryVariables
          const { deleteInput } = queryVariables

          const whiteboardContentContentBlocksIdsToDelete = deleteInput.reduce<
            string[]
          >((acc, { entity, id }) => {
            if (entity === 'CONTENTBLOCK') {
              acc.push(id)
            }
            return acc
          }, [])

          state.entity.contentBlocks = state.entity.contentBlocks.filter(
            ({ id }) => !whiteboardContentContentBlocksIdsToDelete.includes(id),
          )

          let updatedContentBlocks = [...state.entity.contentBlocks]
          const nextNonRenderableContentBlocksLength =
            state.entity.contentBlocks.filter(
              ({ isRenderable }) => !isRenderable,
            ).length

          updatedContentBlocks = updatedContentBlocks.toSorted(
            (a, b) => a.order - b.order,
          )
          // update order
          updatedContentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nextNonRenderableContentBlocksLength
            }
          })

          state.entity.contentBlocks = updatedContentBlocks
        }
      })
      .addCase(updateWhiteboardContentEntitiesMeta.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const updatedWhiteboardContentEntities =
            action.meta.arg.queryVariables.updateInput
          const updatedWhiteboardContentContentBlockEntities =
            updatedWhiteboardContentEntities.filter(
              ({ entity }) => entity === 'CONTENTBLOCK',
            )

          updatedWhiteboardContentContentBlockEntities.forEach(
            (contentBlock) => {
              const existingContentBlockIndex =
                state.entity.contentBlocks.findIndex(
                  (existingContentBlock) =>
                    existingContentBlock.id === contentBlock.id,
                )

              if (existingContentBlockIndex > -1) {
                const existingContentBlock =
                  state.entity.contentBlocks[existingContentBlockIndex]

                state.entity.contentBlocks[existingContentBlockIndex] = {
                  ...existingContentBlock,
                  meta: {
                    ...existingContentBlock.meta,
                    whiteboard: {
                      ...existingContentBlock.meta.whiteboard,
                      ...contentBlock.payload,
                    },
                  },
                }
              }
            },
          )
        }
      })
      .addCase(addBlocks.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const { position, contentBlocksData, contentPropertyName } =
            action.meta.arg.queryVariables

          const blocks = state.entity.contentBlocks

          const currentNonRenderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

          const renderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => isRenderable,
          ).length

          const order = position ?? renderableContentBlocksLength

          // We don't want to add pending block if there is no way to identify it in the future
          const contentBlocksToAdd = contentBlocksData
            .filter(({ cid }) => Boolean(cid))
            .map((blockData, index) => {
              const block = {
                ...blockData,
                id: blockData.cid!,
                contentPropertyName,
                customConfig: {},
                order: blockData.isRenderable ? order : -1,
              }
              return block
            })

          if (contentBlocksData.length === 0) {
            return
          }

          let updatedContentBlocks = [...state.entity.contentBlocks]

          if (typeof position === 'number') {
            updatedContentBlocks = updatedContentBlocks.toSpliced(
              position + currentNonRenderableContentBlocksLength,
              0,
              ...contentBlocksToAdd,
            )
          } else {
            updatedContentBlocks = updatedContentBlocks.toSpliced(
              currentNonRenderableContentBlocksLength,
              0,
              ...contentBlocksToAdd,
            )
          }

          const nextNonRenderableContentBlocksLength =
            updatedContentBlocks.filter(
              ({ isRenderable }) => !isRenderable,
            ).length

          updatedContentBlocks = updatedContentBlocks.toSorted(
            (a, b) => a.order - b.order,
          )

          updatedContentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nextNonRenderableContentBlocksLength
            }
          })

          state.entity.contentBlocks = updatedContentBlocks
        }
      })
      .addCase(addBlocks.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const newContentBlocks = action.payload

          newContentBlocks.forEach((contentBlock) => {
            const existingBlockIndex = state.entity.contentBlocks.findIndex(
              (block) => block.cid === contentBlock.cid,
            )

            if (existingBlockIndex > -1) {
              const existingBlock =
                state.entity.contentBlocks[existingBlockIndex]
              const isPending = existingBlock.cid === existingBlock.id
              if (isPending) {
                state.entity.contentBlocks[existingBlockIndex] = contentBlock
              }
            } else {
              console.error(
                `[content-slice]: addBlocks.fulfilled blocks not found`,
                action.payload,
              )
            }
          })
        }
      })
      .addCase(restoreContentBlocks.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const { restoredContentBlocks, renderableContentBlocks } =
            action.payload as RestoreContentBlockOutput

          const contentBlocksByRenderableType = restoredContentBlocks.reduce(
            (acc, val) => {
              if (val.isRenderable) {
                acc.renderableContentBlocks.push(val)
              } else {
                acc.nonRenderableContentBlocks.push(val)
              }
              return acc
            },
            {
              renderableContentBlocks: [],
              nonRenderableContentBlocks: [],
            } as {
              renderableContentBlocks: BlockType[]
              nonRenderableContentBlocks: BlockType[]
            },
          )

          let updatedContentBlocks = [
            ...contentBlocksByRenderableType.nonRenderableContentBlocks,
            ...state.entity.contentBlocks,
            ...contentBlocksByRenderableType.renderableContentBlocks,
          ]

          const renderableContentBlocksCidsOrder = renderableContentBlocks.map(
            (block) => block.cid,
          )

          updatedContentBlocks = updatedContentBlocks.map((block) => {
            if (block.isRenderable) {
              const order = renderableContentBlocksCidsOrder.findIndex(
                (cid) => cid === block.cid,
              )
              return {
                ...block,
                order,
              }
            }
            return block
          })

          updatedContentBlocks = updatedContentBlocks.toSorted(
            (a, b) => a.order! - b.order!,
          )

          state.entity.contentBlocks = updatedContentBlocks
        }
      })
      .addCase(duplicateContentBlock.pending, (state, action) => {
        if (state.status === 'succeeded') {
          const {
            cid,
            position,
            id: requestedContentBlockId,
          } = action.meta.arg.queryVariables

          const blocks = state.entity.contentBlocks

          const currentNonRenderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

          const isPositionDefined = typeof position === 'number'

          // We don't want to add pending block if there is no way to identify it in the future
          if (!cid) {
            return
          }

          const requestedContentBlock = state.entity.contentBlocks.find(
            ({ id }) => id === requestedContentBlockId,
          )

          let updatedContentBlocks = [...state.entity.contentBlocks]

          if (requestedContentBlock) {
            const blockToDuplicate = {
              ...requestedContentBlock,
              order: isPositionDefined ? position : -1,
              id: cid,
              cid,
              customConfig: {},
            }
            if (requestedContentBlock?.isRenderable && isPositionDefined) {
              updatedContentBlocks = updatedContentBlocks.toSpliced(
                position + currentNonRenderableContentBlocksLength,
                0,
                blockToDuplicate,
              )
            } else {
              updatedContentBlocks = updatedContentBlocks.toSpliced(
                currentNonRenderableContentBlocksLength,
                0,
                blockToDuplicate,
              )
            }
          }

          const nextNonRenderableContentBlocksLength =
            updatedContentBlocks.filter(
              ({ isRenderable }) => !isRenderable,
            ).length

          updatedContentBlocks = updatedContentBlocks.toSorted(
            (a, b) => a.order - b.order,
          )

          updatedContentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nextNonRenderableContentBlocksLength
            }
          })

          state.entity.contentBlocks = updatedContentBlocks
        }
      })
      .addCase(duplicateContentBlock.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const { cid } = action.payload

          const existingBlockIndex = state.entity.contentBlocks.findIndex(
            (block) => block.cid === cid,
          )
          const duplicatedBlock = action.payload
          const blocks = state.entity.contentBlocks

          if (duplicatedBlock) {
            if (existingBlockIndex > -1) {
              const existingBlock =
                state.entity.contentBlocks[existingBlockIndex]
              const isPending = existingBlock.cid === existingBlock.id
              if (isPending) {
                state.entity.contentBlocks[existingBlockIndex] = duplicatedBlock
              }
            } else {
              console.error(
                `[content-slice]: duplicate.fulfilled block not found`,
                action.payload,
              )
            }
          }
        }
      })
      .addCase(deleteReusableContentBlockDraft.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const { contextContentBlocks } = action.payload

          contextContentBlocks
            ?.map(({ id }) => id)
            .forEach((blockId) => {
              const blockIndex = state.entity.contentBlocks.findIndex(
                (block) => block.id === blockId,
              )

              if (blockIndex > -1) {
                state.entity.contentBlocks[
                  blockIndex
                ].reusableContentBlockDraft = null
              }
            })
        }
      })
      .addCase(mergeReusableContentBlockDraft.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const {
            contextContentBlocks,
            issuedReusableContentBlock: {
              id: issuedReusableContentBlockId,
              ...restIssuedReusableContentBlock
            } = {},
          } = action.payload

          contextContentBlocks
            ?.map(({ id }) => id)
            .forEach((blockId) => {
              const blockIndex = state.entity.contentBlocks.findIndex(
                (block) => block.id === blockId,
              )

              state.entity.contentBlocks[blockIndex] = {
                ...state.entity.contentBlocks[blockIndex],
                ...restIssuedReusableContentBlock,
                reusableContentBlockDraft: null,
              }
            })
        }
      })
      .addCase(assignReusableContentBlock.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const blocks = state.entity.contentBlocks
          const assignedReusableContentBlock = action.payload

          const currentNonRenderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

          let updatedContentBlocks = [...state.entity.contentBlocks]

          if (assignedReusableContentBlock) {
            if (assignedReusableContentBlock.isRenderable) {
              updatedContentBlocks = updatedContentBlocks.toSpliced(
                assignedReusableContentBlock.order! +
                  currentNonRenderableContentBlocksLength,
                0,
                assignedReusableContentBlock,
              )
            } else {
              updatedContentBlocks = [
                assignedReusableContentBlock,
                ...updatedContentBlocks,
              ]
            }
          }

          // if we have pending block replace it, otherwise add as new
          const nextNonRenderableContentBlocksLength =
            updatedContentBlocks.filter(
              ({ isRenderable }) => !isRenderable,
            ).length

          updatedContentBlocks = updatedContentBlocks.toSorted(
            (a, b) => a.order - b.order,
          )

          updatedContentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nextNonRenderableContentBlocksLength
            }
          })

          state.entity.contentBlocks = updatedContentBlocks
        }
      })
      .addCase(assignReusableContentBlocks.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const blocks = state.entity.contentBlocks
          const assignedReusableContentBlocks = action.payload

          const currentNonRenderableContentBlocksLength = blocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

          let updatedContentBlocks = [...state.entity.contentBlocks]

          if (!assignedReusableContentBlocks) {
            return
          }

          assignedReusableContentBlocks.forEach(
            (assignedReusableContentBlock) => {
              if (assignedReusableContentBlock) {
                if (assignedReusableContentBlock.isRenderable) {
                  updatedContentBlocks = updatedContentBlocks.toSpliced(
                    assignedReusableContentBlock.order! +
                      currentNonRenderableContentBlocksLength,
                    0,
                    assignedReusableContentBlock,
                  )
                } else {
                  updatedContentBlocks = [
                    assignedReusableContentBlock,
                    ...updatedContentBlocks,
                  ]
                }
              }
            },
          )

          // if we have pending block replace it, otherwise add as new
          const nextNonRenderableContentBlocksLength =
            updatedContentBlocks.filter(
              ({ isRenderable }) => !isRenderable,
            ).length

          updatedContentBlocks = updatedContentBlocks.toSorted(
            (a, b) => a.order - b.order,
          )

          updatedContentBlocks.forEach((block, idx) => {
            if (block.isRenderable) {
              block.order = idx - nextNonRenderableContentBlocksLength
            }
          })

          state.entity.contentBlocks = updatedContentBlocks
        }
      })
      .addCase(moveBlockToIndex.pending, (state, action) => {
        const blocks = state.entity?.contentBlocks
        const { blockId, toIndex, blockCid } = action.meta.arg.queryVariables

        if (!blocks) {
          return
        }

        let id = blockId

        if (state.status !== 'succeeded') {
          return
        }

        if (blockCid) {
          const block = state.entity.contentBlocks.find(
            ({ cid }) => cid === blockCid,
          )
          if (block) {
            id = block.id
          }
        }
        const currentNonRenderableContentBlocksLength = blocks.filter(
          ({ isRenderable }) => !isRenderable,
        ).length

        // Find current index of block
        const fromIndex = blocks.findIndex((block) => block.id === id)

        let updatedContentBlocks = [...state.entity.contentBlocks]

        // Move block from current index to target index
        if (fromIndex !== -1) {
          // remove block from the blocks
          const movedBlock = updatedContentBlocks[fromIndex]
          updatedContentBlocks = updatedContentBlocks.toSpliced(fromIndex, 1)
          // When moving forward (fromIndex < toIndex), we need to adjust the target index
          // since removing the item shifts all subsequent indices down by 1
          const position = toIndex + currentNonRenderableContentBlocksLength
          const adjustedToIndex = fromIndex < position ? toIndex - 1 : toIndex

          updatedContentBlocks = updatedContentBlocks.toSpliced(position, 0, {
            ...movedBlock,
            order: adjustedToIndex,
          })
        }

        const nextNonRenderableContentBlocksLength =
          updatedContentBlocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

        updatedContentBlocks = updatedContentBlocks.toSorted(
          (a, b) => a.order - b.order,
        )

        updatedContentBlocks.forEach((block, idx) => {
          if (block.isRenderable) {
            block.order = idx - nextNonRenderableContentBlocksLength
          }
        })

        state.entity.contentBlocks = updatedContentBlocks
      })
      .addCase(moveBlockToIndex.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        state.entity.contentBlocks = action.payload.contentBlocks.map(
          (newBlockData) => {
            const movedBlock = state.entity?.contentBlocks.find(
              (oldBlock) => oldBlock.id === newBlockData.id,
            )

            if (movedBlock) {
              return { ...movedBlock, ...newBlockData }
            }
          },
        )
      })
      .addCase(updateContentBlocks.pending, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        state.requests.updateBlocksRequestId = action.meta.requestId

        const updatedContentBlocks = action.meta.arg.queryVariables.updateInput

        updatedContentBlocks.forEach((contentBlock) => {
          const existingContentBlockIndex =
            state.entity?.contentBlocks.findIndex(
              (existingContentBlock) =>
                existingContentBlock.id === contentBlock.id,
            )

          if (existingContentBlockIndex > -1) {
            const existingContentBlock =
              state.entity.contentBlocks[existingContentBlockIndex]

            state.entity.contentBlocks[existingContentBlockIndex] = {
              ...existingContentBlock,
              ...contentBlock,
            }
          }
        })
      })
      .addCase(updateContentBlocks.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        if (action.meta.requestId !== state.requests.updateBlocksRequestId) {
          return
        }

        const updatedBlocks = action.payload || []

        updatedBlocks.forEach((contentBlock) => {
          const blockIndex = state.entity.contentBlocks.findIndex(
            (block) => block.id === contentBlock.id,
          )

          if (blockIndex > -1) {
            state.entity.contentBlocks[blockIndex] = contentBlock
          }
        })
      })
      .addCase(updateReusableContentBlocks.pending, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        state.requests.updateReusableBlocksRequestId = action.meta.requestId
      })
      .addCase(updateReusableContentBlocks.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        if (
          action.meta.requestId !== state.requests.updateReusableBlocksRequestId
        ) {
          return
        }

        const updatedReusableContentBlocks = action.payload || []

        updatedReusableContentBlocks.forEach((reusableContentBlock) => {
          const { contextContentBlocks, draftReusableContentBlock } =
            reusableContentBlock

          contextContentBlocks
            ?.map(({ id }) => id)
            .forEach((blockId) => {
              const blockIndex = state.entity.contentBlocks.findIndex(
                (block) => block.id === blockId,
              )

              if (blockIndex > -1) {
                const contentBlock = state.entity.contentBlocks[blockIndex]
                state.entity.contentBlocks[blockIndex] = {
                  ...contentBlock,
                  reusableContentBlockDraft: draftReusableContentBlock,
                }
              }
            })
        })
      })
      .addCase(updateBlock.pending, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        state.requests.updateBlockRequestId = action.meta.requestId

        const { queryVariables, thunkOptions } = action.meta.arg

        const {
          blockCid,
          blockId,
          type,
          configPath,
          configValue,
          customConfigValue,
          referencesToReplace,
        } = queryVariables
        const blockIndex = state.entity.contentBlocks.findIndex(
          (block) => block.cid === blockCid || block.id === blockId,
        )
        let block = state.entity.contentBlocks[blockIndex]
        const isTypeChange = type && type !== block.type

        let blockConfig = block.config || {}

        if (isTypeChange) {
          blockConfig = configValue
        } else if (configValue !== undefined && configPath) {
          if (thunkOptions?.shouldReplaceValue) {
            blockConfig = assocJMESPath(configPath, configValue, block)
          } else {
            blockConfig = flattenUpdate(block.config, configValue, configPath)
          }

          if (thunkOptions?.shouldRemoveEmptyValues) {
            blockConfig = assocJMESPath(
              configPath,
              removeEmptyValues(search(blockConfig, configPath)),
              blockConfig,
            )
          }
        }

        const cleanedReferences = referencesCleanup({
          references: referencesToReplace || block.references,
          data: { config: blockConfig },
        })

        const blockCustomConfig = customConfigValue || block.customConfig || {}

        const { isValid } = validateContentBlock({
          ...block,
          type: type || block.type,
          config: blockConfig,
        })

        if (!isValid) {
          return
        }

        // TODO @tom references

        state.entity.contentBlocks[blockIndex] = {
          ...block,
          config: blockConfig,
          customConfig: blockCustomConfig,
          references: cleanedReferences,
          type: type || block.type,
        }
      })
      .addCase(updateBlock.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        if (action.meta.requestId !== state.requests.updateBlockRequestId) {
          return
        }

        const { blockCid, blockId } = action.meta.arg.queryVariables

        const blockIndex = state.entity.contentBlocks.findIndex(
          (block) => block.cid === blockCid || block.id === blockId,
        )

        if (blockIndex > -1) {
          state.entity.contentBlocks[blockIndex] = action.payload
        }
      })
      .addCase(updateReusableContentBlock.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        const updatedReusableContentBlocks = action.payload

        updatedReusableContentBlocks.forEach(
          ({ contextContentBlocks, draftReusableContentBlock }) => {
            contextContentBlocks
              ?.map(({ id }) => id)
              .forEach((blockId) => {
                const blockIndex = state.entity.contentBlocks.findIndex(
                  (block) => block.id === blockId,
                )

                if (blockIndex > -1) {
                  const contentBlock = state.entity.contentBlocks[blockIndex]
                  state.entity.contentBlocks[blockIndex] = {
                    ...contentBlock,
                    reusableContentBlockDraft: draftReusableContentBlock,
                  }
                }
              })
          },
        )
      })
      .addCase(makeContentBlockReusable.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        const { id, ...restActionPayload } = action.payload

        const blockIndex = state.entity.contentBlocks.findIndex(
          (block) => block.id === id,
        )

        state.entity.contentBlocks[blockIndex] = {
          ...state.entity.contentBlocks[blockIndex],
          ...restActionPayload,
        }
      })
      .addCase(setContentBlocksAsRenderable.pending, (state, action) => {
        if (!state.entity) {
          return
        }

        const blocks = state.entity.contentBlocks

        const currentNonRenderableContentBlocksLength = blocks?.filter(
          ({ isRenderable }) => !isRenderable,
        ).length

        const { queryVariables } = action.meta.arg
        const { setRenderableInput, position } = queryVariables

        const setRenderableInputLength = setRenderableInput.length

        const insertPosition =
          position +
          currentNonRenderableContentBlocksLength -
          setRenderableInputLength

        let updatedContentBlocks = [...state.entity.contentBlocks]
        // Get blocks to move and remove them from original positions
        const blockToSetAsRenderable = setRenderableInput.map(({ id }) => {
          const blockIndex = updatedContentBlocks.findIndex(
            (block) => block.id === id,
          )
          const block = updatedContentBlocks[blockIndex]
          updatedContentBlocks = updatedContentBlocks.toSpliced(blockIndex, 1)
          return { ...block, isRenderable: true, order: position }
        })

        updatedContentBlocks = updatedContentBlocks.toSpliced(
          insertPosition,
          0,
          ...blockToSetAsRenderable,
        )

        const nextNonRenderableContentBlocksLength =
          updatedContentBlocks?.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

        updatedContentBlocks = updatedContentBlocks.toSorted(
          (a, b) => a.order - b.order,
        )

        updatedContentBlocks.forEach((block, idx) => {
          // ignore non renderable blocks with order -1
          if (block.isRenderable) {
            block.order = idx - nextNonRenderableContentBlocksLength
          }
        })

        state.entity.contentBlocks = updatedContentBlocks
      })
      .addCase(setContentBlocksAsRenderable.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        const blocks = state.entity.contentBlocks

        const newRenderableContentBlocks = action.payload

        let updatedContentBlocks = [...state.entity.contentBlocks]

        if (newRenderableContentBlocks) {
          newRenderableContentBlocks.forEach((renderableContentBlock) => {
            const blockIndex = updatedContentBlocks.findIndex(
              (block) => block.id === renderableContentBlock.id,
            )
            const block = blocks[blockIndex]

            blocks[blockIndex] = {
              ...block,
              ...renderableContentBlock,
            }
          })
        }
      })
      .addCase(setContentBlocksAsNonRenderable.pending, (state, action) => {
        if (!state.entity) {
          return
        }

        const blocks = state.entity.contentBlocks

        const { queryVariables } = action.meta.arg
        const { setNonRenderableInput: blocksToSetAsNonRenderable } =
          queryVariables

        let updatedContentBlocks = [...state.entity.contentBlocks]

        blocksToSetAsNonRenderable.forEach(({ id, meta }) => {
          const blockIndex = updatedContentBlocks.findIndex(
            (block) => block.id === id,
          )
          const block = updatedContentBlocks[blockIndex]

          block.isRenderable = false
          block.order = -1
          block.meta = meta
        })

        const nextNonRenderableContentBlocksLength =
          updatedContentBlocks.filter(
            ({ isRenderable }) => !isRenderable,
          ).length

        updatedContentBlocks = updatedContentBlocks.toSorted(
          (a, b) => a.order - b.order,
        )

        updatedContentBlocks.forEach((block, idx) => {
          if (block.isRenderable) {
            block.order = idx - nextNonRenderableContentBlocksLength
          }
        })

        state.entity.contentBlocks = updatedContentBlocks
      })
      .addCase(setContentBlocksAsNonRenderable.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        const blocks = state.entity.contentBlocks

        const newNonRenderableContentBlocks = action.payload

        newNonRenderableContentBlocks.forEach((renderableContentBlock) => {
          const blockIndex = blocks.findIndex(
            (block) => block.id === renderableContentBlock.id,
          )
          const block = blocks[blockIndex]

          blocks[blockIndex] = {
            ...block,
            ...renderableContentBlock,
          }
        })
      })
      .addCase(updateContentPath.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(updateContentPath.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(updateContentPath.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(updateContent.pending, (state, action) => {
        if (!state.entity) {
          return
        }
        //prevent old requests overwriting the current one
        state.requests.updateContentRequestId = action.meta.requestId

        const { queryVariables } = action.meta.arg
        const { id, references } = queryVariables
        const data = queryVariables?.data || state.entity?.data

        state.entity = {
          ...state.entity,
          data,
          id,
          references,
        }
      })
      .addCase(updateContent.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        if (action.meta.requestId !== state.requests.updateContentRequestId) {
          return
        }
        const { data, references, preferences } = action.payload

        state.entity.data = data
        state.entity.references = references
        state.entity.preferences = preferences
      })
      .addCase(updateContentData.pending, (state, action) => {
        if (!state.entity) {
          return
        }
        //prevent old requests overwriting the current one
        state.requests.updateContentDataRequestId = action.meta.requestId

        const { queryVariables, thunkOptions } = action.meta.arg
        const { configPath, configValue } = queryVariables
        let updatedData = state.entity.data

        if (thunkOptions?.shouldReplaceValue) {
          updatedData = assocJMESPath(
            configPath,
            configValue,
            updatedData,
          ) as ContentDataType
        } else {
          updatedData = mergeDeepRight({ config: configValue }, updatedData)
        }

        const { isValid } = validateContent({ data: updatedData })

        if (!isValid) {
          return
        }

        state.entity.data = updatedData
      })
      .addCase(updateContentData.fulfilled, (state, action) => {
        if (!state.entity) {
          return
        }

        //prevent old requests overwriting the current one
        if (
          action.meta.requestId !== state.requests.updateContentDataRequestId
        ) {
          return
        }
        const { data, references, preferences, updatedAt } = action.payload

        state.entity.data = data
        state.entity.references = references
        state.entity.preferences = preferences
        state.entity.updatedAt = updatedAt
      })
      .addCase(requestContentBlocksByAiV2.pending, (state) => {
        if (!state.entity) return
        state.entity.contentBlocks = []
      })
      .addCase(requestContentBlocksByAiV2.fulfilled, (state) => {
        if (!state.entity) return
        if (state.entity.preferences) {
          state.entity.preferences.isAIGeneratedContent = true
        } else {
          state.entity = {
            ...state.entity,
            preferences: {
              isAIGeneratedContent: true,
            },
          }
        }
      })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useContentDispatch: () => AppDispatch = useDispatch
export const useContentSelector: TypedUseSelectorHook<RootState> = useSelector

// Action creators are generated for each case reducer function
export const {
  resetContentState,
  wsAddContentBlocks,
  wsUpdateContentBlocks,
  wsDeleteContentBlocks,
  wsSortContentBlock,
  wsRestoreContentBlocks,
  wsUpdateWhiteboardContentContentBlocksEntity,
  wsUpdateReusableContentBlocks,
  wsUpdateBlockFocusedBy,
  wsUpdateContent,
  wsReplaceBlocks,
  setAIPrompt,
  clearBlocks,
  setSwapBlockData,
  resetSwapBlockData,
} = contentSlice.actions

export default contentSlice.reducer
