import '@sceneio/config-tailwind/styles.css'
import { LoadingBarLogo } from './LoadingBarLogo'

export function LoadingPage() {
  return (
    <div className="tw-flex tw-h-screen tw-relative">
      <div className="tw-w-[300px] tw-border-r tw-border-border-color"></div>
      <div className="tw-flex tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0">
        <LoadingBarLogo className="tw-flex-1" />
      </div>
    </div>
  )
}
