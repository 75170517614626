import React, { SVGProps } from 'react'

const SpacingLooseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.1641 16L14.1641 13.5L3.16406 13.5L3.16406 16"
        stroke="black"
      />
      <path
        d="M14.1641 1.00195L14.1641 3.50195L3.16406 3.50195L3.16406 1.00195"
        stroke="black"
      />
      <path d="M13.6641 8.49805L4.66406 8.49805" stroke="black" />
    </svg>
  )
}

export default SpacingLooseIcon
