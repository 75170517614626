import { fontsConfig } from '@sceneio/config-fonts'
import type { FontsMap, FontType } from '@sceneio/config-fonts/lib/fonts'
import { flatten } from 'flat'

const inlineFontFamilyStyleRegex = new RegExp(/font-family:\s*([^;]+)/g)

export const getUsedFonts = (
  data: Record<string, any> = {},
  customFonts?: FontsMap,
) => {
  const flattenData = flatten(data) as Record<string, any>

  const fontFamilies = Object.entries(flattenData).reduce(
    (acc, [path, value]) => {
      const isFontFamily = path.includes('family')

      if (isFontFamily) {
        const fontFamily = value
        const fontFamilyAlreadyExits = acc.includes(fontFamily)
        if (!fontFamilyAlreadyExits) {
          acc.push(value)
        }
      }

      // check for inline styles provided by wysiwyg
      if (path.endsWith('children') && typeof value === 'string') {
        if (inlineFontFamilyStyleRegex.test(value)) {
          const fontFamilyStyles = value.match(inlineFontFamilyStyleRegex) || []
          fontFamilyStyles.forEach((fontFamilyStyle: string) => {
            const fontFamily = fontFamilyStyle.replace(/font-family:\s*/, '')
            const fontFamilyAlreadyExits = acc.includes(fontFamily)
            if (!fontFamilyAlreadyExits) {
              acc.push(fontFamily)
            }
          })
        }
      }
      return acc
    },
    [] as string[],
  )

  const fonts = Object.entries({
    ...fontsConfig,
    ...(customFonts || {}),
  }).reduce((acc, [_, font]) => {
    if (fontFamilies.includes(font?.fontFamily)) {
      acc.push(font)
    }

    return acc
  }, [] as FontType[])

  return fonts
}
