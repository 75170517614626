import React, { SVGProps } from 'react'

const RadiusRoundIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="1.82812" y="4" width="13" height="9" rx="4.5" stroke="#252523" />
  </svg>
)

export default RadiusRoundIcon
