import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from './../../store'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  WorkspaceSliceType,
  WorkspaceType,
  WorkspaceUserRoleId,
} from './workspaceSliceTypes'
import {
  workspaceAssignEditors,
  workspaceAssignViewers,
  workspaceAvatarRemove,
  workspaceAvatarUpload,
  workspaceLeave,
  workspacesFetch,
  workspaceUnassignUsers,
  workspaceUpdate,
} from './workspaceSliceThunks'
import { workspaceAssignUsersReducer } from './workspaceSliceReducers'
import { GqlResultDataByDocumentName } from '@sceneio/graphql-queries/dist/client/types'

// ---------------
// Initial State
// ---------------
export const initialState: WorkspaceSliceType = {
  entities: [],
  status: 'idle',
  error: null,
  selectedWorkspaceId: null,
  myWorkspaceId: null,
} as WorkspaceSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Reducer
// ---------------

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setSelectedWorkspaceId: (
      state,
      action: PayloadAction<WorkspaceType['id'] | null>,
    ) => {
      state.selectedWorkspaceId = action.payload
    },
    setMyWorkspaceId: (
      state,
      action: PayloadAction<WorkspaceType['id'] | null>,
    ) => {
      state.myWorkspaceId = action.payload
    },
    wsUpdateWorkspace: (
      state,
      action: PayloadAction<
        NonNullable<
          GqlResultDataByDocumentName<'WorkspaceUpdatedSubscriptionDocument'>
        >['workspaceUpdated']['data'] & {
          aclRoleId?: WorkspaceUserRoleId
        }
      >,
    ) => {
      const workspace = action.payload

      if (!workspace) {
        return
      }

      const workspaceIndex = state.entities.findIndex(
        (w) => w.id === workspace.id,
      )

      if (workspaceIndex === -1) {
        return
      }

      state.entities[workspaceIndex] = {
        ...state.entities[workspaceIndex],
        ...(workspace.aclRoleId && { aclRoleId: workspace.aclRoleId }),
        id: workspace.id,
        name: workspace.name,
        avatar: workspace.avatar?.presentationUrl,
        assignedUsers: workspace.assignedUsers.map((entity) => ({
          ...entity.user,
          roleId: entity.aclRoleId as WorkspaceUserRoleId,
        })),
      }
    },
  },
  extraReducers(builder) {
    builder.addCase('USER:LOGOUT', () => {
      return initialState
    })
    builder.addCase(workspacesFetch.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(workspacesFetch.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const workspaces: WorkspaceType[] = (action.payload ?? []).reduce<
        WorkspaceType[]
      >((acc, item) => {
        const w = item?.workspace

        if (!w) {
          return acc
        }

        return [
          ...acc,
          {
            ...item?.workspace,
            owner: w.assignedUsers.find((user) => user.aclRoleId === 'id:owner')
              ?.user,
            aclRoleId: item.aclRoleId as WorkspaceUserRoleId,
            avatar: w.avatar?.presentationUrl,
            assignedUsers: w.assignedUsers.map((entity) => ({
              ...entity.user,
              roleId: entity.aclRoleId as WorkspaceUserRoleId,
            })),
          },
        ]
      }, [])

      state.entities = workspaces
    })
    builder.addCase(workspaceUpdate.fulfilled, (state, action) => {
      const workspace = action.payload
      if (!workspace) {
        return
      }

      const workspaceIndex = state.entities.findIndex(
        (w) => w.id === workspace.id,
      )
      if (workspaceIndex === -1) {
        return
      }
      state.entities[workspaceIndex] = {
        ...state.entities[workspaceIndex],
        ...workspace,
      }
    })
    builder.addCase(workspaceAvatarUpload.fulfilled, (state, action) => {
      const avatarUrl = action.payload?.presentationUrl
      const workspaceId = action.payload?.workspaceId
      if (!avatarUrl) {
        return
      }

      const workspaceIndex = state.entities.findIndex(
        (w) => w.id === workspaceId,
      )
      if (workspaceIndex === -1) {
        return
      }
      state.entities[workspaceIndex] = {
        ...state.entities[workspaceIndex],
        avatar: avatarUrl,
      }
    })
    builder.addCase(workspaceAvatarRemove.fulfilled, (state, action) => {
      const workspaceId = action.payload?.workspaceId

      const workspaceIndex = state.entities.findIndex(
        (w) => w.id === workspaceId,
      )
      if (workspaceIndex === -1) {
        return
      }
      state.entities[workspaceIndex] = {
        ...state.entities[workspaceIndex],
        avatar: undefined,
      }
    })
    builder.addCase(workspaceLeave.fulfilled, (state, action) => {
      const workspaceId = action.meta?.arg?.queryVariables?.workspaceId
      if (!workspaceId) {
        return
      }
      state.entities = state.entities.filter((w) => w.id !== workspaceId)
    })

    builder.addCase(
      workspaceAssignEditors.fulfilled,
      workspaceAssignUsersReducer,
    )
    builder.addCase(
      workspaceAssignViewers.fulfilled,
      workspaceAssignUsersReducer,
    )
    builder.addCase(workspaceUnassignUsers.fulfilled, (state, action) => {
      const unassignedUsers = action.payload
      const workspace = state.entities.find(
        (workspace) => workspace.id === state.selectedWorkspaceId,
      )
      if (!workspace || !unassignedUsers) {
        return
      }

      const normalizedUsers = unassignedUsers
        ?.filter((user) => user !== null) // filter out any undefined user ids
        ?.map((user) => user?.userId) // map so we can use includes

      const filteredUsers = workspace.assignedUsers.filter(
        (user) => !normalizedUsers.includes(user.id),
      )

      workspace.assignedUsers = filteredUsers
    })
  },
})
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useWorkspaceDispatch: () => AppDispatch = useDispatch
export const useWorkspaceSelector: TypedUseSelectorHook<RootState> = useSelector

export const { setSelectedWorkspaceId, setMyWorkspaceId, wsUpdateWorkspace } =
  workspaceSlice.actions

export default workspaceSlice.reducer
