import { GqlResultDataByDocumentName } from '@sceneio/graphql-queries/dist/client/types'
import { ThunkApiType } from '../../helpers/asyncThunkFetcher'
import {
  UpdateContentBlocksQueryVarsType,
  UpdateContentQueryVarsType,
} from './contentSliceTypes'
import { materializeSnippets } from '@sceneio/snippets-tools'
import { validateContent, validateContentBlocks } from '@sceneio/schemas'
import { errorNotification } from '@sceneio/cms-notifications'
import { thunkReject } from '../../helpers/thunkReject'
import { referencesCleanup } from '@sceneio/referencing-tools'
import { materializeContentBlock } from './helpers'
import { ContentBlockUpdateDataInput } from '@sceneio/graphql-queries/dist/generated/graphqlTypes'

export function preprocessContentUpdateVariables(
  queryVariables: UpdateContentQueryVarsType['queryVariables'],
  thunkAPI: ThunkApiType<
    GqlResultDataByDocumentName<'UpdateContentDataDocument'>
  >,
) {
  const snippets = thunkAPI.getState().snippets.entities
  const content = thunkAPI.getState().content.entity
  const currentContentData = content?.data

  const { data, references } = queryVariables

  // content's data is optional during update, we need to used current content's data if not provided
  const contentData = data || currentContentData

  // safety check if provided references are materialized correctly
  const materializedContent = materializeSnippets({
    data: {
      data: contentData,
      references,
    },
    snippets,
  })

  const { isValid, error } = validateContent({
    data: materializedContent.data,
  })

  if (!isValid) {
    errorNotification({
      content: 'Invalid content data',
      log: {
        message: '[updateContent]: Content data validation failed',
        data: {
          dataToValidate: materializedContent.data,
          validationError: error,
          thunkQueryVariables: queryVariables,
        },
      },
    })

    return thunkReject({
      code: 'invalid_data',
      message: 'Invalid content data',
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  }

  const updatedReferences = referencesCleanup({
    references: queryVariables.references,
    data: { data: contentData },
  })

  return {
    ...queryVariables,
    data: contentData,
    references: updatedReferences,
  }
}

export function preprocessContentBlocksUpdateVariables(
  queryVariables: UpdateContentBlocksQueryVarsType['queryVariables'],
  thunkAPI: ThunkApiType<
    GqlResultDataByDocumentName<'ContentBlocksUpdateMutationDocument'>
  >,
) {
  const { updateInput, preventSetContentUserHasInteracted } = queryVariables
  const snippets = thunkAPI.getState().snippets.entities
  const currentContentBlocks = thunkAPI.getState().content.entity?.contentBlocks

  // contentBlock's config is optional during update, we need to used current contentBlock's config if not provided
  const contentBlocks: ContentBlockUpdateDataInput[] = updateInput.map(
    (contentBlock) => {
      const { config: currentContentBlockConfig } =
        currentContentBlocks?.find(({ id }) => id === contentBlock.id) || {}

      return {
        ...contentBlock,
        config: contentBlock.config || currentContentBlockConfig || {},
      }
    },
  )

  // safety check if provided references are materialized correctly
  const materializedContentBlocks = contentBlocks.map((contentBlock) =>
    materializeContentBlock({
      block: contentBlock,
      snippets,
    }),
  )

  const { isValid, error } = validateContentBlocks({
    contentBlocks: materializedContentBlocks,
  })

  if (!isValid) {
    errorNotification({
      content: 'Invalid content blocks',
      log: {
        message: '[updateContentBlocks]: Content blocks data validation failed',
        data: {
          dataToValidate: materializedContentBlocks,
          validationError: error,
          thunkQueryVariables: queryVariables,
        },
      },
    })

    return thunkReject({
      code: 'invalid_data',
      message: 'Invalid content data',
      thunkAPI,
      rejectQueries: ['CONTENT'],
    })
  }

  const contentBlocksWithCleanedReferences = contentBlocks.map((block) => ({
    ...block,
    references: referencesCleanup({
      references: block?.references,
      data: { config: block?.config },
    }),
  }))

  return {
    updateInput: contentBlocksWithCleanedReferences,
    preventSetContentUserHasInteracted,
  }
}
