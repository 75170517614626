import { PayloadAction } from '@reduxjs/toolkit'
import {
  AssignedUserResType,
  WorkspaceSliceType,
  WorkspaceUserRoleId,
} from './workspaceSliceTypes'

export const workspaceAssignUsersReducer = (
  state: WorkspaceSliceType,
  action: PayloadAction<AssignedUserResType[] | null | undefined>,
) => {
  const assignedUsers = action.payload
  const workspace = state.entities.find(
    (workspace) => workspace.id === state.selectedWorkspaceId,
  )
  if (!workspace || !assignedUsers) {
    return
  }
  const normalizedUsers = assignedUsers
    ?.filter((user) => user !== null)
    ?.map((user) => {
      return {
        ...user?.user,
        roleId: user?.aclRoleId as WorkspaceUserRoleId,
      }
    })

  // update users that already exist in the workspace assignedUsers array
  const updatedAssignedUsers = workspace.assignedUsers.map((existingUser) => {
    const updatedUser = normalizedUsers.find(
      (newUser) => newUser.id === existingUser.id,
    )
    return updatedUser || existingUser
  })

  const newUsers = normalizedUsers.filter(
    (newUser) =>
      !workspace.assignedUsers.some(
        (existingUser) => existingUser.id === newUser.id,
      ),
  )
  workspace.assignedUsers = [...updatedAssignedUsers, ...newUsers]
}
