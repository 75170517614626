import type { SVGProps } from 'react'
import * as React from 'react'

const HorizontalStretchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75 2.5V13.5C3.75 13.6326 3.69732 13.7598 3.60355 13.8536C3.50979 13.9473 3.38261 14 3.25 14C3.11739 14 2.99021 13.9473 2.89645 13.8536C2.80268 13.7598 2.75 13.6326 2.75 13.5V2.5C2.75 2.36739 2.80268 2.24021 2.89645 2.14645C2.99021 2.05268 3.11739 2 3.25 2C3.38261 2 3.50979 2.05268 3.60355 2.14645C3.69732 2.24021 3.75 2.36739 3.75 2.5ZM4.75 6.5V4C4.75 3.73478 4.85536 3.48043 5.04289 3.29289C5.23043 3.10536 5.48478 3 5.75 3H11.75C12.0152 3 12.2696 3.10536 12.4571 3.29289C12.6446 3.48043 12.75 3.73478 12.75 4V6.5C12.75 6.76522 12.6446 7.01957 12.4571 7.20711C12.2696 7.39464 12.0152 7.5 11.75 7.5H5.75C5.48478 7.5 5.23043 7.39464 5.04289 7.20711C4.85536 7.01957 4.75 6.76522 4.75 6.5ZM5.75 6.5H11.75V4H5.75V6.5ZM12.75 9.5V12C12.75 12.2652 12.6446 12.5196 12.4571 12.7071C12.2696 12.8946 12.0152 13 11.75 13H5.75C5.48478 13 5.23043 12.8946 5.04289 12.7071C4.85536 12.5196 4.75 12.2652 4.75 12V9.5C4.75 9.23478 4.85536 8.98043 5.04289 8.79289C5.23043 8.60536 5.48478 8.5 5.75 8.5H11.75C12.0152 8.5 12.2696 8.60536 12.4571 8.79289C12.6446 8.98043 12.75 9.23478 12.75 9.5ZM11.75 12V9.5H5.75V12H11.75Z"
      fill="#252523"
    />
    <path
      d="M14.75 2.5V13.5C14.75 13.6326 14.6973 13.7598 14.6036 13.8536C14.5098 13.9473 14.3826 14 14.25 14C14.1174 14 13.9902 13.9473 13.8964 13.8536C13.8027 13.7598 13.75 13.6326 13.75 13.5V2.5C13.75 2.36739 13.8027 2.24021 13.8964 2.14645C13.9902 2.05268 14.1174 2 14.25 2C14.3826 2 14.5098 2.05268 14.6036 2.14645C14.6973 2.24021 14.75 2.36739 14.75 2.5Z"
      fill="#252523"
    />
  </svg>
)
export default HorizontalStretchIcon
