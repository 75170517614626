import { InferType, bool, object, string } from 'yup'
import type { JSX } from 'react'
import { reactNodeSchema } from '../shared/reactNodeSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { verticalAlignmentStyleSchema } from '../styles/verticalAlignmentStyleSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { styleSchema } from '../shared/styleSchema'
import { cssLengthUnitSchema } from '../styles/common/cssLengthUnitSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'

const responsiveBreakpointConfigSchema = object({
  // "screen" or valid css lenght unit
  maxWidth: string().matches(
    /^(?:\d+(?:\.\d+)?(?:px|em|rem|vw|vh|vmin|vmax|cm|mm|in|pt|pc|%)?|screen)$/i,
  ),
  rowGap: cssLengthUnitSchema,
  columnGap: cssLengthUnitSchema,
}).noUnknown()

export const blockContainerComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['block-container-component']).required(),
  })
    .noUnknown()
    .required(),
  children: reactNodeSchema,
  className: string(),
  as: string().oneOf(['div']),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  verticalAlignment: verticalAlignmentStyleSchema.optional(),
  background: backgroundSchema.optional(),
  rootContainerMaxWidth: object({
    xs: string().required(),
    sm: string().required(),
    md: string().required(),
    lg: string().required(),
    xl: string().required(),
    xxl: string().required(),
  })
    .noUnknown()
    .optional(),
  show: bool(),
  style: styleSchema,
  direction: string().oneOf(['vertical', 'horizontal']),
  editor: componentEditorSchema,
  responsiveConfig: object({
    default: responsiveBreakpointConfigSchema.required(),
    xs: responsiveBreakpointConfigSchema.optional(),
    sm: responsiveBreakpointConfigSchema.optional(),
    md: responsiveBreakpointConfigSchema.optional(),
    lg: responsiveBreakpointConfigSchema.optional(),
    xl: responsiveBreakpointConfigSchema.optional(),
    xxl: responsiveBreakpointConfigSchema.optional(),
  })
    .noUnknown()
    .meta({
      dataType: 'block-container-component',
    }),
})

export type BlockContainerComponent = InferType<
  typeof blockContainerComponentSchema
>

export type BlockContainerComponentStyles = Pick<
  BlockContainerComponent,
  'margin' | 'padding' | 'boxShadow'
>
