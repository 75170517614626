import { InferType, bool, object, string } from 'yup'
import type { JSX } from 'react'
import { ariaAttributesSchema } from '../shared/ariaAttributesSchema'
import { ariaRoleSchema } from '../shared/ariaRoleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { styleSchema } from '../shared/styleSchema'
import { backgroundColorStyleSchema } from '../styles/backgroundColorStyleSchema'
import { colorStyleSchema } from '../styles/colorStyleSchema'
import { fontStyleSchema } from '../styles/fontStyleSchema'
import { horizontalAlignmentStyleSchema } from '../styles/horizontalAlignmentStyleSchema'
import { linkStyleSchema } from '../styles/linkStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { textShadowStyleSchema } from '../styles/textShadowStyleSchema'
import { enableEditorSchema } from './common/enableEditorSchema'
import { linkSchema } from './common/linkSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { transitionStyleSchema } from '../styles/transitionStyleSchema'
import { transformStyleSchema } from '../styles/transformStyleSchema'
import { sizeSchema } from './common/sizeSchema'

export const textComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['text-component']).required(),
  })
    .noUnknown()
    .required(),
  children: string(),
  className: string(),
  as: string().oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'a',
    'div',
  ]),
  cid: string(),
  id: string(),
  link: linkSchema.optional(),
  horizontalAlignment: horizontalAlignmentStyleSchema.optional(),
  color: colorStyleSchema.optional(),
  dataAttributes: object().optional(),
  ariaAttributes: ariaAttributesSchema,
  style: styleSchema,
  font: fontStyleSchema.optional(),
  size: sizeSchema.optional(),
  textShadow: textShadowStyleSchema.optional(),
  linkStyle: linkStyleSchema.optional(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  backgroundColor: backgroundColorStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  transition: transitionStyleSchema.optional(),
  transform: transformStyleSchema.optional(),
  isAnimated: bool(),
  role: ariaRoleSchema,
  enableEditor: enableEditorSchema,
  editor: componentEditorSchema,
  shouldHyphenate: bool(),
  show: bool(),
})
  .noUnknown()
  .meta({
    dataType: 'text-component',
  })

export type TextComponent = InferType<typeof textComponentSchema>

export type TextComponentStyles = Pick<
  TextComponent,
  | 'font'
  | 'linkStyle'
  | 'margin'
  | 'padding'
  | 'horizontalAlignment'
  | 'textShadow'
  | 'color'
  | 'backgroundColor'
  | 'border'
  | 'boxShadow'
>
