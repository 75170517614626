import { InferType, array, bool, object } from 'yup'
import { blockComponentSchema } from '../../components/blockComponentSchema'
import { textComponentSchema } from '../../components/textComponentSchema'
import { blockEditorSchema } from '../../shared/blockEditorSchema'
import { idSchema } from '../../shared/idSchema'
import { version } from '../../shared/versionSchema'
import { containerComponentSchema } from '../../components/containerComponentSchema'
import { accordionItemComponentSchema } from '../../components/accordionItemComponentSchema'
import { buttonComponentSchema } from '../../components/buttonComponentSchema'
import { accordionComponentSchema } from '../../components/accordionComponentSchema'

export const fAQ1BlockSchema = object({
  id: idSchema,
  version: version(1),
  components: object({
    block: blockComponentSchema,
    topHeading: textComponentSchema,
    topText: textComponentSchema,
    accordion: accordionComponentSchema
      .shape({
        items: array().of(
          accordionItemComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown(),
        ),
      })
      .noUnknown(),
    bottomHeading: textComponentSchema,
    bottomText: textComponentSchema,
    buttons: containerComponentSchema
      .shape({
        items: array().of(
          buttonComponentSchema
            .shape({
              id: idSchema.required(),
            })
            .noUnknown()
            .required(),
        ),
      })
      .noUnknown(),
  }).noUnknown(),
  config: object().optional(),
  preview: bool(),
  editor: blockEditorSchema,
})
  .noUnknown()
  .required()

export type FAQ1Block = InferType<typeof fAQ1BlockSchema>
