import { memoize } from 'proxy-memoize'
import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'
import { SelectedModules } from './whiteboardSliceTypes'
import { selectContentModulesZOrders } from '../contentModules/contentModulesSliceSelectors'
import { selectContentBlocksZOrders } from '../content/contentSliceSelectors'

export const selectZoom = (state: RootState) => {
  return state.whiteboard.zoom
}

export const selectSelectedModules = (state: RootState) => {
  return state.whiteboard.selectedModules
}

export const selectIsPageModuleSelected = (state: RootState) => {
  return (
    state.whiteboard.selectedModules.length === 1 &&
    state.whiteboard.selectedModules[0].type === 'PAGE'
  )
}

export const selectSelectedModulesLength = (state: RootState) => {
  return state.whiteboard.selectedModules.length
}
export const selectSingleSelectedModuleType = (state: RootState) => {
  const selectedModules = state.whiteboard.selectedModules
  if (selectedModules.length === 1) {
    return selectedModules[0].type
  }

  return undefined
}

export const selectSelectedModulesIds = memoize(
  (state: RootState) =>
    state.whiteboard.selectedModules.map(({ id }) => id) as string[],
)

export const selectSelectedModulesWithoutComments = memoize(
  (state: RootState) =>
    state.whiteboard.selectedModules.filter(
      (contentModule) => contentModule.type !== 'COMMENT',
    ) as SelectedModules,
)

export const selectSelectedModulesAllowedToCopy = memoize(
  (state: RootState) =>
    state.whiteboard.selectedModules.filter(
      (contentModule) =>
        contentModule.type !== 'COMMENT' &&
        contentModule.type !== 'PAGE' &&
        // due to image upload postprocessing of base64 image
        contentModule.type !== 'IMAGE',
    ) as SelectedModules,
)

export const selectSelectedModulesIdsWithoutComments = memoize(
  (state: RootState) =>
    state.whiteboard.selectedModules
      .filter(({ type }) => type !== 'COMMENT')
      .map(({ id }) => id) as string[],
)

export const selectIsModuleSelected = createSelector(
  [selectSelectedModulesIds, (_, contentModuleId) => contentModuleId],
  (selectedContentModulesIds, contentModuleId) => {
    const isSelected = selectedContentModulesIds.find(
      (id) => id === contentModuleId,
    )

    return isSelected
  },
)

export const selectMode = (state: RootState) => {
  return state.whiteboard.mode
}

export const selectHiddenModules = (state: RootState) => {
  return state.whiteboard.hiddenModules
}

export const selectInternalZoom = (state: RootState) => {
  return state.whiteboard.internalZoom
}

export const selectTriggerZoomToFit = (state: RootState) => {
  return state.whiteboard.triggerZoomToFit
}

export const selectTriggerScrollIntoView = (state: RootState) => {
  return state.whiteboard.triggerScrollIntoView
}

export const selectModeData = (state: RootState) => {
  return state.whiteboard.modeData
}

export const selectTriggerExternalAdd = (state: RootState) => {
  return state.whiteboard.triggerExternalAdd
}

export const selectPageModule = memoize((state: RootState) =>
  state.contentModules.entities.find(({ type }) => type === 'PAGE'),
)

export const selectWhiteboardModulesHighestZOrder = createSelector(
  [selectContentModulesZOrders, selectContentBlocksZOrders],
  (contentModulesZOrders, contentBlocksZOrders) => {
    if (
      contentModulesZOrders?.length === 0 &&
      contentBlocksZOrders?.length === 0
    ) {
      return 0
    }
    return Math.max(...contentModulesZOrders, ...contentBlocksZOrders)
  },
)
