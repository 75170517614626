import { bool, InferType, object, string } from 'yup'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { styleSchema } from '../shared/styleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { colorStyleSchema } from '../styles/colorStyleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { widthStyleSchema } from '../styles/widthStyleSchema'
import { heightStyleSchema } from '../styles/heightStyleSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'

export const sliderPaginantionComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['slider-pagination-component']).required(),
  })
    .noUnknown()
    .required(),
  type: string().oneOf(['bullets', 'bars']),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  background: backgroundSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  color: colorStyleSchema.optional(),
  gap: gapStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  width: widthStyleSchema.optional(),
  height: heightStyleSchema.optional(),
  className: string(),
  style: styleSchema,
  show: bool(),
  editor: componentEditorSchema,
  preview: bool(),
})
  .noUnknown()
  .meta({
    dataType: 'slider-pagination-component',
  })

export type SliderPaginationComponent = InferType<
  typeof sliderPaginantionComponentSchema
>
