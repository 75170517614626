import { clsx } from 'clsx'
import { SceneIcon, SCENE_ICONS } from './providers/scene/SceneIcon'
import {
  PhosphorIcon,
  PhosphorIconProps,
} from './providers/phosphor/PhosphorIcon'
import React, { memo } from 'react'
import { AVAILABLE_ICONS } from './providers/phosphor/iconsMap'

export const ICON_PROVIDERS = {
  scene: {
    component: SceneIcon,
    icons: Object.keys(SCENE_ICONS),
  },
  phosphor: {
    component: PhosphorIcon,
    icons: AVAILABLE_ICONS,
  },
}

export type Icons = {
  scene: keyof typeof SCENE_ICONS
  phosphor: PhosphorIconProps['icon']
}

type IconBase = {
  className?: string
  style?: React.CSSProperties
  size?: number | string
  weight?: string
}

export type SceneIcon = IconBase & {
  provider: 'scene'
  icon: Icons['scene']
}

export type PhosphorIcon = IconBase & {
  provider: 'phosphor'
  icon: Icons['phosphor']
}

export const Icon = memo(
  ({
    className,
    size = '16px',
    style,
    provider,
    icon,
    weight,
  }: SceneIcon | PhosphorIcon) => {
    if (!icon) {
      throw new Error('[Icons] - "icon" property is not defined')
    }

    if (!provider) {
      throw new Error('[Icons] - "provider" property is not defined')
    }

    if (!ICON_PROVIDERS[provider]) {
      throw new Error(`[Icons] - ${provider} not found`)
    }

    const ProviderIconComponent = ICON_PROVIDERS[provider]
      .component as React.FC<any>

    return (
      <ProviderIconComponent
        icon={icon}
        className={clsx('scene-icon', className)}
        weight={weight}
        size={size}
        style={{
          width: size,
          height: size,
          ...(style && style),
        }}
      />
    )
  },
)
