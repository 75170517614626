import { useEffect } from 'react'
const isProd = import.meta.env.PROD

export function useGTMScript(gtmId: string = 'GTM-PXGP84ZH') {
  useEffect(() => {
    if (!isProd) {
      return
    }

    const noscript = document.createElement('noscript')
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`

    const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`

    const script = document.createElement('script')
    script.innerHTML = gtmScript

    document.head.appendChild(script)
    document.body.appendChild(noscript)

    return () => {
      document.body.removeChild(noscript)
      document.head.removeChild(script)
    }
  }, [])
}
