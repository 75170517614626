import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export const selectSnippetsStatus = (state: RootState) => {
  return state.snippets.status
}

export const selectSnippets = (state: RootState) => {
  return state.snippets.entities
}

export const selectAllSnippets = createSelector(
  [selectSnippets],
  (snippets) => {
    const sortedSnippets = snippets.toSorted((a, b) => {
      const timeA = new Date(a?.issued?.createdAt || '').getTime()
      const timeB = new Date(b?.issued?.createdAt || '').getTime()
      if (timeA > timeB) {
        return -1
      } else if (timeA < timeB) {
        return 1
      }
      return 0
    })
    return sortedSnippets
  },
)
