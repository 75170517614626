import { Icon } from '@sceneio/ui-icons/lib/Icon'
import clsx from 'clsx'
import { LoadingBar } from './LoadingBar'

export function LoadingBarLogo({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
        className,
      )}
    >
      <div className="tw-mb-4">
        <Icon size={28} provider="scene" icon="scene-toolbar-logo" />
      </div>
      <LoadingBar />
    </div>
  )
}
