import React, { JSX, useEffect } from 'react'
import {
  ToastContainer,
  ToastContent,
  ToastOptions,
  toast,
} from 'react-toastify'
import styles from './notifications.scss?inline'
import { Notification } from './Notifications/Notification'
export type { ToastOptions } from 'react-toastify'
import { Icon } from '@sceneio/ui-icons'
import { NotificationComponentProps } from './types'

type EventCallbackType = (message?: string, data?: any) => void

type EventCallbacksType = {
  onError?: EventCallbackType
  onSuccess?: EventCallbackType
  onWarning?: EventCallbackType
  onInfo?: EventCallbackType
}
type NotificationContainerProps = EventCallbacksType & {
  options?: ToastOptions
}

const noop: EventCallbackType = () => {}

let onErrorFn = noop
let onSuccessFn = noop
let onWarningFn = noop
let onInfoFn = noop

function setCmsNotificationCallbacks(callbacks: EventCallbacksType) {
  const { onError, onSuccess, onWarning, onInfo } = callbacks || {}

  if (onError) onErrorFn = onError
  if (onSuccess) onSuccessFn = onSuccess
  if (onWarning) onWarningFn = onWarning
  if (onInfo) onInfoFn = onInfo
}

const NotificationContainer = ({
  onError,
  onSuccess,
  onWarning,
  onInfo,
  options,
}: NotificationContainerProps) => {
  useEffect(() => {
    setCmsNotificationCallbacks({
      onError,
      onSuccess,
      onWarning,
      onInfo,
    })
  }, [])

  return (
    <>
      <style>{styles}</style>
      <ToastContainer
        {...options}
        closeButton={({ closeToast }) => (
          <button
            type="button"
            className="Toastify__close-btn tw-absolute tw-top-2 tw-right-2 tw-p-1"
            onClick={closeToast}
          >
            <Icon provider="phosphor" icon="X" />
          </button>
        )}
      />
    </>
  )
}

type NotificationProps = {
  content: string | React.ReactNode
  options?: ToastOptions<{}> & { hideIcon?: boolean }
  log?: {
    message: string
    data?: any
  }
}

function successNotification({ content, options, log }: NotificationProps) {
  if (log) {
    onSuccessFn(log.message)
  }

  toast(Notification, {
    ...options,
    data: {
      ...options?.data,
      status: 'success',
      content,
    },
  })
}

function errorNotification({ content, options, log }: NotificationProps) {
  if (log) {
    console.error(log.message, log.data)
    onErrorFn(log.message)
  }
  toast(Notification, {
    ...options,
    data: {
      ...options?.data,
      status: 'error',
      content,
    },
  })
}

function warningNotification({ content, options, log }: NotificationProps) {
  if (log) {
    console.warn(log.message, log.data)
    onWarningFn(log.message)
  }
  toast(Notification, {
    ...options,
    data: {
      ...options?.data,
      status: 'warning',
      content,
    },
  })
}

function infoNotification({ content, options, log }: NotificationProps) {
  if (log) {
    console.info(log.message, log.data)
    onInfoFn(log.message)
  }

  toast(Notification, {
    ...options,
    data: {
      ...options?.data,
      status: 'info',
      content,
    },
  })
}

function neutralNotification({ content, options, log }: NotificationProps) {
  if (log) {
    console.info(log.message, log.data)
    onInfoFn(log.message)
  }

  toast(Notification, {
    ...options,
    data: {
      ...options?.data,
      status: 'neutral',
      content,
    },
  })
}

function customNotification(
  customNotificationComponent: (
    props: NotificationComponentProps,
  ) => React.ReactNode,
  data: {
    id: string
    options?: ToastOptions & { data: NotificationComponentProps['data'] }
  },
) {
  toast(customNotificationComponent, {
    ...data.options,
    toastId: data.id,
  })
}

function updateNotification(toastId: string, options?: ToastOptions) {
  toast.update(toastId, options)
}

function dismissNotification(toastId: string) {
  toast.dismiss(toastId)
}

export {
  NotificationContainer,
  infoNotification,
  warningNotification,
  errorNotification,
  successNotification,
  neutralNotification,
  updateNotification,
  customNotification,
  toast,
  dismissNotification,
}
