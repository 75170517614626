import { SubscriptionAddonsType } from './subscriptionSliceTypes'
import { PayloadAction } from '@reduxjs/toolkit'
import { SubscriptionSliceType } from './subscriptionSliceTypes'

export const normaliseSubscriptionPayload = (
  action: PayloadAction<{
    subscription: NonNullable<SubscriptionSliceType['entity']>
    subscriptionAddons: SubscriptionAddonsType
  }>,
) => {
  const { subscription, subscriptionAddons } = action.payload

  // TODO whatchout for API typo in oc[c]upied
  const {
    availableFree: seatsFreeAvailable,
    availablePaid: seatsPaidAvailable,
    free: seatsFree,
    ocupiedAll: seatsAllOccupied,
    ocupiedFree: seatsFreeOccupied,
    ocupiedPaid: seatsPaidOccupied,
    paid: seatsPaid,
  } = subscriptionAddons?.seats || {}

  const {
    availableFree: sitePlansFreeAvailable,
    availablePaid: sitePlansPaidAvailable,
    free: sitePlansFree,
    ocupiedAll: sitePlansAllOccupied,
    ocupiedFree: sitePlansFreeOccupied,
    ocupiedPaid: sitePlansPaidOccupied,
    paid: sitePlansPaid,
  } = subscriptionAddons?.sitePlans || {}

  return {
    ...subscription,
    seatsFreeAvailable,
    seatsPaidAvailable,
    seatsFree,
    seatsAllOccupied,
    seatsFreeOccupied,
    seatsPaidOccupied,
    seatsPaid,
    sitePlansFreeAvailable,
    sitePlansPaidAvailable,
    sitePlansFree,
    sitePlansPaid,
    sitePlansAllOccupied,
    sitePlansFreeOccupied,
    sitePlansPaidOccupied,
  }
}
