import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'
import { ContentBlock } from '@sceneio/graphql-queries/dist/generated/graphqlTypes'
import flatten from 'flat'
import { memoize } from 'proxy-memoize'

export const selectContentStatus = (state: RootState) => {
  return state.content.status
}

export const selectContentStatusError = (state: RootState) => {
  return state.content.error
}

export const selectContentPublishState = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.publishState
}

export const selectContentMeta = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.meta
}

export const selectContent = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return []
  }

  return state.content.entity
}

export const selectContentBlocks = createSelector(
  [
    (state: RootState) => state.content.status,
    (state: RootState) => state.content,
  ],
  (contentStatus, content) => {
    if (contentStatus !== 'succeeded') {
      return []
    }

    return content.entity?.contentBlocks || []
  },
)

export const selectContentBlocksMeta = createSelector(
  [(state: RootState) => state.content.entity?.contentBlocks],
  (contentBlocks) => {
    return (contentBlocks || []).map(({ id, meta }) => {
      return {
        id,
        meta,
      }
    })
  },
)

export const selectContentBlocksZOrders = createSelector(
  [(state: RootState) => state.content.entity?.contentBlocks],
  (contentBlocks) => {
    return (contentBlocks || []).map(
      ({ meta }) => meta?.whiteboard?.zOrder || 0,
    )
  },
)

// merged content blocks with reusable content blocks drafts
export const selectMergedContentBlocksWithReusableContentBlocksDrafts =
  createSelector(
    [
      (state: RootState) => state.content.status,
      (state: RootState) => state.content.entity?.contentBlocks,
    ],
    (contentStatus, contentBlocks) => {
      if (contentStatus !== 'succeeded') {
        return []
      }
      if (!contentBlocks) {
        return []
      }

      return contentBlocks.map(
        ({ reusableContentBlockDraft, ...contentBlock }) =>
          reusableContentBlockDraft || contentBlock,
      )
    },
  )

export const selectRenderableContentBlocks = memoize((state: RootState) => {
  return (
    (state.content.entity?.contentBlocks?.filter(({ isRenderable }) => {
      return isRenderable
    }) as ContentBlock[]) || []
  )
})

export const selectRenderableNonReusableContentBlocks = memoize(
  (state: RootState) => {
    return (
      (state.content.entity?.contentBlocks?.filter(
        ({ isRenderable, isReusable }) => {
          return isRenderable && !isReusable
        },
      ) as ContentBlock[]) || []
    )
  },
)

export const selectRenderableContentBlocksLength = createSelector(
  [selectRenderableContentBlocks],
  (renderableContentBlocks) => renderableContentBlocks.length,
)

export const selectIsEmptyFrame = createSelector(
  [selectRenderableContentBlocksLength],
  (renderableContentBlocksLength) => renderableContentBlocksLength === 0,
)

export const selectNonRenderableContentBlocks = memoize((state: RootState) => {
  return (
    (state.content.entity?.contentBlocks?.filter(({ isRenderable }) => {
      return !isRenderable
    }) as ContentBlock[]) || []
  )
})

export const selectContentId = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.id
}

export const selectContentBlocksOrdersByBlockId = createSelector(
  [(state: RootState) => state.content.entity?.contentBlocks],
  (contentBlocks) => {
    return contentBlocks?.reduce((acc, { id, order }) => {
      return { ...acc, [id]: order }
    }, {})
  },
)
export const selectPartialContent = createSelector(
  [
    (state: RootState) => state.content.status,
    (state: RootState) => state.content.entity,
  ],
  (contentStatus, entity) => {
    if (contentStatus !== 'succeeded') {
      return null
    }
    if (!entity) {
      return null
    }
    const { id, data, references } = entity

    return { id, data, references }
  },
)

export const selectPageMetaTitle = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return ''
  }

  return state.content.entity.meta.page.title
}

export const selectLayoutType = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.data.type
}

export const selectPageConfig = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.data?.config
}

export const selectContentPreferences = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return {}
  }

  return state.content.entity?.preferences || {}
}

export const selectContentPreferencesAiPrompt = (state: RootState) => {
  return state.content.entity?.preferences?.AIPrompt || ''
}

export const selectBlockIds = memoize(
  (state: RootState) =>
    state.content.entity?.contentBlocks.map((block) => block.id) as string[],
)

export const selectRenderableContentBlockIds = memoize((state: RootState) => {
  return state.content.entity?.contentBlocks.reduce<string[]>(
    (acc, { id, isRenderable }) => {
      if (isRenderable) {
        acc.push(id)
      }
      return acc
    },
    [],
  ) as string[]
})

export const selectRawBlockById = createSelector(
  [selectContentBlocks, (_, blockId) => blockId],
  (contentBlocks, blockId) => {
    const contentBlock = contentBlocks.find((block) => block.id === blockId)

    return contentBlock
  },
)

export const selectBlockOrderById = createSelector(
  [selectContentBlocks, (_, blockId) => blockId],
  (contentBlocks, blockId) => {
    const contentBlock = contentBlocks.find((block) => block.id === blockId)

    return contentBlock?.order
  },
)

export const selectRawBlockByCid = createSelector(
  [selectContentBlocks, (_, blockCid) => blockCid],
  (contentBlocks, blockCid) => {
    const contentBlock = contentBlocks.find((block) => block.cid === blockCid)

    return contentBlock
  },
)

export const selectContentPaths = createSelector(
  [
    (state: RootState) => state.content.status,
    (state: RootState) => state.content?.entity?.paths,
  ],
  (contentStatus, contentPaths) => {
    if (contentStatus !== 'succeeded') {
      return []
    }

    return contentPaths || []
  },
)
export const selectContentParentId = (state: RootState) => {
  if (state.content.status !== 'succeeded') {
    return null
  }

  return state.content.entity.parentId
}

export const selectContentHasUpdates = createSelector(
  [
    (state: RootState) =>
      state.content.entity?.contentBlocks.some(({ updatedAt }) => updatedAt),
    (state: RootState) => Boolean(state.content.entity?.updatedAt),
    (state: RootState) => Boolean(state.content.entity?.paths[0]?.updatedAt),
  ],
  (isContentBlocksUpdated, isContentUpdated, isContentPathUpdated) => {
    return isContentUpdated || isContentPathUpdated || isContentBlocksUpdated
  },
)

export const selectContentRestoreInfo = (state: RootState) => {
  return state.content.entity?.restoreInfo
}

export const selectSwapBlockTypeById = createSelector(
  [selectContentBlocks, (_, blockId) => blockId],
  (contentBlocks, blockId) => {
    const block = contentBlocks.find((block) => block.id === blockId)

    return block?.swapBlockData?.type
  },
)

export const selectSwapBlockDataById = createSelector(
  [selectContentBlocks, (_, blockId) => blockId],
  (contentBlocks, blockId) => {
    const block = contentBlocks.find((block) => block.id === blockId)

    return block?.swapBlockData?.data
  },
)

export const selectContentPreferencesStyleguideHash = (state: RootState) => {
  return state.content.entity?.preferences?.styleguideHash
}
