import type { SVGProps } from 'react'
import * as React from 'react'

const VerticalStretchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.75 2.5C14.75 2.63261 14.6973 2.75979 14.6036 2.85355C14.5098 2.94732 14.3826 3 14.25 3H3.25C3.11739 3 2.99021 2.94732 2.89645 2.85355C2.80268 2.75979 2.75 2.63261 2.75 2.5C2.75 2.36739 2.80268 2.24021 2.89645 2.14645C2.99021 2.05268 3.11739 2 3.25 2H14.25C14.3826 2 14.5098 2.05268 14.6036 2.14645C14.6973 2.24021 14.75 2.36739 14.75 2.5ZM13.75 5V11C13.75 11.2652 13.6446 11.5196 13.4571 11.7071C13.2696 11.8946 13.0152 12 12.75 12H10.25C9.98478 12 9.73043 11.8946 9.54289 11.7071C9.35536 11.5196 9.25 11.2652 9.25 11V5C9.25 4.73478 9.35536 4.48043 9.54289 4.29289C9.73043 4.10536 9.98478 4 10.25 4H12.75C13.0152 4 13.2696 4.10536 13.4571 4.29289C13.6446 4.48043 13.75 4.73478 13.75 5ZM12.75 5H10.25V11H12.75V5ZM8.25 5V11C8.25 11.2652 8.14464 11.5196 7.95711 11.7071C7.76957 11.8946 7.51522 12 7.25 12H4.75C4.48478 12 4.23043 11.8946 4.04289 11.7071C3.85536 11.5196 3.75 11.2652 3.75 11V5C3.75 4.73478 3.85536 4.48043 4.04289 4.29289C4.23043 4.10536 4.48478 4 4.75 4H7.25C7.51522 4 7.76957 4.10536 7.95711 4.29289C8.14464 4.48043 8.25 4.73478 8.25 5ZM7.25 5H4.75V11H7.25V5Z"
      fill="#252523"
    />
    <path
      d="M15 13.5C15 13.6326 14.9473 13.7598 14.8536 13.8536C14.7598 13.9473 14.6326 14 14.5 14H3.5C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5C3 13.3674 3.05268 13.2402 3.14645 13.1464C3.24021 13.0527 3.36739 13 3.5 13H14.5C14.6326 13 14.7598 13.0527 14.8536 13.1464C14.9473 13.2402 15 13.3674 15 13.5Z"
      fill="#252523"
    />
  </svg>
)
export default VerticalStretchIcon
