import { updateBlock } from '../features/content/contentSlice'
import { UpdateContentBlockQueryVarsType } from '../features/content/contentSliceTypes'
import { StoreType } from '../store'
import { LocalHistoryCommand } from './LocalHistoryCommand'

export class UpdateBlockCommand extends LocalHistoryCommand {
  thunkData: UpdateContentBlockQueryVarsType
  constructor(thunkData: UpdateContentBlockQueryVarsType) {
    super()
    this.thunkData = thunkData
    this.type = 'UpdateBlockCommand'
  }

  execute() {
    return updateBlock(this.thunkData)
  }

  preExecute(getState: StoreType['getState']) {
    const state = getState()

    if (state.content.status !== 'succeeded') {
      return
    }

    const block = state.content.entity.contentBlocks.find(
      ({ cid }) => this.thunkData.queryVariables.blockCid === cid,
    )

    if (block) {
      this.pastState = {
        blockId: block.id,
        blockCid: block.cid,
        configValue: block.config,
        name: block.name,
        configPath: '',
        customConfigValue: block.customConfig,
        referencesToReplace: block.references,
        type: block.type,
      }
    }
  }

  undo() {
    return updateBlock({
      queryVariables: {
        ...this.pastState,
      },
      thunkOptions: {
        shouldReplaceValue: true,
      },
    })
  }
}
