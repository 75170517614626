import { memoize } from 'proxy-memoize'
import { RootState } from '../../store'
import { createSelector } from '@reduxjs/toolkit'

export const selectContentModulesStatus = (state: RootState) => {
  return state.contentModules.status
}

export const selectContentModulesStatusError = (state: RootState) => {
  return state.contentModules.error
}

export const selectContentModules = (state: RootState) => {
  return state.contentModules.entities
}

export const selectPageModule = memoize((state: RootState) =>
  state.contentModules.entities.find(({ type }) => type === 'PAGE'),
)

export const selectContentModulesZOrders = createSelector(
  [(state: RootState) => state.contentModules.entities],
  (contentModules) => {
    return (contentModules || []).map(
      ({ meta }) => meta?.whiteboard?.zOrder || 0,
    )
  },
)
