import { InferType, object, string } from 'yup'

export const transformStyleSchema = object({
  data: object({
    default: object({
      '--transform-default-default': string(),
      '--transform-xs-default': string(),
      '--transform-md-default': string(),
      '--transform-lg-default': string(),
    })
      .noUnknown()
      .optional(),
    hover: object({
      '--transform-default-hover': string(),
      '--transform-xs-hover': string(),
      '--transform-md-hover': string(),
      '--transform-lg-hover': string(),
    })
      .noUnknown()
      .optional(),
    active: object({
      '--transform-default-active': string(),
      '--transform-xs-active': string(),
      '--transform-md-active': string(),
      '--transform-lg-active': string(),
    })
      .noUnknown()
      .optional(),
  }).noUnknown(),
}).noUnknown()

export type TransformStyle = InferType<typeof transformStyleSchema>
