import { InferType, bool, object, string } from 'yup'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { horizontalAlignmentFlexStyleSchema } from '../styles/horizontalAlignmentFlexStyleSchema'
import { styleSchema } from '../shared/styleSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { textComponentSchema } from './textComponentSchema'

import { galleryImageComponentSchema } from './galleryImageComponentSchema'
import { heightStyleSchema } from '../styles/heightStyleSchema'
import { backgroundSchema } from './common/backgroundSchema'
import { transitionStyleSchema } from '../styles/transitionStyleSchema'
import { transformStyleSchema } from '../styles/transformStyleSchema'

export const galleryItemComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['gallery-item-component']).required(),
  })
    .noUnknown()
    .required(),
  components: object({
    image: galleryImageComponentSchema,
    heading: textComponentSchema,
    description: textComponentSchema,
  }).noUnknown(),
  className: string(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  horizontalAlignment: horizontalAlignmentFlexStyleSchema.optional(),
  gap: gapStyleSchema.optional(),
  height: heightStyleSchema.optional(),
  background: backgroundSchema.optional(),
  transition: transitionStyleSchema.optional(),
  transform: transformStyleSchema.optional(),
  show: bool(),
  style: styleSchema,
  editor: componentEditorSchema,
  cid: string(),
  isAnimated: bool(),
})
  .noUnknown()
  .meta({
    dataType: 'gallery-item-component',
  })

export type GalleryItemComponent = InferType<typeof galleryItemComponentSchema>

export type GalleryItemComponentStyles = Pick<
  GalleryItemComponent,
  | 'margin'
  | 'padding'
  | 'boxShadow'
  | 'border'
  | 'horizontalAlignment'
  | 'gap'
  | 'height'
  | 'transform'
  | 'transition'
>
