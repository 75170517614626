import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { ProjectSliceType } from './projectSliceTypes'
import { AppDispatch, RootState } from '../../store'
import { createSlice } from '@reduxjs/toolkit'
import { setSelectedModules } from '../whiteboard/whiteboardSlice'
import { pageMultiUpdate } from '../content/contentSlice'
import { DEFAULT_STYLEGUIDE_VALUES } from './projectDefaults'

export const initialState: ProjectSliceType = {
  status: 'idle',
  error: null,
  entity: null,
} as ProjectSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    projectFetchSuccess: (
      state,
      action: PayloadAction<ProjectSliceType['entity']>,
    ) => {
      if (!action.payload) {
        return
      }

      const styleguide =
        action.payload?.preferences?.styleguide || DEFAULT_STYLEGUIDE_VALUES

      state.status = 'succeeded'
      state.entity = {
        ...state.entity,
        ...action.payload,
        preferences: {
          ...action.payload.preferences,
          styleguide,
        },
      }
    },
    setProjectPreferences: (
      state,
      action: PayloadAction<Record<string, any>>,
    ) => {
      if (!state.entity) {
        return
      }
      state.entity.preferences = action.payload
    },
    setProjectName: (state, action: PayloadAction<string>) => {
      if (!state.entity) {
        return
      }
      state.entity.name = action.payload
    },

    setAuthLink: (state, action: PayloadAction<string | null | undefined>) => {
      if (!state.entity) {
        return
      }
      state.entity.authLink = action.payload
    },
    activateSitePlan: (state) => {
      if (!state.entity) {
        return
      }
      state.entity.sitePlanAssignedAt = new Date().toISOString()
    },
    deactivateSitePlan: (state) => {
      if (!state.entity) {
        return
      }
      state.entity.sitePlanAssignedAt = null
    },
    wsUpdateProject: (
      state,
      action: PayloadAction<Partial<ProjectSliceType['entity']>>,
    ) => {
      if (!state.entity) {
        return
      }
      state.entity = {
        ...state.entity,
        ...action.payload,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase('USER:LOGOUT', (state, action) => {
        return initialState
      })
      .addCase(pageMultiUpdate.fulfilled, (state, action) => {
        if (state.status === 'succeeded') {
          const { project } = action.payload

          if (project) {
            state.entity.preferences = project.preferences
          }
        }
      })
  },
})

export const useProjectDispatch: () => AppDispatch = useDispatch
export const useProjectSelector: TypedUseSelectorHook<RootState> = useSelector

export const {
  projectFetchSuccess,
  setAuthLink,
  setProjectPreferences,
  setProjectName,
  activateSitePlan,
  deactivateSitePlan,
  wsUpdateProject,
} = projectSlice.actions

// ---------------
// SELECTORS
// ---------------

export const selectProject = (state: RootState) => {
  return state.project
}

export const selectProjectName = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }

  return state.project.entity.name
}

export const selectProjectPreferences = (state: RootState) => {
  return state.project.entity?.preferences
}

export const selectProjectData = (state: RootState) => {
  return state.project.entity
}

export const selectProjectDomain = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  const domains = state.project.entity.domains
  return Boolean(domains.length) ? domains[0] : null
}

export const selectProjectId = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }

  return state.project.entity.id
}

export const selectDomainSetupStatus = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.meta.setup?.domain?.status || null
}

export const selectStorageSetupStatus = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.meta.setup?.storage?.status || null
}
export const selectProjectAuthLink = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.authLink
}
export const selectProjectOwner = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.ownedByUser
}

export const selectProjectEditedAt = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.editedAt
}

export const selectHasSitePlan = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return Boolean(state.project.entity.sitePlanAssignedAt)
}

export const selectIsNewProject = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return null
  }
  return state.project.entity.editedAt === state.project.entity.createdAt
}

export const selectIsCustomDomainWithoutSitePlan = (state: RootState) => {
  if (state.project.status !== 'succeeded') {
    return false
  }

  if (state.project.entity.domains.length === 0) {
    return false
  }

  const isCustomDomain = state.project.entity.domains[0].isCustom
  const hasSitePlan = Boolean(state.project.entity.sitePlanAssignedAt)

  return Boolean(isCustomDomain && !hasSitePlan)
}

export default projectSlice.reducer
