export function LoadingBar({ className }: { className?: string }) {
  return (
    <div className={className}>
      <svg
        className="tw-w-[74px] tw-h-[2px]"
        viewBox="0 0 74 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Background bar */}
        <path
          d="M0 1C0 0.447715 0.447715 0 1 0H73C73.5523 0 74 0.447715 74 1C74 1.55228 73.5523 2 73 2H0.999999C0.447715 2 0 1.55228 0 1Z"
          fill="#252523"
          fillOpacity="0.1"
        />
        {/* Animated loader */}
        <g className="tw-animate-slide">
          <path
            d="M0 1C0 0.447715 0.447715 0 1 0H45C45.5523 0 46 0.447715 46 1C46 1.55228 45.5523 2 45 2H1C0.447715 2 0 1.55228 0 1Z"
            fill="#5E56F9"
          />
        </g>
      </svg>
    </div>
  )
}
