import { InferType, bool, object, string } from 'yup'
import { styleSchema } from '../shared/styleSchema'
import { marginStyleSchema } from '../styles/marginStyleSchema'
import { paddingStyleSchema } from '../styles/paddingStyleSchema'
import { borderStyleSchema } from '../styles/borderStyleSchema'
import { boxShadowStyleSchema } from '../styles/boxShadowStyleSchema'
import { componentEditorSchema } from '../shared/componentEditorSchema'
import { textComponentSchema } from './textComponentSchema'
import { inputFieldComponentSchema } from './inputFieldComponentSchema'
import { buttonComponentSchema } from './buttonComponentSchema'
import { gapStyleSchema } from '../styles/gapStyleSchema'

export const formFieldComponentSchema = object({
  meta: object({
    dataType: string().oneOf(['form-field-component']).required(),
  })
    .noUnknown()
    .required(),
  components: object({
    label: textComponentSchema,
    field: inputFieldComponentSchema,
    button: buttonComponentSchema,
    requiredValueError: textComponentSchema,
    invalidPatternError: textComponentSchema,
    minLengthError: textComponentSchema,
    maxLengthError: textComponentSchema,
  }).noUnknown(),
  gap: gapStyleSchema.optional(),
  margin: marginStyleSchema.optional(),
  padding: paddingStyleSchema.optional(),
  border: borderStyleSchema.optional(),
  boxShadow: boxShadowStyleSchema.optional(),
  style: styleSchema,
  className: string(),
  preview: bool(),
  editor: componentEditorSchema,
  show: bool(),
  cid: string(),
})
  .noUnknown()
  .meta({
    dataType: 'form-field-component',
  })

export type FormFieldComponent = InferType<typeof formFieldComponentSchema>

export type FormFieldComponentStyles = Pick<
  FormFieldComponent,
  'margin' | 'padding' | 'boxShadow' | 'border'
>
