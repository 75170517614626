import clsx from 'clsx'
import { StatusIcon } from './StatusIcon'
import type { NotificationComponentProps } from '../types'

export function Notification({
  closeToast,
  data,
  toastProps,
}: NotificationComponentProps) {
  const {
    status,
    content,
    buttons = [],
    description,
    buttonsPlacement = 'bottom',
  } = data

  const actionButtons = (
    <>
      {buttons.length > 0 && (
        <div className="tw-flex tw-gap-2 tw-items-center tw-ml-auto tw-flex-shrink-0">
          {buttons.map(({ text, onClick, className }, idx) => (
            <button
              key={idx}
              className={clsx(
                'Toastify__button tw-px-[10px] tw-rounded tw-py-1 tw-text-xs tw-bg-white tw-text-body-color tw-border tw-border-border-color hover:tw-bg-gray-4 tw-font-medium tw-flex tw-items-center',
                className,
              )}
              onClick={() => {
                onClick()
                closeToast()
              }}
            >
              {text}
            </button>
          ))}
        </div>
      )}
    </>
  )

  return (
    <div className="tw-w-full">
      <div
        className={clsx('tw-flex tw-items-center tw-p-2', {
          'tw-mr-[30px]': Boolean(toastProps?.closeButton),
        })}
      >
        <StatusIcon status={status} />
        <div className="tw-mb-0 tw-pl-2 tw-flex tw-text-xs tw-items-center tw-font-medium tw-w-full tw-gap-3">
          <div className="tw-grow">{content}</div>
          {buttonsPlacement === 'top' && actionButtons}
        </div>
      </div>
      {(description ||
        (buttons.length > 0 && buttonsPlacement === 'bottom')) && (
        <div className="tw-p-2 tw-flex tw-items-center tw-border-t tw-border-border-color">
          {description && (
            <span className="tw-text-xs tw-mr-2">{description}</span>
          )}
          {actionButtons}
        </div>
      )}
    </div>
  )
}
